export default [
  {
    id: 0,
    img: "/assets/featureimg_3.png",
    title: `All Subscriptions in One Place!`,
    smartFeatures: false,
    button: true,
    desc: `Alladdin streamlines subscription management. It consolidates movies, music, and other premium services into a single dashboard for effortless oversight and control. No need to check multiple websites - Alladdin handles all subscriptions in one place.`,
  },
  {
    id: 1,
    img: "/assets/featureimg_2.svg",
    title: `Manage Your Free and Paid Subscriptions!"`,
    smartFeatures: false,
    button: true,
    desc: `Alladdin notifies you when paid subscriptions expire or free trials end, allowing easy renewal or cancelation directly from our site or extension. No need to visit other websites to manage subscriptions.`,
  },
  {
    id: 2,
    img: "/assets/featureimg_2.svg",
    title: `Say No to Unexpected Charges!`,
    smartFeatures: false,
    button: true,
    desc: `Manage subscriptions proactively to avoid unwanted bank fees. Untracked subscriptions risk unexpected deductions disrupting budgets. Use our platform for transparency and authorization of all charges.
    `,
  },
  {
    id: 3,
    img: "/assets/featureimg_2.svg",
    title: `All-Inclusive Subscription Library!`,
    smartFeatures: false,
    button: true,
    desc: `Alladdin provides a comprehensive subscription library with access to a variety of online services including education, entertainment, music, and more. View all subscription platforms on our site and manage your services from one location.`,
  },
  {
    id: 4,
    img: "/assets/aldx.svg",
    title: `All your OTT reminders in one place`,
    desc: `Do not miss out on any subscription renewal dates with Alladdin. This genie will help you manage all your subscriptions, that too, at minimum costs. Keep your entertainment needs in check with us!`,
    smartFeatures: true,
    button: false,
    features: [
      {
        icon: "/assets/featureicon3.svg",
        featuretitle: "Smart Reminders",
        featuredesc: "Easy to manage subscription with Smart reminders.",
      },
    ],
  },
];

export const featurepagefeatures = [
  {
    id: 1,
    img: "/assets/featureimage_3.png",
    title: `Unlock more Premium Features`,
    desc: `Discover a new dimension of convenience and savings as you effortlessly track and optimise your OTT subscriptions with us!.`,
    smartFeatures: true,
    button: false,
    features: [
      {
        icon: "/assets/featureicon1.svg",
        featuretitle: "Manage Paid Platforms",
        featuredesc: "Building an enterprisedoesn't need your thousands.",
      },
      {
        icon: "/assets/featureicon2.svg",
        featuretitle: "More Pro reminders",
        featuredesc: "Building an enterprisedoesn't need your thousands..",
      },
    ],
  },
  {
    id: 3,
    img: "/assets/asa_img.svg",
    title: `Advanced  <br/> Subscription <br/> Analysis`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    desc: "Maintain detailed records of subscription details including time spent on platforms, renewal dates, and other key information.",
    // smartFeatures: true,
    button: false,
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
  {
    id: 4,
    img: "/assets/fto_img.svg",
    title: `Free Trial <br/> Oversight`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    desc: "Keep records of expiring free trials for extensions. Prepare resources needed to purchase extensions. We will provide updates.",
    // smartFeatures: true,
    button: false,
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
  {
    id: 5,
    img: "/assets/rs_img.svg",
    title: `Recommended <br/> Services`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    // smartFeatures: true,
    desc: "We recommend additional platforms to meet your entertainment needs based on your subscriptions.",
    button: false,
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
  {
    id: 6,
    img: "/assets/as_img.svg",
    title: `Activity<br/> Stream`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    desc: "Do not focus solely on one platform. With Alladdin's tracking, stay updated on time spent across subscribed platforms.",
    // smartFeatures: true,
    button: false,
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
  {
    id: 7,
    img: "/assets/cv_img.svg",
    title: `Calander <br/> View`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    // smartFeatures: true,
    button: false,
    desc: " In the calendar view, you can easily see the date when a subscription is nearing its expiration. Additionally, you'll receive reminders, allowing you to renew it promptly if desired.",
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
  {
    id: 8,
    img: "/assets/sl_img.svg",
    title: `Subscription <br/> library`,
    // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
    border: true,
    // smartFeatures: true,
    desc: "Get access to Alladdin library, a comprehensive network of online subscription companies. This library enables users to easily access a varied portfolio of subscription-based products and services.",
    button: false,
    // features: [
    //   {
    //     icon: "/assets/featureicon1.svg",
    //     featuretitle: "Manage Paid Platforms",
    //     featuredesc: "Building an enterprisedoesn't need your thousands.",
    //   },
    //   {
    //     icon: "/assets/featureicon2.svg",
    //     featuretitle: "More Pro reminders",
    //     featuredesc: "Building an enterprisedoesn't need your thousands..",
    //   },
    // ],
  },
];

// export default [
//   {
//     id: 0,
//     img: "/assets/featureimg_3.png",
//     title: `Simplified Subscription Management`,
//     smartFeatures: false,
//     button: true,
//     desc: `Envision a seamless space where every subscription, from movies to utilities, is neatly organized. No more lost bills or overlooked apps. Our platform offers unparalleled clarity, presenting all your commitments in one dashboard. From unnoticed music plans to redundant storages, we bring transparency and simplicity. Experience the revolution in subscription management.`,
//   },
//   {
//     id: 1,
//     img: "/assets/featureimg_2.svg",
//     title: `Stay on top of your "free trials"`,
//     smartFeatures: false,
//     button: true,
//     desc: `Explore your spending with our detailed analytics. Track monthly expenses, view yearly trends, and uncover spending patterns. Pinpoint areas of excess and find opportunities to save. With our platform, you gain a clear financial picture, enabling smarter decisions. Strategize, optimize, and ensure every subscription truly benefits you.`,
//   },
//   {
//     id: 2,
//     img: "/assets/featureimg_2.svg",
//     title: `Unexpected Charges: No More!`,
//     smartFeatures: false,
//     button: true,
//     desc: `Avoid the shock of unexpected charges. When subscriptions go untracked, you might find unwelcome deductions from your bank or credit card. These surprises can throw off your budget and lead to financial stress. With our platform, gain clarity and control, ensuring every charge is anticipated and approved.`,
//   },
//   {
//     id: 3,
//     img: "/assets/featureimg_2.svg",
//     title: `Subscription Library: Your Comprehensive Guide`,
//     smartFeatures: false,
//     button: true,
//     desc: `Explore our Subscription Library, a comprehensive resource that brings together a wide range of online services, from education to entertainment and more. With a clear and organized structure, it's easier than ever to get an overview of what each service offers. Whether you're looking for something new or researching a known service, our library provides a streamlined and professional experience to assist your decision-making.`,
//   },
//   {
//     id: 4,
//     img: "/assets/aldx.svg",
//     title: `All your OTT reminders at one place`,
//     desc: `Demo text - Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     smartFeatures: true,
//     button: false,
//     features: [
//       {
//         icon: "/assets/featureicon3.svg",
//         featuretitle: "Smart Reminders",
//         featuredesc: "Easy to manage subscription with Smart reminders.",
//       },
//     ],
//   },
// ];

// export const featurepagefeatures = [
//   {
//     id: 1,
//     img: "/assets/featureimage_3.png",
//     title: `Unlock more Premium Features`,
//     desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     smartFeatures: true,
//     button: false,
//     features: [
//       {
//         icon: "/assets/featureicon1.svg",
//         featuretitle: "Manage Paid Platforms",
//         featuredesc: "Building an enterprisedoesn't need your thousands.",
//       },
//       {
//         icon: "/assets/featureicon2.svg",
//         featuretitle: "More Pro reminders",
//         featuredesc: "Building an enterprisedoesn't need your thousands..",
//       },
//     ],
//   },
//   {
//     id: 3,
//     img: "/assets/asa_img.svg",
//     title: `Advanced  <br/> Subscription <br/> Analysis`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
//   {
//     id: 4,
//     img: "/assets/fto_img.svg",
//     title: `Free Trial <br/> Oversight`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
//   {
//     id: 5,
//     img: "/assets/rs_img.svg",
//     title: `Recommended <br/> Services`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
//   {
//     id: 6,
//     img: "/assets/as_img.svg",
//     title: `Activity<br/> Stream`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
//   {
//     id: 7,
//     img: "/assets/cv_img.svg",
//     title: `Calander <br/> View`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
//   {
//     id: 8,
//     img: "/assets/sl_img.svg",
//     title: `Subscription <br/> library`,
//     // desc: `Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use and completxability to create even the most powerful of products.`,
//     border: true,
//     // smartFeatures: true,
//     button: false,
//     // features: [
//     //   {
//     //     icon: "/assets/featureicon1.svg",
//     //     featuretitle: "Manage Paid Platforms",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands.",
//     //   },
//     //   {
//     //     icon: "/assets/featureicon2.svg",
//     //     featuretitle: "More Pro reminders",
//     //     featuredesc: "Building an enterprisedoesn't need your thousands..",
//     //   },
//     // ],
//   },
// ];
