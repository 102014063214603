import UnexpectedImage from '../../../assets/images/Unexpected.webp';

const UnexpectedCharges = () => {
  return (
    <div className='pt-[95px] pb-7 mobile:py-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <div className='grid grid-cols-[1fr_530px] gap-100 tab:grid-cols-1 tab:gap-60'>
        <div>
                <img className='block w-full' src={UnexpectedImage} alt='UnexpectedImage'/>
            </div>
            <div>
                <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal tracking-normal text-black300 font-semibold mb-5 mobile:mt-0 mt-5'>
                No More Unexpected Charges.
                </h2>
                <p className='text-lg font-normal mobile:text-base mobile:leading-8  leading-normal mb-6 text-gray650'>
                Avoid the shock of unexpected charges. When subscriptions go untracked, you might find unwelcome deductions from your bank or credit card. These surprises can throw off your budget and lead to financial stress. With our platform, gain clarity and control, ensuring every charge is anticipated 
                and approved
                </p>
                <button onClick={() => window.open("https://alladdin-web.netlify.app/login", "_blank")} className='bg-transparent hover:bg-black300 hover:text-white transition ease-in-out border border-solid border-black300 text-sm font-normal tracking-normal py-3 px-4 text-black300 rounded-lg'>
                Learn More
                </button>
            </div>
           
        </div>
      </div>
    </div>
  )
}

export default UnexpectedCharges
