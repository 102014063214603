import { Box } from "@mui/material";
import React, { useState } from "react";
import ResponsiveDrawer from "../sidebar";
import { useAuth } from "../../context/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import FeatureCard from "../features/FeatureCard";
import DashbordFeatures from "../commonComponents/UnsubscribedFeatures";
import Adminsidebar from "../adminsidebar";
import AdminHeader from "../adminheader";

function DashBoardLayout({ children }) {
  const { isAuthenticate,logout, isUserSubscribed } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [logoutModel, setLogoutModel] = useState(false);
  const [ sidebar , setSidebar ] = useState(false);
  
  const handleLogout = async () => {
    await logout();
    window.location.reload();
    localStorage.removeItem("authToken");
    // localStorage.removeItem("usersData");
    navigate("/");
    setLogoutModel(false)
  };

  return (
    <>
      <div className="flex bg-bglight tab:flex-wrap">
        <div className="w-[280px] tab:w-full tab:hidden">
          <Adminsidebar sidebar={sidebar} setSidebar={setSidebar} logoutModel={logoutModel} setLogoutModel={setLogoutModel}/>
        </div>
        <div className="w-[calc(100%-280px)] tab:w-full">
          <AdminHeader />
          <div className="p-5 mobile:p-3 h-[calc(100% - 57px)] tab:min-h-[calc(100vh-73px)]" style={{height: "calc(100% - 57px)"}}>
            {!isUserSubscribed &&
            !pathname.includes("profile") &&
            !pathname.includes("help") &&
            !pathname.includes("privacy-policy") &&
            !pathname.includes("terms-of-service")  &&
            !pathname.includes("/")  ? (
              <div className="unsubscribedContainerWrapper">
                <div className="unsubscribedContainer">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "18px",
                    }}
                  >
                    <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
                    <h6 className="unsubscribedHeading">
                      This Feature is <br /> locked in Free Plan
                    </h6>
                    <p className="unsubscribedpara">
                      Please, Upgrade your plan to <br /> enjoy full benefits
                    </p>
                  </div>
                  <FeatureCard feature={DashbordFeatures} />
                </div>
              </div>
            ) : (
              children
            )}
          </div>
          {logoutModel && (
          <div className="global-modal" style={{ zIndex: "999999" }}>
            <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
              {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
              <div className="flex justify-between items-center">
                <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure you want to logout?</p>
              </div>

              <div className="flex justify-end gap-3 pt-3">
                <button
                  className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                  onClick={() => {
                    setLogoutModel(false);
                  }}
                >
                  Cancel
                </button>
                <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleLogout()}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}
        </div>
      </div>
      {/* <Box sx={{ display: "flex" }} className="pagewrapper">
      <ResponsiveDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${240}px)` },
          marginTop: {
            xs: "60px",
            md: "60px",
          },
          padding: "20px",
          background: "#f2f2f2",
        }}
      >
        {!isUserSubscribed &&
        isAuthenticate && 
        !pathname.includes("profile") &&
        !pathname.includes("help") &&
        !pathname.includes("privacy-policy") &&
        !pathname.includes("terms-of-service") ? (
          <div className="unsubscribedContainerWrapper">
            <div className="unsubscribedContainer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                }}
              >
                <img
                  src="/assets/lockgif.gif"
                  alt="lock"
                  className="lockimage"
                  style={{ width: "140px", height: "140px" }}
                />
                <h6 className="unsubscribedHeading">
                  This Feature is <br /> locked in Free Plan
                </h6>
                <p className="unsubscribedpara">
                  Please, Upgrade your plan to <br /> enjoy full benefits
                </p>
              </div>
              <FeatureCard feature={DashbordFeatures} />
            </div>
          </div>
        ) : (
          children
        )}
      </Box>
    </Box> */}
    </>
  );
}

export default DashBoardLayout;
