import React, { useRef, useState } from "react";
import HuluIcon from "../../assets/icons/hulu.svg";
import TimeIcon from "../../assets/icons/time-icon.svg";
import moment from "moment";
import FreeIcon from "../../assets/icons/free.svg";
import FreeIconExpired from "../../assets/icons/freeExpire.svg";
import { useNavigate } from "react-router";
import { deleteSubscription, GetUserCurrentSubscriptions, handleSubscriptionStatus } from "../../redux/features/subscriptionSlice";
import SubscriptionStatusModel from "../subscriptionStatusModel";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Skeleton from "react-loading-skeleton";
import DeleteSubscriptionModel from "../deleteSubscriptionModel";
import FullPageLoader from "../common/loaders/FullPageLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SubscriptionsCard = ({ item, index, handleOpenModel, activeTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteIconRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  const userData = JSON.parse(localStorage.getItem("usersData"));
  const { usersCurrentSubLoader, deleteSubscriptionLoader } = useSelector((state) => state.subscription);
  // let daysLeft = item?.end_date
  // ? Math.floor((new Date(item.end_date) - new Date()) / (1000 * 60 * 60 * 24))
  // : item?.start_date && item?.free_trial_days
  // ? Math.floor((new Date(new Date(item.start_date).getTime() + item.free_trial_days * 24 * 60 * 60 * 1000) - new Date()) / (1000 * 60 * 60 * 24))
  // : "0";
  let daysLeft = item?.end_date
    ? Math.floor((new Date(item.end_date) - new Date()) / (1000 * 60 * 60 * 24))
    : item?.start_date && item?.free_trial_days
    ? Math.floor((new Date(new Date(item.start_date).getTime() + item.free_trial_days * 24 * 60 * 60 * 1000) - new Date()) / (1000 * 60 * 60 * 24))
    : "0";
  let daysLeftForFree = item?.start_date && item?.free_trial_days ? Math.floor((new Date(new Date(item.start_date).getTime() + item.free_trial_days * 24 * 60 * 60 * 1000) - new Date()) / (1000 * 60 * 60 * 24)) : "0";

  const [activeModel, setActiveModel] = useState({
    isShow: false,
    id: null,
    isActive: false,
    start_date: "",
    end_date: "",
  });

  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null,
  });

  const handleChangeSubscriptionStatus = () => {
    let forInactiveData = { id: activeModel?.id, body: { active: activeModel?.isActive } };
    let forActiveData = { id: activeModel?.id, body: { active: activeModel?.isActive, start_date: activeModel?.start_date, end_date: activeModel?.end_date } };
    dispatch(handleSubscriptionStatus(activeModel?.isActive ? forActiveData : forInactiveData))
      .then((res) => {
        message.success(res?.payload);
        dispatch(GetUserCurrentSubscriptions({ status: activeTab }));
        setActiveModel({ isShow: false, id: null, isActive: false });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleDeleteSubscription = () => {
    let data = { id: deleteModel?.id, body: null };
    dispatch(deleteSubscription(data))
      .then((res) => {
        message.success(res?.messages || "Subscription Deleted Successfully");
        dispatch(GetUserCurrentSubscriptions({ status: activeTab ?? true }));
        setDeleteModel({
          id: null,
          isShow: false,
        });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    // <div className=" bg-white rounded-2xl">
    //   <div className="py-6 flex items-center justify-center">
    //     <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
    //   </div>
    //   <div className="px-4 pb-6">
    //     <p className="text-[12px] font-bold text-black300 tracking-normal mb-1 mobile:text-[10px]">
    //       Pay date: <span className="font-normal"> {item?.end_date ? moment(item.end_date).format("Do MMMM YYYY") : "-"}</span>
    //     </p>
    //     <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
    //       <span className="block text-xs font-normal text-black">{daysLeft >= 0 ? daysLeft + " Days remaining" : "Already Paid"}</span>
    //       <img src={TimeIcon} alt="TimeIcon" className="block" />
    //     </div>
    //     <p className="mt-2 text-lg text-black font-bold">
    //       ${item?.cost ?? 0} <span className="text-sm font-normal">{daysLeft >= 0 ? "to be paid" : "paid"}</span>
    //     </p>
    //   </div>
    //   <div className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px]">
    //     <p className="text-sm font-normal text-black300 text-center cursor-pointer">Access {item?.sub_name}</p>
    //   </div>
    // </div>

    usersCurrentSubLoader ? (
      <div className="bg-white rounded-2xl flex flex-col justify-between mobile:min-w-max mobile:max-w-[100%] mobile:w-full min-w-[240px] max-w-[240px] w-[240px]">
        <div>
          <div className="pt-3 py-3 flex items-center justify-center">
            <Skeleton circle width={60} height={60} />
          </div>
          <div className="px-4 pb-3 mobile:px-1.5 mobile:pb-2">
            <Skeleton height={20} width={120} />
            <Skeleton height={15} width={80} className="mt-1" />
            <Skeleton height={15} width={150} className="mt-1" />
            <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
              <Skeleton height={14} width={100} />
              <Skeleton width={12} height={12} />
            </div>
            <div className="pt-2">
              <div className="flex items-center gap-0.5">
                <div className="bg-[#f5f5f5] d-flex items-center justify-center py-2 px-3 rounded max-w-max">
                  <Skeleton height={20} width={60} />
                </div>
                <Skeleton height={20} width={80} className="ml-2" />
              </div>
              <div className="flex pt-2 items-center gap-3">
                <Skeleton height={18} width={50} />
                <Skeleton height={18} width={100} />
              </div>
            </div>
          </div>
        </div>
        <div className="p-2.5 bg-lightgray19 flex items-center justify-center rounded-b-[16px] cursor-pointer">
          <Skeleton height={20} width={80} />
        </div>
      </div>
    ) : item?.type === "free" ? (
      <div 
      className=" bg-white min-w-[240px] max-w-[240px] w-[240px] tab:min-w-[210px] tab:max-w-[210px] tab:w-[210px] mobile:min-w-max mobile:max-w-[100%] mobile:w-full rounded-2xl flex flex-col justify-between"
      onMouseEnter={() => setCurrentIndex(index)} onMouseLeave={() => setCurrentIndex(null)}
      >
        <div className="pt-3 py-3 flex items-center justify-center" onClick={() => handleOpenModel(item)}>
          <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
        </div>

        <div className="px-4 pb-3 mobile:px-1.5 mobile:pb-2" onClick={() => handleOpenModel(item)}>
          <p className="text-center text-lg text-black200 font-medium mobile:text-base truncate">{item?.sub_name}</p>
          {/* <span className="text-xs font-normal text-gray600 block text-center pb-2">{item?.categories?.[0]}</span> */}

          <div className="flex justify-center pb-2 mobile:flex-wrap" 
          // onMouseEnter={() => setCurrentIndex(index)} onMouseLeave={() => setCurrentIndex(null)}
          >
            {item?.categories?.length === 1 && (
              <div className=" px-1 ">
                <span className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
              </div>
            )}

            {item?.categories?.length === 2 && (
              <>
                <div className="border-solid pr-1 border-r border-borderColor1">
                  <span className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
                </div>

                {index === currentIndex && (
                  <ReactTooltip id="tooltip-1" place="top" style={{ zIndex: "9" }} content={<>{item?.categories?.[1]}</>} />
                  )}
                <div className=" px-1 ">
                  <span data-tooltip-id="tooltip-1" className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center cursor-pointer">
                    1 more
                  </span>
                </div>
              </>
            )}

            {item?.categories?.length === 3 && (
              <>
                {" "}
                {/* <div className="border-solid pr-1 border-r border-borderColor1">
                  <span className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center">{item?.categories?.[1]}</span>
                </div> */}
                <div className="border-solid px-1 border-r border-borderColor1">
                  <span className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center">{item?.categories?.[0]}</span>
                </div>
                {index === currentIndex && (
                  <ReactTooltip
                    id="tooltip-2"
                    place="top"
                    style={{ zIndex: "9" }}
                    content={
                      <>
                        {item?.categories?.[1]} <br /> {item?.categories?.[2]}
                      </>
                    }
                  />
                )}
                <div className="pl-1">
                  <span data-tooltip-id="tooltip-2" className="text-xs mobile:text-[10px] font-normal text-gray600 block text-center cursor-pointer">
                    2 more
                  </span>
                </div>
              </>
            )}
          </div>

          <p className="text-[12px] font-bold text-black300 tracking-normal mb-1 mobile:text-[10px]">
            Start Date: <span className="font-normal"> {item?.start_date ? moment(item.start_date).format("DD-MMM-YYYY") : "-"}</span>
          </p>
          <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
            <span className="block text-xs font-normal text-black">{daysLeftForFree > 0 ? daysLeftForFree + " Days remaining" : daysLeftForFree == 0 ? "Expire today" : "Expired"}</span>
            <img width={12} height={12} src={TimeIcon} alt="TimeIcon" className="block cursor-pointer" />
          </div>
          <span className="block text-xs font-normal text-gray700 pt-1">{item?.isPaymentMethod === 0 ? "Payment Not Added" : " Payment Added"}</span>
          <div className="flex items-center gap-3 py-2">
            {daysLeftForFree >= 0 ? <img src={FreeIcon} alt="FreeIcon" /> : <img src={FreeIconExpired} alt="FreeIcon" />}
            <p className="text-sm font-semibold text-black200 mobile:text-xs">{item?.free_trial_days ?? 0} Days</p>
          </div>
          <div className="flex items-center gap-3">
            <p className="text-sm font-semibold text-black200 mobile:text-xs">End Date:</p>
            <p className="text-xs font-medium text-black200">
              {item?.end_date ? moment(item?.end_date).format("DD-MMM-YYYY") : "-"}
              {/* {item?.end_date ? moment(item.end_date).format("Do MMM YYYY") : item.start_date ? moment(item.start_date).add(item?.free_trial_days, "days").format("Do MMM YYYY") : "-"} */}
            </p>
          </div>
        </div>

        <a href={item?.plan_link} target="_blank">
          <div className="bg-[#46aefc] p-2 flex items-center justify-center rounded-b-[16px]">
            <p className="text-sm font-normal text-black300 text-center cursor-pointer">Upgrade</p>
          </div>
        </a>
      </div>
    ) : (
      <div className=" bg-white relative group rounded-2xl flex flex-col justify-between mobile:min-w-max mobile:max-w-[100%] mobile:w-full min-w-[240px] max-w-[240px] w-[240px] ">
        <div onClick={() => handleOpenModel(item)}>
          {item?.active === 0 && (
            <div
              ref={deleteIconRef}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModel({ isShow: true, id: item?.id });
              }}
              className="absolute right-4 top-3 opacity-0 group-hover:opacity-[1] transition ease-in-out"
            >
              <i class="fa-solid fa-trash text-red-500 cursor-pointer"></i>
            </div>
          )}
          <div className="pt-3 py-3 flex items-center justify-center">
            <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
          </div>
          <div className="px-4 pb-3 mobile:px-1.5 mobile:pb-2">
            <p className="text-center text-lg text-black200 font-medium mobile:text-base truncate">{item?.sub_name}</p>
            {/* <span className="text-xs font-normal text-gray600 block text-center pb-2">{item?.categories?.[0]}</span> */}
            <div className="flex justify-center pb-2 mobile:flex-wrap" onMouseEnter={() => setCurrentIndex(index)} onMouseLeave={() => setCurrentIndex(null)}>
              {item?.categories?.length === 1 && (
                <div className=" px-1 ">
                  <span className="text-xs text-center leading-3 font-medium text-gray600 mobile:text-[10px]">{item?.categories?.[0]}</span>
                </div>
              )}

              {item?.categories?.length === 2 && (
                <>
                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <span className="text-xs text-center leading-3 font-medium text-gray600 mobile:text-[10px]">{item?.categories?.[0]}</span>
                  </div>

                  <div className=" px-1 ">
                    <span data-tooltip-id="tooltip-3" className="text-xs text-center leading-3 font-medium text-gray600 mobile:text-[10px] cursor-pointer">
                      1 more
                    </span>
                  </div>
                  {index === currentIndex && <ReactTooltip id="tooltip-3" style={{ zIndex: "9" }} place="top" content={item?.categories?.[1]} />}
                </>
              )}

              {item?.categories?.length === 3 && (
                <>
                  {" "}
                  {/* <div className="border-solid pr-1 border-r border-borderColor1">
                    <span className="text-xs text-center leading-3 font-medium text-gray600 mobile:text-[10px]">{item?.categories?.[1]}</span>
                  </div> */}
                  <div className="border-solid px-1 border-r border-borderColor1">
                    <span className="text-xs text-center leading-3 font-medium text-gray600 mobile:text-[10px]">{item?.categories?.[0]}</span>
                  </div>
                  <div className="pl-1">
                    <span data-tooltip-id="tooltip-4" className="text-xs text-center leading-3 cursor-pointer font-medium text-gray600 mobile:text-[10px]">
                      2 more
                    </span>
                  </div>
                  {index === currentIndex && (
                    <ReactTooltip
                      id="tooltip-4"
                      place="top"
                      style={{ zIndex: "9" }}
                      content={
                        <>
                          {item?.categories?.[1]} <br /> {item?.categories?.[2]}
                        </>
                      }
                    />
                  )}
                </>
              )}
            </div>

            {item?.type === "lifetime" ? (
              <p className="text-[12px] font-bold text-black300 tracking-normal mb-1 mobile:text-[10px]">
                Start Date: <span className="font-normal"> {item?.start_date ? moment(item.start_date).format("DD-MMM-YYYY") : "-"}</span>
              </p>
            ) : (
              <p className="text-[12px] font-bold text-black300 tracking-normal mb-1 mobile:text-[10px]">
                Next Renewal: <span className="font-normal"> {item?.end_date ? moment(item.end_date).format("DD-MMM-YYYY") : "-"}</span>
              </p>
            )}

            {/* <p className="text-[12px] font-bold text-black300 tracking-normal mb-1 mobile:text-[10px]">
              Next Renewal: <span className="font-normal"> {item?.end_date ? moment(item.end_date).format("Do MMM YYYY") : "-"}</span>
            </p> */}
            <div className="p-1 rounded bg-lightgray19 flex items-center justify-between">
              <span className="block text-xs font-normal text-black">{item?.type === "lifetime" ? "No Expiration" : daysLeft > 0 ? daysLeft + " Days remaining" : daysLeft == 0 ? "Expire today" : "Already Paid"}</span>
              <img src={TimeIcon} width={12} height={12} alt="TimeIcon" className="block cursor-pointer" />
            </div>
            <div className=" pt-2">
              <div className="flex items-center gap-0.5">
                <div className="bg-[#f5f5f5] d-flex items-center justify-center py-2 px-3 rounded max-w-max mobile:p-2">
                  <p className="text-base font-semibold text-black200 mobile:text-sm">
                    {userData?.currency ?? "$"}
                    {item?.cost ?? 0}
                  </p>
                </div>
                <span className="text-black text-sm font-medium upper capitalize"> {item?.type === "lifetime" ? item?.type : item?.plan_name}</span>
              </div>
              <div className="flex pt-2 items-center gap-3">
                <p className="text-sm font-semibold text-black200 mobile:text-xs">Plan:</p>
                <p className="text-xs font-medium text-black200 truncate">{item?.plan_variety}</p>
              </div>
            </div>
          </div>
        </div>
        {item?.active ? (
          <div className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px] cursor-pointer mobile:p-1.5 mobile:text-xs" onClick={() => navigate(`/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
            <p className="text-sm font-normal text-black300 text-center cursor-pointer">Manage</p>
          </div>
        ) : (
          <div
            className="bg-[#fe3c3cd4] p-2.5 flex items-center justify-center rounded-b-[16px] cursor-pointer"
            onClick={() => {
              setActiveModel({ isShow: true, id: item?.id, isActive: item?.active === 0 ? true : false });
            }}
          >
            <p className="text-sm font-normal text-black300 text-center cursor-pointer">Reactivate</p>
          </div>
        )}
        {activeModel?.isShow && <SubscriptionStatusModel activeModel={activeModel} setActiveModel={setActiveModel} handleChangeSubscriptionStatus={handleChangeSubscriptionStatus} />}
        {deleteModel?.isShow && <DeleteSubscriptionModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDeleteSubscription={handleDeleteSubscription} />}
        {deleteSubscriptionLoader && <FullPageLoader />}
      </div>
    )
  );
};

export default SubscriptionsCard;
