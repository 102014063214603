import { message } from "antd";
import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { inputClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AddCustomSubscription, GetALLSubscriptions, GetBrowseAllData } from "../../../../redux/features/subscriptionSlice";

function AddCustomSubsModel({ showCustomModel, setNewSubsData, newSubsData, setShowCustomModel, customSubsData, setCustomSubsData, open, setOpen, handleOpenAddModel }) {
  const { alladdinCategory } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const [error, setError] = useState();

  const handleCloseCustomModel = () => {
    setShowCustomModel({
      isOpen: false,
      subScriptionId: null,
    });
    setCustomSubsData({});
  };

  const validateData = () => {
    let isValid = true;
    let err = {};

    if (!customSubsData?.name) {
      err.name = "Please enter subscription name";
      isValid = false;
    }

    if (!customSubsData?.subLogo) {
      err.subLogo = "Please upload subscription logo";
      isValid = false;
    }

    if (customSubsData?.categories?.length === 0) {
      err.categories = "Please select at least one category";
      isValid = false;
    } else if (!customSubsData?.categories) {
      err.categories = "Please select at least one category";
      isValid = false;
    }

    if (!customSubsData?.description) {
      err.description = "Please enter subscription description";
      isValid = false;
    }

    setError(err);
    return isValid;
  };

  const handleNextStep = () => {
    if (validateData()) {
      let categoryid = customSubsData?.categories?.map((item) => item.id);

      const formData = new FormData();
      formData.append("sub_name", customSubsData?.name);
      formData.append("sub_description", customSubsData?.description);
      // formData.append("categoryId", JSON.stringify(categoryid));
      const mapData = categoryid.map((id, index) => {
        return formData.append(`categoryId[${index}]`, id);
        // return formData.append(`categoryId${[index]}`, JSON.stringify([id]));
      });
      formData.append("image", customSubsData?.subLogo);
      dispatch(AddCustomSubscription(formData))
        .then((res) => {
          if(res?.payload?.success){
            handleOpenAddModel();
            dispatch(GetALLSubscriptions());
            dispatch(GetBrowseAllData());
            setShowCustomModel({ ...showCustomModel, subScriptionId: res?.payload?.payload?.sub_id, isOpen: false });
            setNewSubsData({ ...newSubsData, sub_title: res?.payload?.payload?.sub_id });
            setCustomSubsData({
              name: "",
              subLogo: null,
              description: "",
              categories: [],
            });
          } else {
            message.error(res?.payload?.message);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const fileType = file?.type;
    const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
    if (validImageTypes.includes(fileType)) {
      const size = (file.size / 1024 / 1024).toFixed(2);

      if (size > 50) {
        message.error("File size should be less than 50MB");
        return;
      }

      setCustomSubsData({ ...customSubsData, subLogo: file });
      setError({ ...error, subLogo: "" });
    } else {
      message.error("Please upload a JPG or PNG image.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value.trimStart();
    let formattedValue = trimmedValue.replace(/\s+/g, " ");
    setCustomSubsData({ ...customSubsData, [name]: formattedValue });
    setError({ ...error, [name]: "" });
  };

  const handleCategoryChange = (selectedList, selectedItem) => {
    const currentCategories = customSubsData?.categories || [];

    // Check if the user is trying to add a new item
    if (!currentCategories.includes(selectedItem)) {
      if (selectedList.length > 3) {
        message.error("You can select a maximum of 3 categories");
        return; // Exit the function if the limit is exceeded
      }
    }

    if (selectedList.length === 3) {
      message.warning("You can select max 3 categories. Remove one to add another.");
    }
    setCustomSubsData((prevState) => ({
      ...prevState,
      categories: selectedList,
    }));
    setError({ ...error, categories: "" });
  };

  return (
    <div className="global-modal z-[999]">
      <div className="bg-white w-[450px] p-5 rounded-lg mobile:max-h-[calc(100vh-100px)] mobile:overflow-auto mobile:w-[calc(100%-20px)]">
        <p className="text-lg text-black200 font-medium mb-5">Add New Subscription</p>
        <div className="grid grid-cols-[1fr] gap-0 items-center">
          <div className="w-[110px] relative max-w-[110px] mx-auto">
            <div className="w-[110px] flex items-center justify-center min-w-[110px] h-[110px] rounded-lg border border-solid border-borderColor2">
              {customSubsData?.subLogo ? (
                <img className="rounded-lg block w-full h-full object-cover" src={URL.createObjectURL(customSubsData?.subLogo)} alt="Subscription Logo" />
              ) : (
                <span className="text-sm font-medium text-black300 block text-center">
                  Select Your <br /> Image<span className="text-red-500">*</span>
                </span>
              )}
            </div>
            <input type="file" className="w-full h-full absolute top-0 right-0 opacity-0 cursor-pointer indent-[-999px]	" onChange={(e) => handleUploadImage(e)} />
            <div className="w-7 h-7 bg-primary rounded-full absolute bottom-[-5px] right-[-5px] flex items-center justify-center cursor-pointer">
              <input type="file" className="w-full h-full absolute top-0 right-0 opacity-0 cursor-pointer indent-[-999px]	" onChange={(e) => handleUploadImage(e)} />
              <i class="fa-solid fa-upload text-xs cursor-pointer"></i>
            </div>
          </div>
          <span className="text-red-500 text-[12px] flex justify-center mt-2 font-medium">{error?.subLogo}</span>
          <div className="pt-5">
            <div className="grid grid-cols-1 gap-4">
              <div className="mb-4">
                <label className="block text-sm text-black333 pb-1 font-medium">
                  Subscription Name<span className="text-red-500">*</span> <span className="text-red-500 text-[12px]">{error?.name}</span>
                </label>
                <input
                  className="w-full h-10 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
                  placeholder="Subscription name"
                  name="name"
                  value={customSubsData?.name}
                  onChange={(e) => handleChange(e)}
                  type="text"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm text-black333 pb-1 font-medium">
                Subscription Category<span className="text-red-500">*</span> <span className="text-red-500 text-[12px]">{error?.categories}</span>
              </label>
              <Multiselect
                options={customSubsData?.categories?.length >= 3 ? [] : alladdinCategory} // Options should be an array of objects
                selectedValues={customSubsData?.categories || []} // Preselected values
                onSelect={handleCategoryChange} // Function to handle selection
                onRemove={handleCategoryChange} // Function to handle removal
                displayValue="name" // Property to display
                placeholder="Select categories"
                className="custom-multiselect"
                style={{
                  multiselectContainer: {
                    background: "#f8f9fa",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0",
                    fontSize: "16px",
                  },
                  searchBox: {
                    border: "none",
                    fontSize: "16px",
                    outline: "none",
                  },
                  notFound: {
                    fontSize: "12px",
                  },
                  optionContainer: {
                    background: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  },
                  option: {
                    fontSize: "14px",
                    padding: "5px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  },
                  inputField: {
                    fontSize: "12px",
                  },
                  chips: {
                    background: "#D0EE11",
                    color: "#000",
                  },
                }}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm text-black333 pb-1 font-medium">
                Short Description<span className="text-red-500">*</span> <span className="text-red-500 text-[12px]">{error?.description}</span>
              </label>
              <textarea
                name="description"
                value={customSubsData?.description}
                className=" resize-none w-full h-24 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
                onChange={(e) => handleChange(e)}
                placeholder="Description"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-3 gap-3">
          <button className="px-6 py-2 block border-none text-sm font-medium text-black300 rounded bg-inputBackground" onClick={() => handleCloseCustomModel()}>
            Cancel
          </button>

          <button className="px-6 py-2 block border-none text-sm font-medium text-black300 rounded bg-primary" onClick={() => handleNextStep()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCustomSubsModel;
