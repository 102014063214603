import React from "react";

const FullPageLoader = () => {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loading">
          <span class="relative flex h-12 w-12">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-greenLight opacity-75"></span>
            <span class="relative inline-flex rounded-full h-12 w-12 bg-green"></span>
          </span>
        </div>
      </div>
    </>
  );
};

export default FullPageLoader;
