import React from 'react'

const TwitterIcon = () => {
    return (
        <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <g clip-path="url(#clip0_1385_1344)">
                <path d="M17.9525 6.05896C17.9642 6.23479 17.9642 6.41146 17.9642 6.58896C17.9642 12.0115 13.8358 18.264 6.28917 18.264V18.2606C4.06 18.264 1.87667 17.6256 0 16.4215C0.324167 16.4606 0.65 16.4798 0.976667 16.4806C2.825 16.4823 4.62 15.8623 6.0725 14.7206C4.31667 14.6873 2.77667 13.5423 2.23917 11.8706C2.85417 11.989 3.4875 11.9648 4.09167 11.8006C2.17667 11.414 0.8 9.73146 0.8 7.77812C0.8 7.76062 0.8 7.74312 0.8 7.72646C1.37083 8.04479 2.00917 8.22062 2.6625 8.23979C0.859167 7.03562 0.3025 4.63729 1.39167 2.76229C3.475 5.32562 6.54917 6.88396 9.84833 7.04979C9.5175 5.62479 9.97 4.13146 11.035 3.12896C12.6875 1.57479 15.2867 1.65479 16.8408 3.30729C17.76 3.12646 18.6408 2.78896 19.4467 2.31146C19.14 3.26146 18.4992 4.06812 17.6425 4.58146C18.4567 4.48396 19.2508 4.26646 20 3.93479C19.4492 4.75896 18.7558 5.47812 17.9525 6.05896Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1385_1344">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.139648)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TwitterIcon
