import React from "react";
import styles from "./about.module.css";
import ValueCard from "./ValueCard";
const OurValues = () => {
  const values = [
    {
      id: 1,
      value: "Cost Efficiency",
      icon: "/assets/teamwork.svg",
      desc: "We are dedicated to helping users reduce expenses while continuing to enjoy entertainment content.",
    },
    {
      id: 2,
      value: "Streamlined Management",
      icon: "/assets/commiter.svg",
      desc: " At Alladdin, we strive to organize subscriptions efficiently so they can be managed with minimal hassle.",
    },
    {
      id: 3,
      value: "Customer Focus",
      icon: "/assets/positivity.svg",
      desc: "Our top priority is customer satisfaction, and this philosophy guides our work.",
    },
    {
      id: 4,
      value: "Innovation Leadership",
      icon: "/assets/innovation.svg",
      desc: "Through new ideas and innovative techniques, our team continuously works to enhance the user experience.",
    },
    {
      id: 5,
      value: "Security and Privacy",
      icon: "/assets/growth.svg",
      desc: "Customer data and payments are fully protected from breaches or fraudulent activities",
    },
    {
      id: 6,
      value: "24/7 Support",
      icon: "/assets/ownership.svg",
      desc: "Customers can contact our support team anytime assistance is needed. ",
    },
  ];
  return (
    <div
      className="wholeWidthContainer"
      style={{ position: "relative", background: "#F2F2F2" }}
    >
      <div className="pageWrapper">
        <div className={styles.ourvaluecontainer}>
          <h2 className="header_2" style={{ fontWeight: 700 }}>
            Our Values
          </h2>

          <div className={styles.valuessection}>
            {values.map((item, id) => (
              <ValueCard key={`${item.id}-${id}`} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
