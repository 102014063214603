import React from 'react'
import RoundCheckIcon from '../../../svg/RoundCheckIcon'
import MissionImage from '../../../assets/images/missions.webp';
import MissionImage2 from '../../../assets/images/missions2.webp';
const OurMission = () => {
  return (
    <div className='py-100 mobile:py-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <div className='grid grid-cols-[547px_1fr] gap-12 tab:grid-cols-1'>
            <div>
                <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal font-bold text-black300 leading-normal mb-6'>
                Our Mission
                </h2>
                <p className='text-lg font-normal text-black400 leading-normal mb-6'>
                    Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is purpose built for ease of use 
                    and completxability to create.
                </p>
                <div className='flex items-center gap-2 pb-6'>
                <RoundCheckIcon/>
                    <span className='block text-lg font-semibold text-black200'>
                    Posting to social media, blogs, and messengers
                    </span>
                </div>
                <div className='flex items-center gap-2 pb-6'>
                <RoundCheckIcon/>
                    <span className='block text-lg font-semibold text-black200'>
                    Working with images and videos
                    </span>
                </div>
                <div className='flex items-center gap-2 pb-6'>
                <RoundCheckIcon/>
                    <span className='block text-lg font-semibold text-black200'>
                    The Future of Writing Blog Articles
                    </span>
                </div>
            </div>
            <div>
                <div className='grid grid-cols-2 gap-5'>
                    <div>
                        <img src={MissionImage} className='block h-[420px] mobile:h-[320px] rounded-lg' alt='MissionImage'/>
                    </div>
                    <div className='mt-120 mobile:mt-60'>
                        <img src={MissionImage2} className='block h-[420px] mobile:h-[320px] rounded-lg' alt='MissionImage2'/>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default OurMission
