import React, { useState } from "react";
import styles from "./contact.module.css";
import Appconstants from "../../constants/AppConstants";
import axios from "axios";

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [company, setCompany] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  // /api/auth/contactus
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("message");
    const data = { email, fullname, company, subject, message }
    const rslt = await axios.post(`${Appconstants.baseURL}/auth/contactus`, { data })
    console.log(rslt.data.message, "rslt");
  }

  return (
    <div className={styles.formwrapper}>
      <form onSubmit={handleSubmit}>
        <div className={styles.upperForm}>
          <div className={styles.inputfieldContainer}>
            <div className={styles.formcontrol}>
              <label htmlFor="fullname">Full Name</label>
              <input
                type="text"
                id="fullname"
                className="inputcontrol"
                placeholder="Enter Full Name"
                onChange={(e) => { setFullname(e.target.value) }}
              />
            </div>
          </div>
          <div className={styles.inputfieldContainer}>
            <div className={styles.formcontrol}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="inputcontrol"
                placeholder="Enter Email Address"
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </div>
          </div>
          <div className={styles.inputfieldContainer}>
            <div className={styles.formcontrol}>
              <label htmlFor="company">Company</label>
              <input
                type="text"
                id="company"
                className="inputcontrol"
                placeholder="Add Subscription Name"
                onChange={(e) => { setCompany(e.target.value) }}
              />
            </div>
          </div>
          <div className={styles.inputfieldContainer}>
            <div className={styles.formcontrol}>
              {" "}
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                className="inputcontrol"
                placeholder="How can we help"
                onChange={(e) => { setSubject(e.target.value) }}
              />
            </div>
          </div>
        </div>
        <div className={styles.lowerForm}>
          <div className="w-full">
            <div className={styles.formcontrol}>
              <label htmlFor="message">Message</label>
              <textarea
                rows={4}
                id="message"
                className="inputcontrol"
                placeholder="Add Description"
                onChange={(e) => { setMessage(e.target.value) }}
              />
            </div>
          </div>
          <button type="submit" className={styles.formBtn}>
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
