import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import JoinSection from "./JoinSection";
import FeaturesSection from "../commonComponents/FeaturesSection";
import featuresJson from "../commonComponents/featuresJson";
import styles from "./explore.module.css";
import ExtraFeatures from "./ExtraFeatures";
import Testimonials from "./Testimonials";
import HomePage from "../../modules/home";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/authContext";

const Explore = () => {
  const { isAuthenticate } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticate) {
      // if (history || history === null || history === undefined) navigate(`/dashboard`, { replace: true, });
      // else navigate(`/${history}`, { replace: true, })
      navigate(`/dashboard`, { replace: true });
    }
  }, [isAuthenticate, navigate]);
  
  return (
    <>
    <HomePage/>
      {/* <HeroSection />
      <JoinSection />
      <div className="marg_100">
        <FeaturesSection feature={featuresJson[0]} />
      </div>
      <div
        className={`wholeWidthContainer marg_100 ${styles.container}`}
        style={{ background: "#F2F2F2" }}
      >
        <FeaturesSection feature={featuresJson[1]} />
      </div>
      <div className="marg_100">
        <FeaturesSection feature={featuresJson[2]} />
      </div>
      <div
        className={`wholeWidthContainer marg_100 ${styles.container}`}
        style={{ background: "#D0EE11" }}
      >
        <FeaturesSection feature={featuresJson[3]} />
      </div>
      <div className="marg_100">
        <FeaturesSection feature={featuresJson[4]} />
      </div>
      <ExtraFeatures />
      <Testimonials /> */}
    </>
  );
};

export default Explore;
