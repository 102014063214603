"use client";
import React, { useState } from "react";
import styles from "./contact.module.css";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Faq = [
  {
    id: "1",
    question: (
      <h4 className={styles.faqHeading}>
        Q1. Can all subscriptions be managed through Alladdin?
      </h4>
    ),
    answer: (
      <p className={styles.faqAns}>
        Yes, Alladdin allows users to manage all subscriptions from within the
        platform.
      </p>
    ),
  },
  {
    id: "2",
    question: (
      <h4 className={styles.faqHeading}>
        Q2. Does Alladdin assist with auto-renewing subscriptions through the
        website?
      </h4>
    ),
    answer: (
      <p className={styles.faqAns}>
        Alladdin makes auto-renewing and managing subscriptions straightforward.
        Timely reminders are sent prior to renewal dates.
      </p>
    ),
  },
  {
    id: "3",
    question: (
      <h4 className={styles.faqHeading}>
        Q3. Does Alladdin offer a free trial?
      </h4>
    ),
    answer: (
      <p className={styles.faqAns}>
        Alladdin offers a 30 days free trial. Users can choose to subscribe
        for ongoing access if they find the platform useful during the trial
        period.
      </p>
    ),
  },
  {
    id: "4",
    question: (
      <h4 className={styles.faqHeading}>
        Q4. What benefits does an Alladdin subscription provide?
      </h4>
    ),
    answer: (
      <p className={styles.faqAns}>
        An Alladdin subscription consolidates subscription data, usage tracking,
        and management tools in a centralized location for ease of use.
      </p>
    ),
  },
  {
    id: "5",
    question: (
      <h4 className={styles.faqHeading}>
        Q5. How can an Alladdin subscription be canceled?
      </h4>
    ),
    answer: (
      <p className={styles.faqAns}>
        Subscriptions can be canceled at any time by contacting Alladdin's
        customer support number or email address.
      </p>
    ),
  },
];

const FaqSection = () => {
  const [expanded, setExpanded] = useState([0]);

  const handleChange = (panel) => (event, newExpanded) => {
    console.log(panel, newExpanded, "ggggggggggg");
    let expandData = [...expanded];
    if (newExpanded) {
      expandData.push(panel);
    } else {
      expandData = expandData.filter((item) => item !== panel);
    }
    setExpanded([...expandData]);
  };
  const CustomExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <span style={{ fontSize: "20px", cursor: "pointer" }}>
        <img
          src={"/assets/minuscircle.svg"}
          width={24}
          height={24}
          alt="minuscircle"
        />
      </span>
    ) : (
      <span style={{ fontSize: "20px", cursor: "pointer" }}>
        <img
          src={"/assets/pluscircle.svg"}
          width={24}
          height={24}
          alt="pluscircle"
        />
      </span>
    );
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    margin: "0px",
    padding: "0px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "none",
      background: "#f5f5f5",
    },
    // "&:not(.Mui-expanded)": {
    //   borderBottom: "1px solid rgba(0, 0, 0, .125)",
    // },
    "& .MuiAccordionSummary-content": {
      margin: theme.spacing(0),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    margin: "0px",
    padding: "0px",
    // paddingBottom: "32px",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
  }));
  return (
    <div className="pageWrapper">
      <div className={styles.faqcontainer}>
        <h2
          className="header_2"
          style={{ color: "#030303", fontWeight: "700" }}
        >
          Frequently Asked Questions
        </h2>

        <div>
          {Faq?.map((faq, index) => (
            <Box key={index + 1}>
              <Accordion
                expanded={expanded.includes(index)}
                onChange={handleChange(index)}
                className="accordioncontainer"
              >
                <AccordionSummary className="Panel_faq" expandIcon={false}>
                  {faq?.question}
                </AccordionSummary>
                <AccordionDetails>
                  {typeof faq?.answer === "object" ? (
                    faq?.answer
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: faq?.answer }}
                    ></div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
