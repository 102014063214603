import React from 'react'

const AllAppsTable = () => {
    return (
        <>
            <div className='w-full bg-white rounded-xl p-4 overflow-auto'>
                <table className='w-full border-collapse '>
                    <thead>
                        <tr className='border-solid border-b border-borderColor1'>
                            <th className='text-base font-medium text-left text-black400 p-2'></th>
                            <th className='text-base font-medium text-left text-black400 p-2'>Name</th>
                            <th className='text-base font-medium text-left text-black400 p-2'>Price</th>
                            <th className='text-base font-medium text-left text-black400 p-2'>Renewal Date</th>
                            <th className='text-base font-medium text-left text-black400 p-2'>Last visited</th>
                            <th className='text-base font-medium text-left text-black400 p-2'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        [...Array(10)].map(()=> {
                            return(
                                <tr className=' border-solid border-b border-borderColor1 hover:bg-inputBackground'>
                                <td className='text-sm font-normal text-left text-black400 p-2'>
                                    <img className='w-8 h-8 rounded-full block object-cover shadow-4xl' src="https://m.media-amazon.com/images/I/41F9mjnTWpL._AC_UF1000,1000_QL80_.jpg" />
                                </td>
                                <td className='text-sm font-normal text-left text-black400 p-2'>Disney+ Hotstar</td>
                                <td className='text-sm font-normal text-left text-black400 p-2'>$15.99/mo</td>
                                <td className='text-sm font-normal text-left text-black400 p-2'>
                                    8 Sep 2023
                                    <p className='m-0 text-gray650 text-xs '>
                                        (7 Days left)
                                    </p>
                                </td>
                                <td className='text-sm font-normal text-left text-black400 p-2'>7 Days ago</td>
                                <td className='text-sm font-normal text-left text-black400 p-2'>
                                    <div className='flex items-center gap-3'>
                                        <button className='bg-primary text-black200 text-sm py-1 font-medium px-5 rounded border-none cursor-pointer'>
                                            Cancel
                                        </button>
                                        <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_1079_3756)">
                                                <path d="M15.216 0.783999C14.7065 0.2971 14.0288 0.0253906 13.324 0.0253906C12.6192 0.0253906 11.9416 0.2971 11.432 0.783999L1.07401 11.142C0.732617 11.4815 0.46192 11.8853 0.277573 12.3301C0.0932258 12.7749 -0.00111372 13.2519 9.9204e-06 13.7333V15C9.9204e-06 15.2652 0.105367 15.5196 0.292903 15.7071C0.48044 15.8946 0.734793 16 1.00001 16H2.26668C2.74838 16.0013 3.22556 15.907 3.67059 15.7227C4.11562 15.5383 4.51967 15.2676 4.85934 14.926L15.216 4.568C15.7171 4.06582 15.9985 3.3854 15.9985 2.676C15.9985 1.9666 15.7171 1.28617 15.216 0.783999ZM3.44401 13.512C3.13093 13.823 2.708 13.9984 2.26668 14H2.00001V13.7333C2.00138 13.2916 2.1767 12.8681 2.48801 12.5547L10.2 4.84467L11.1553 5.8L3.44401 13.512ZM13.8 3.154L12.5693 4.38667L11.6133 3.43067L12.8467 2.2C12.9753 2.07705 13.1464 2.00844 13.3243 2.00844C13.5023 2.00844 13.6734 2.07705 13.802 2.2C13.9277 2.32704 13.9981 2.49867 13.9977 2.67741C13.9974 2.85615 13.9263 3.02749 13.8 3.154Z" fill="#5A6ACF" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1079_3756">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </td>
    
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AllAppsTable
