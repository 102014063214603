import React, { useEffect, useState } from "react";
import FaqSection from './faqSection'
import axios from "axios";
import Appconstants from "../../constants/AppConstants";
import FullPageLoader from "../../components/common/loaders/FullPageLoader";
import { message } from "antd";

const Contactus = () => {
  const [inputValue, setInputValue] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart();
    if (value !== trimmedValue) {
      setInputValue({ ...inputValue, [name]: trimmedValue });
    } else {
      if(name === "email"){
        setInputValue({ ...inputValue, [name]: value.toLowerCase() });
      }
      else{
        setInputValue({ ...inputValue, [name]: value });
      }
      setError({ ...error, [name]: "" });
    }
  };

  const validateData = () => {
    let isValid = true;
    let err = {};
    // let regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

    if (!inputValue?.fullname) {
      err.fullname = "Please enter your full name";
      isValid = false;
    }

    // if (!inputValue?.email) {
    //   err.email = "Please enter your email address";
    //   isValid = false;
    // } else if (inputValue?.email && regexEmail.test(inputValue?.email) === false) {
    //   err.email = "Please enter valid email address";
    //   isValid = false;
    // }
    if (!inputValue.email) {
      err.email = "Please enter your email";
      isValid = false;
    } else if (inputValue.email && !emailRegex.test(inputValue.email)) {
      err.email = "Please enter a valid email";
      isValid = false;
    } else if (inputValue.email.includes(";")) {
      err.email = "Emails cannot contain semicolons.";
      isValid = false;
    }

    if (!inputValue?.company) {
      err.company = "Please enter company name";
      isValid = false;
    }

    if (!inputValue?.subject) {
      err.subject = "Please enter your subject";
      isValid = false;
    }

    if (!inputValue?.message) {
      err.message = "Please enter your message";
      isValid = false;
    }
    setError(err);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateData()) {
      setIsLoading(true);
      const rslt = await axios.post(`${Appconstants.baseURL}/auth/contactus`, { email: inputValue?.email, fullname: inputValue?.fullname, company: inputValue?.company, subject: inputValue?.subject, message: inputValue?.message }).then((res) =>{
        setIsLoading(false);
        message.success(res?.data?.messages)
        return res;
      }).catch((err) =>
        {
          setIsLoading(false);
        }
      );
      console.log(rslt.data.message, "rslt");
      setInputValue({...inputValue, fullname: '', email: '', company:"", subject:"", message:''});
    }
  };

  useEffect(() =>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <div>
      <div className="pt-60 pb-120 mobile:py-60 bg-primary">
        <div className="max-w-1160 mx-auto px-5">
          <h2 className="text-[40px] text-black300 font-semibold leading-[48px] mb-4 mobile:text-[26px] mobile:leading-normal">Get in Touch</h2>
          <p className="text-xl text-gray650 font-normal max-w-[450px] mobile:text-base mobile:leading-8">If you have any questions or would like to speak to us, please contact us. We are ready for you!</p>
        </div>
      </div>
      <div className="pb-100 mobile:pb-60">
        <div className="max-w-1160 mx-auto px-5">
          <div className="grid grid-cols-[468px_1fr] gap-100 mobile:grid-cols-1 mobile:gap-8">
            <div className="pt-60 mobile:pt-0">
              {/* <div className="grid grid-cols-[130px_1fr] mobile:grid-cols-1 mobile:gap-1 gap-60 py-4 border-solid border-b border-borderColor1">
                <p className="text-base text-gray600 font-semibold m-0">Contact Nunber</p>
                <a className="text-base block text-black300 font-semibold m-0">(856) 635-1130</a>
              </div> */}
              <div className="grid grid-cols-[130px_1fr] mobile:grid-cols-1 mobile:gap-1 gap-60 py-4 border-solid border-b border-borderColor1">
                <p className="text-base text-gray600 font-semibold m-0">E-mail</p>
                <a href="mailto:info@alladdinco.com" className="text-base block text-black300 font-semibold m-0">info@alladdinco.com</a>
              </div>
              {/* <div className="grid grid-cols-[130px_1fr] mobile:grid-cols-1 mobile:gap-1 gap-60 py-4 ">
                <p className="text-base text-gray600 font-semibold m-0">Address</p>
                <a className="text-base block text-black300 font-semibold m-0">325 Hillside Ave, Camden, New Jersey(NJ), 08105</a>
              </div> */}
            </div>
            <div>
              <div className="bg-white rounded-lg shadow-3xl p-8 mobile:p-5 mt-[-120px] mobile:mt-0">
                <h2 className="text-2xl text-black300 font-semibold mb-6">Contact Form</h2>
                <div className="grid grid-cols-2 gap-5 mobile:grid-cols-1">
                  <div>
                    <label className="block text-sm text-black300 font-medium leading-normal pb-2">
                      Full Name<span style={{ color: "red" }}>*</span>
                      <span style={{ color: "red", fontSize: "12px", margin: "7px" }}>{error?.fullname}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      className="text-sm font-medium text-black300 h-12 w-full px-4 rounded-md bg-inputBackground border-none focus:outline-none placeholder:text-sm"
                      name="fullname"
                      value={inputValue?.fullname}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-black300 font-medium leading-normal pb-2">
                      Email<span style={{ color: "red" }}>*</span>
                      <span style={{ color: "red", fontSize: "12px", margin: "7px" }}>{error?.email}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Email Address"
                      className="text-sm font-medium text-black300 h-12 w-full px-4 rounded-md bg-inputBackground border-none focus:outline-none placeholder:text-sm"
                      name="email"
                      value={inputValue?.email}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-black300 font-medium leading-normal pb-2">
                      Company<span style={{ color: "red" }}>*</span>
                      <span style={{ color: "red", fontSize: "12px", margin: "7px" }}>{error?.company}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      className="text-sm font-medium text-black300 h-12 w-full px-4 rounded-md bg-inputBackground border-none focus:outline-none placeholder:text-sm"
                      name="company"
                      value={inputValue?.company}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm text-black300 font-medium leading-normal pb-2">
                      Subject<span style={{ color: "red" }}>*</span>
                      <span style={{ color: "red", fontSize: "12px", margin: "7px" }}>{error?.subject}</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Subject here"
                      className="text-sm font-medium text-black300 h-12 w-full px-4 rounded-md bg-inputBackground border-none focus:outline-none placeholder:text-sm"
                      name="subject"
                      value={inputValue?.subject}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="pt-5">
                  <label className="block text-sm text-black300 font-medium leading-normal pb-2">
                    Message<span style={{ color: "red" }}>*</span>
                    <span style={{ color: "red", fontSize: "12px", margin: "7px" }}>{error?.message}</span>
                  </label>
                  <textarea
                    placeholder="How can we help"
                    className="text-sm font-medium text-black300 h-[120px] resize-none w-full p-4 rounded-md bg-inputBackground border-none focus:outline-none placeholder:text-sm"
                    name="message"
                    value={inputValue?.message}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </div>
                <div className="pt-8 flex items-center justify-center">
                  <button className="py-[14px] px-4 bg-primary text-black300 rounded-lg border-none text-sm font-medium cursor-pointer" onClick={() => handleSubmit()}>
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FaqSection/>
      {isLoading && <FullPageLoader />}
    </div>
  );
};

export default Contactus;
