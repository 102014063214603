import SimplifiedSubscriptionImage from '../../../assets/images/SimplifiedSubscription.webp';
import VecIcon from '../../../assets/icons/vec.svg';
const SimplifiedSubscription = () => {
  return (
    <div className='py-100 mobile:py-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <div className='grid grid-cols-[451px_1fr] gap-[100px] tab:grid-cols-1 tab:gap-60'>
            <div className='relative z-10'>
            <div className='border-8 border-solid border-white bg-white shadow-2xl rounded-[18px] '>
                <img src={SimplifiedSubscriptionImage} alt='SimplifiedSubscriptionImage' className='block w-full rounded-[18px]' />
                <div className=' absolute bottom-[-40px] right-[-60px] z-[-1] tab:hidden'>
                    <img src={VecIcon} alt='VecIcon' className='block' />
                </div>
            </div>
            </div>
            <div>
                <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal tracking-normal text-black300 font-semibold mb-5'>
                Simplified & Optimized <br /> For Easy <br />Management.
                </h2>
                <p className='text-lg tracking-normal mobile:text-base mobile:leading-8 text-gray600 font-normal mb-5'>
                Envision a seamless space where every subscription, from movies to utilities, is neatly organized. No more lost bills or overlooked apps. Our platform offers unparalleled clarity, presenting all your commitments in one dashboard. From unnoticed music plans 
                to redundant storages, we bring transparency and simplicity. Experience the revolution in subscription management.
                </p>
                <button onClick={() => window.open("https://alladdin-web.netlify.app/login", "_blank")} className='bg-transparent hover:bg-black300 hover:text-white transition ease-in-out border border-solid border-black300 text-sm font-normal tracking-normal py-3 px-4 text-black300 rounded-lg'>
                Learn More
                </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default SimplifiedSubscription
