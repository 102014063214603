

import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'

import Datatable from '../../common/commonComponents/Datatable/Datatable.js'



function RecommendedApp() {

    
    const columns = [
        {
            name: 'sub_logo',
            label: '',
            options: {
                // customBodyRender: (id, tableMeta) => {
                //     let data = {}
                //     data.id = id
                //     data.Store = tableMeta.rowData[7]
                //     data.PID = tableMeta.rowData[8]
                //     return (
                //         <ButtonGroup orientation="horizontal" variant="text" color="success" size="small">
                //             <Button color="success" onClick={() => updateDeal(data, "approve")}>
                //                 <strong>Approve</strong>
                //             </Button>
                //             <Button color="error" onClick={() => updateDeal(data, "reject")}>
                //                 <strong>Reject</strong>
                //             </Button>
                //         </ButtonGroup>
                //     )
                // },
                filter: true,
                sort: true,
            }
        },
        {
            name: 'sub_name',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
            }
        },

        {
            name: 'cost',
            label: 'Price',
            options: {
                // customBodyRender: (link) => {
                //     return <a href={link} target="_blank" rel="noreferrer">{link}</a>
                // },
                // filter: true,
                // sort: true,
            }
        },
    
        {
            name: 'sub_name',
            label: 'Actions',
            options: {
                // customBodyRender: (pid, tableMeta) => {
                //     return (

                //         <PIDGraph
                //             // allDealsPanelData={allDealsPanelData}
                //             pid={pid}
                //             sid={tableMeta.rowData[7]}
                //         />
                //     )
                // },
            }
        },
    
  

    ]


    return (
        <>
            <Datatable name="Recommend Apps" columns={columns} data={[]} />
        </>
    )
}

export default RecommendedApp