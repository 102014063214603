import React from "react";
import HeroSection from "./HeroSection";
import FormSection from "./FormSection";
import FaqSection from "./FaqSection";


const Contact = () => {
  return (
    <>
      {/* <HeroSection /> */}
      <FormSection />
      <FaqSection />
  
    </>
  );
};

export default Contact;
