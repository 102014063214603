import React from "react";
import cn from "classnames";
// import Icon from "../../../assets/svg/repeat-icon.svg";
export default function Pagination(props) {
  const { pages = 1, current = 1, wrapperClass, onClick, setSelectedRows } = props;
  const onPrev = () => {
    onClick && onClick(current - 1);
  };
  const onNext = () => {
    onClick && onClick(current + 1);
  };
  const onPrevDash = () => {
    onClick && onClick(current - 2);
  };
  const onNextDash = () => {
    onClick && onClick(current + 2);
  };
  const onFirst = () => {
    onClick && onClick(1);
  };
  const onLast = () => {
    onClick && onClick(pages);
  };

  return (
    <div className={cn(wrapperClass)}>
      <div className="flex items-center gap-3 pt-4 justify-end" id="scrollToTopButton">
        {current !== 1 ? (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onPrev();
            }}
          >
            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
          </div>
        ) : (
          <div className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white">
            <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg>
          </div>
        )}
        {current !== 1 && (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onFirst();
            }}
          >
            1
          </div>
        )}
        {current > 3 && (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onPrevDash();
            }}
          >
            ...
          </div>
        )}
        {current - 1 > 1 && (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onPrev();
            }}
          >
            {current - 1}
          </div>
        )}
        <div className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg bg-primary hover:bg-white active">{current}</div>
        {current + 1 < pages && (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onNext();
            }}
          >
            {current + 1}
          </div>
        )}
        {current <= pages - 3 && (
          <div
            className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
            onClick={() => {
              onNextDash();
            }}
          >
            ...
          </div>
        )}
        {pages === 0
          ? null
          : current !== pages && (
              <div
                className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white"
                onClick={() => {
                  onLast();
                }}
              >
                {pages}
              </div>
            )}

        {pages === 0 ? (
          <div className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white">
            <div disabled className="">
              <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
              </svg>
            </div>
          </div>
        ) : current !== pages ? (
          <div  onClick={onNext} className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white">
            <div>
              <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
              </svg>
            </div>
          </div>
        ) : (
          <div className="w-11 h-11 flex items-center justify-center cursor-pointer border border-borderColor1 border-solid rounded-lg hover:bg-white">
            <div disabled>
              <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
