import React, { useEffect } from 'react'
import Header from '../header'
import FooterSection from '../footerSection'
import { useNavigate } from 'react-router'

export default function Wrapper({ children }) {
  const navigate = useNavigate()
  const userData = localStorage.getItem("authToken")

  // useEffect(() =>{
  //   if(userData){
  //     navigate("/dashboard")
  //   }
  // }, [userData])
  
  return (
    <div>
      <Header />
      {children}
      <FooterSection />
    </div>
  )
}
