import RatingIcon from "../../../assets/icons/rating.svg";
import RatingIconFilled from "../../../assets/icons/rating1.svg";
import RatingIconEmpty from "../../../assets/icons/rating2.svg";
import Marquee from "react-fast-marquee";

const UsersSay = () => {
  let userExpericance = [
    {
      message: "Explore tales of transformation from those who have experienced the magic of our extension.",
      usersName: "Samuel B.",
      stars: 5,
    },
    {
      message: "With Alladdin, I was able to save a lot on all my subscriptions, never giving up on the website again.",
      usersName: "Liam C.",
      stars: 5,
    },
    {
      message: "Managing all my subscriptions has never been easier; Aladdin works like a genie for me.",
      usersName: "Williamns K.",
      stars: 4,
    },
    {
      message: "Alladdin has made managing my subscriptions so effortless. Their support team is always ready to help!",
      usersName: "Oliver S.",
      stars: 5,
    },
    {
      message: "Highly recommend Alladdin for their user-friendly platform and exceptional customer support!",
      usersName: "Smith S.",
      stars: 4,
    },
    {
      message: "I love how easy it is to navigate through my TV subscriptions with Alladdin. Highly recommend!",
      usersName: "Jofra A.",
      stars: 5,
    },
    {
      message: "Navigating OTT choices was overwhelming until I found Alladdin—now it's simple and stress-free.",
      usersName: "Henry F.",
      stars: 5,
    },
    {
      message: "With Alladdin, I feel in control of my subscriptions. Their support team is always ready to help!",
      usersName: "Alexander M.",
      stars: 4,
    },
    {
      message: "I rely on Alladdin to keep my OTT subscriptions organized and cost-effective. It's been a lifesaver!",
      usersName: "James A.",
      stars: 4,
    },
  ];

  const renderStars = (stars) => {
    let starsArray = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= stars) {
        starsArray.push(<img key={i} src={RatingIconFilled} alt="RatingIconFilled" />);
      } else {
        starsArray.push(<img key={i} src={RatingIconEmpty} alt="RatingIconEmpty" />);
      }
    }
    return starsArray;
  };

  return (
    <div className="py-100 mobile:py-60">
      <div className="max-w-1080 mx-auto px-5 mobile:px-30">
        <div className="pb-10">
          <h2 className="text-[40px] mobile:text-[26px] mobile:leading-normal leading-normal text-black300 font-semibold text-center mb-4">What our users say about us!</h2>
          <p className="text-xl mobile:text-base  mobile:leading-8 text-gray650 font-normal max-w-[443px] mx-auto text-center">Explore tales of transformation from those who've experienced the magic of our extension.</p>
        </div>
      </div>
      <Marquee pauseOnHover>
      {userExpericance.map((item) => {
        return (
          <div className="bg-white min-w-[350px] mr-5 max-w-[350px] w-[350px] border border-solid border-borderColor rounded-[8px] p-4 card-main">
            <p className="text-lg mobile:text-base mobile:leading-8 leading-normal text-black400 mb-5">{item?.message}</p>
            <div className="flex items-center justify-between">
              <p className="text-xl line-clamp-3 mobile:text-base mobile:leading-8 font-bold text-black400">{item?.usersName}</p>
                  {/* <img src={RatingIcon} alt="RatingIcon" /> */}

                  <div className="flex gap-2">
                {renderStars(item?.stars)}
              </div>
            </div>
          </div>
        );
      })}
      </Marquee>
    </div>
  );
};

export default UsersSay;
