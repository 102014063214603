import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { activityStreamGraph } from "../../../redux/features/subscriptionSlice";
import DatePicker from "react-datepicker";
import moment from "moment";
import FullPageLoader from "../../../components/common/loaders/FullPageLoader";

const ActivityStream = ({selectedName, setSelectedName, isCustomDate, setIsCustomDate}) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("usersData"));
  const { streamPlayGraph, streamPlayGraphLoader } = useSelector((state) => state.subscription);
  const [dropdown, setDropdown] = useState(false);
  
  
  const [monthStartDate, setMonthStartDate] = useState();
  const [monthEndDate, setMonthEndDate] = useState();
  const [selectedMonths, setSelectedMonths] = useState([]);

  useEffect(() => {
    dispatch(activityStreamGraph({ days: 90 }));
  }, []);

  const generateMonths = (startDate, endDate) => {
    const months = [];
    let current = moment(startDate).startOf("month");

    while (current.isBefore(endDate) || current.isSame(moment(endDate).startOf("month"))) {
      months.push(current.format("MM-YYYY"));
      current.add(1, "month");
    }

    return months;
  };

  const getSelectedMonths = () => {
    const endDate = moment();
    let startDate;

    if (selectedName === "3 Months") {
      startDate = moment().subtract(2, "months");
    } else if (selectedName === "6 Months") {
      startDate = moment().subtract(5, "months");
    } else if (isCustomDate.startDate && isCustomDate.endDate) {
      startDate = moment(isCustomDate.startDate);
      endDate = moment(isCustomDate.endDate);
    } else {
      return [];
    }

    return generateMonths(startDate, endDate);
  };

  useEffect(() => {
    if (selectedName !== "Custom Date") {
      setSelectedMonths(getSelectedMonths());
    }
  }, [selectedName, isCustomDate?.endDate]);

  const processedData = streamPlayGraph?.reduce((acc, curr) => {
    const monthYearKey = `${curr?.month?.slice(5)}-${curr?.month?.slice(0, 4)}`;
    if (!acc[monthYearKey]) {
      acc[monthYearKey] = { totalCost: 0 };
    }
    acc[monthYearKey].totalCost += curr?.cost;
    return acc;
  }, {});

  const seriesData = selectedMonths?.map((monthYear) => {
    const key = `${monthYear?.split("-")[0]}-${monthYear?.split("-")[1]}`;
    return processedData[key]?.totalCost || 0;
  });

  const series = [
    {
      name: "Total Cost",
      data: seriesData,
    },
  ];
  
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 0,
        blur: 7,
        opacity: 0.2,
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels to hide the values
    },
    markers: {
      size: 5, // Size of the dot
      shape: "circle", // Shape of the marker
      strokeWidth: 2, // Width of the border of the dot
      hover: {
        size: 7, // Size of the dot on hover
      },
    },
    xaxis: {
      categories: selectedMonths.map((monthYear) => {
        const [month, year] = monthYear?.split("-");
        return (
          moment()
            .month(month - 1)
            .format("MMM") + `-${year}`
        );
      }),
      labels: {
        style: {
          colors: ["#9aa0ac"],
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => `${val?.toFixed(2)}`,
        style: {
          colors: ["#9aa0ac"],
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    tooltip: {
      y: {
        formatter: (val) => `${userData?.currency ?? "$"}${val?.toFixed(2)}`,
      },
    },
  };
  
  const handleChangeMonths = ([newStartDate, newEndDate]) => {
    const adjustedStartDate = moment(newStartDate).startOf("month");
    const adjustedEndDate = moment(newEndDate).endOf("month");
    setMonthStartDate(newStartDate);
    setMonthEndDate(newEndDate);

    if (newStartDate && newEndDate) {
      dispatch(
        activityStreamGraph({
          startDate: adjustedStartDate.format("YYYY-MM-DD"),
          endDate: adjustedEndDate.format("YYYY-MM-DD"),
        })
      );
      setIsCustomDate({...isCustomDate, finalStartDate: adjustedStartDate.format("YYYY-MM-DD"), finalEndDate: adjustedEndDate.format("YYYY-MM-DD")})
      setSelectedMonths(generateMonths(newStartDate, newEndDate));
    }
  };

  const handleChangeFilter = (days, name) => {
    if (name === "custom" && days === 0) {
      setSelectedName("Custom Date");
      setIsCustomDate({ ...isCustomDate, isShowCalendar: true });
    } else {
      setSelectedName(name);
      setIsCustomDate({ isShowCalendar: false, startDate: null, endDate: null });
      dispatch(activityStreamGraph({ days }));
      setMonthStartDate();
      setMonthEndDate();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropdown]);

  return (
    <div className="py-5">
      <h2 className="text-xl font-medium text-black mb-2 mobile:text-base">Activity Stream</h2>
      <div className="bg-white w-full p-3 h-[300px] rounded-2xl mobile:h-auto">
        <div>
          <div className="flex items-center gap-5 justify-end mobile:grid mobile:grid-cols-1 mobile:gap-2">
            {isCustomDate?.isShowCalendar && (
              <div className="w-full">
                <DatePicker
                  selected={monthStartDate}
                  onChange={handleChangeMonths}
                  selectsRange
                  startDate={monthStartDate}
                  endDate={monthEndDate}
                  dateFormat="MMM yyyy"
                  showMonthYearPicker
                  className="custom-datepicker w-full"
                  maxDate={moment().toDate()}
                  fixedHeight
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            )}

            <div className="relative">
              <div onClick={() => setDropdown(!dropdown)} ref={dropdownRef} className="w-[160px] mobile:w-full cursor-pointer bg-white border-2 border-[#e9e9e9] p-2 text-sm flex items-center justify-between font-medium text-black300 rounded">
                {selectedName}
                <i className="fa-solid fa-chevron-down text-black300 cursor-pointer"></i>
              </div>
              <div className={dropdown ? "cus-dropdown-design show z-[100]" : "cus-dropdown-design hide z-[100]"}>
                <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(90, "3 Months")}>
                  3 Months
                </span>
                <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(180, "6 Months")}>
                  6 Months
                </span>
                <span className="block text-sm p-2 font-medium text-black300 cursor-pointer transition ease-in-out hover:bg-bgHover" onClick={() => handleChangeFilter(0, "custom")}>
                  Custom Date
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-0 flex items-center justify-center">
          <Chart className="h-[240px] w-full" options={chartOptions} series={series} type="line" height={240} />
        </div>
      </div>

      {streamPlayGraphLoader && <FullPageLoader />}
    </div>
  );
};

export default ActivityStream;