import React from 'react'
import SubscriptionImage from '../../../assets/images/Subscription-img.webp';
import OversightImage from '../../../assets/images/Oversight.webp';
import BasicTrakingImage from '../../../assets/images/SimplifiedSubscription.webp';
import RecommendedServicesImage from '../../../assets/images/Recommended Services.webp';
import StreamImage from '../../../assets/images/Stream.webp';
import ProjectedSpending from '../../../assets/images/projected-spending.webp';
import CalanderViewImage from '../../../assets/images/CalanderView.webp';
import UnlockmoreImage from '../../../assets/images/Unlockmore.webp';
import CommingSoonImage from '../../../assets/images/comming-soon.webp';
const FeaturesDetails = () => {
    return (
        <>
        <div className='max-w-1160 mx-auto px-5'>
           <div className='py-60 text-center'>
           <h2 className='text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300'>
            Our Features
            </h2>
           </div>
        </div>
         <div className='bg-bglight py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div>
                            <img src={BasicTrakingImage} className='block w-fll' alt='OversightImage' />
                        </div>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Basic Tracking</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Our sleek, user-friendly dashboard centralizes all your recurring payments into one clear view. Effortlessly monitor 
                                due dates, amounts, and subscription details in an organized layout.
                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-80 mobile:py-60 '>
                <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Chrome Extension</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                    The browser Extension streamlines subscription management by integrating directly into
                                     your web experience. Easily add and track new subscriptions from your browser, and get a quick view of your Alladdin dashboard.

                                </p>
                           </div>
                        </div>
                        <div>
                            <img src={SubscriptionImage} className='block w-fll' alt='SubscriptionImage' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-bglight py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div>
                            <img src={UnlockmoreImage} className='block w-fll' alt='OversightImage' />
                        </div>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Price Match</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Maximize your savings with Price Match. Our feature compares subscription rates across providers to 
                                find and apply the best deals available. Ensure you get top value for your money and never overpay for your services.
                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Recommended Subs</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Discover new favorites with Recommended Subscriptions. Our smart recommendation algorithm 
                                suggests personalized subscriptions based on your interests and budget, ensuring you never miss out on services that fit your money.
                                </p>
                           </div>
                        </div>
                        <div>
                            <img src={RecommendedServicesImage} className='block w-fll' alt='RecommendedServicesImage' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-bglight py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div>
                            <img src={StreamImage} className='block w-fll' alt='StreamImage' />
                        </div>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Advanced Reporting</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Advanced Reporting delivers detailed insights into your subscription spending. Analyze your expenses with 
                                comprehensive reports that track spending trends and highlight savings opportunities.

                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Calendar View</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Our dynamic calendar displays all upcoming payments and renewal dates, giving you a clear overview 
                                to streamline your budgeting and avoid surprises.
                                </p>
                           </div>
                        </div>
                        <div>
                            <img src={CalanderViewImage} className='block w-fll' alt='CalanderViewImage' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-bglight py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div>
                            <img src={CommingSoonImage} className='block w-fll' alt='CommingSoonImage' />
                        </div>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Alladdin DB</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Alladdin DB offers an extensive database of subscription services with detailed information. Access 
                                pricing, features, and user reviews to make informed decisions about your next subscription.
                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Projected Spending</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Plan your finances with confidence using Projected Spending. Estimate future subscription costs based on 
                                current data and renewal schedules. This feature helps you budget effectively, set aside funds in advance 
                                and avoid unexpected financial surprises
                                </p>
                           </div>
                        </div>
                        <div>
                            <img src={ProjectedSpending} className='block w-fll' alt='ProjectedSpending' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-bglight py-80 mobile:py-60'>
                <div className='max-w-1160 mx-auto px-5'>
                    <div className='grid grid-cols-[515px_1fr] tab:grid-cols-2 tab:gap-10 mobile:grid-cols-1 gap-12 items-center'>
                        <div>
                            <img src={OversightImage} className='block w-fll' alt='NotificationsImage' />
                        </div>
                        <div className='relative before:absolute left-0 top-0 pl-4 before:content-normal before:w-3 mobile:before:w-3 before:h-full before-back'>
                           <div className='pl-12 mobile:pl-8'>
                                <h2 className='
                                    text-[42px] mobile:text-[26px] mobile:leading-normal tab:text-[40px] tab:leading-[55px] leading-[52px] font-bold text-black300 max-w-[574px]'>
                                    <span className='block '>Alerts & Notifications</span>
                                </h2>
                                <p className='text-lg font-normal text-gray600 mt-5'>
                                Stay informed with timely Alerts. Receive notifications via app and email for upcoming payments, renewals and 
                                important updates. Keep your subscriptions organized and avoid missed payments with proactive reminders that ensure you’re always in control.
                                </p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default FeaturesDetails
