import React from "react";
import styles from "./about.module.css";

const MissionSec = () => {
  const missions = [
    {
      icon: "/assets/Tickicon.svg",
      name: "At Alladdin, we're dedicated to simplifying subscription management and helping customers navigate the online television landscape with ease. Our top priority is customer satisfaction, which drives the development of all our services. Whether you're looking to streamline your subscriptions or explore new television options, we're here to assist you every step of the way.",
    },
    {
      icon: "/assets/Tickicon.svg",
      name: "Our team is committed to providing exceptional support and guidance, ensuring that all your questions are answered and concerns addressed promptly. We understand the importance of clear communication and transparency, which is why we strive to maintain open lines of communication with our customers at all times.",
    },
    {
      icon: "/assets/Tickicon.svg",
      name: "With Alladdin, you can trust that you're in good hands. We're constantly working to enhance our services and improve the customer experience, so you can enjoy seamless subscription management and access to the best television content available online. Let us help you take control of your subscriptions and discover the convenience and simplicity that Alladdin has to offer",
    },
  ];
  return (
    <div className="pageWrapper">
      <div className={styles.missionContainer}>
        <div className={styles.leftPart}>
          <h2 className="header_2" style={{ fontWeight: "600" }}>
            Our Mission
          </h2>
          <p className="desc_texts" style={{ color: "#424242" }}>
            At Alladdin, we aim to ease subscription management for our users
            and help them explore the various aspects of OTT. During our work,
            we keep customer values and satisfaction as a priority and have
            designed all our services with that in mind. Our team is readily
            available in case you have any questions or need any support
          </p>
          {missions.map((mission, id) => (
            <div className={styles.missionContent} key={id.toString()}>
              <img
                width={20}
                height={20}
                src={mission.icon}
                alt={mission.name}
              />
              <p
                className="desc_texts"
                style={{ color: "#030303", fontWeight: "600" }}
              >
                {mission.name}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.rightPart}>
          <img
            src={"/assets/missionimage.svg"}
            className="img"
            alt="img"
            fill
          />
        </div>
      </div>
    </div>
  );
};

export default MissionSec;
