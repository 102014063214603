import AboutBanner from '../../../assets/images/about-banner.webp'

const AboutusBanner = () => {
  return (
    <div className='py-100 bg-primary mobile:py-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <h1 className='text-[64px] leading-[75px] mobile:text-[26px] mobile:leading-normal font-bold text-black300 text-center mb-5'>
        We work Together
        </h1>
        <p className='text-xl font-normal mobile:text-base mobile:leading-8 text-gray600 text-center max-w-[860px] mx-auto mb-60 mobile:mb-8'>
          At Alladdin, we believe in simplifying the way people access and manage their subscriptions. As a monthly subscription service, 
          we offer users unparalleled access to the world's largest network of online subscription-based companies. Our platform serves as 
          a centralized hub where users can effortlessly discover, subscribe to, and manage a wide variety of products and services. 
        </p>
        <div className='max-w-[1078px] mx-auto'>
          <img className='block w-full' src={AboutBanner}alt='AboutBanner'/>
        </div>

      </div>
    </div>
  )
}

export default AboutusBanner
