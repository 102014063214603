import React, { useState } from "react";
import { UserNotificationSetting, UserNotificationSettingUpdate } from "../../../redux/features/subscriptionSlice";
import { useDispatch } from "react-redux";
import { message } from "antd";

export default function SettingModal({ startEditAlert, setStartEditAlert }) {
  const daysDropdown = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
  const [deleteDataId, setDeleteDataId] = useState([])
  const dispatch = useDispatch();

  const [confirmModel, setConfirmModel] = useState();

  const addNewAlert = () => {
    const newAlert = {
      days: 1,
      type: "Renewal Updates",
    };
    setStartEditAlert({
      ...startEditAlert,
      data: [...startEditAlert.data, newAlert],
    });
  };

  const handleDaysChange = (e, idx) => {
    const updatedData = startEditAlert.data.map((item, i) => (i === idx ? { ...item, days: Number(e.target.value) } : item));
    setStartEditAlert({ ...startEditAlert, data: updatedData });
  };

  const handleTypeChange = (e, idx) => {
    const updatedData = startEditAlert.data.map((item, i) => (i === idx ? { ...item, type: e.target.value } : item));
    setStartEditAlert({ ...startEditAlert, data: updatedData });
  };

  const handleDeleteAlert = (idx) => {
    const deletedAlert = startEditAlert.data[idx];
    const updatedData = startEditAlert.data.filter((_, i) => i !== idx);
    setStartEditAlert({ ...startEditAlert, data: updatedData });
    if (deletedAlert.id) {
      setDeleteDataId((prevIds) => [...prevIds, { id: deletedAlert.id }]);
    }
  };

  const handleSubmitAlert = () => {
    dispatch(UserNotificationSettingUpdate({settingArray: startEditAlert?.data, deleteSetting: deleteDataId}))
      .then((res) => {
        dispatch(UserNotificationSetting());
        setStartEditAlert({ isOpenPopup: false, data: [] });
        setConfirmModel(false)
        message.success("Alert settings updated successfully");
      })
      .catch((e) => {
        message.error("Failed to update custom alert");
      });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-10 flex items-center justify-center">
      <div className="bg-white w-[600px] rounded-lg mobile:w-[calc(100%-20px)]">
        <div className="flex items-center p-5 justify-between">
          <p className="text-xl mobile:text-base font-medium text-black300">Alert Manager</p>
          <i onClick={() => setStartEditAlert({ ...startEditAlert, isOpenPopup: false, data: [] })} className="fa-solid fa-xmark cursor-pointer text-xl"></i>
        </div>
        <div className="max-h-[500px] overflow-auto">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 left-0 z-10">
              <tr className="bg-inputBackground text-left">
                <th className="text-sm font-medium text-black300 p-3">Time Period</th>
                <th className="text-sm font-medium text-black300 p-3">Alert For</th>
              </tr>
            </thead>
            <tbody>
              {startEditAlert?.data?.map((editData, idx) => {
                return (
                  <tr className="border-b border-solid border-borderColor5" key={idx}>
                    <td className="p-3 ">
                      <div className="relative w-[230px]">
                        <select className="bg-inputBackground px-3 w-full text-sm font-medium text-black300 h-8" value={editData?.days} onChange={(e) => handleDaysChange(e, idx)}>
                          {daysDropdown?.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <span className="block absolute top-[50%] translate-y-[-50%] right-6 text-sm font-medium">Days</span>
                      </div>
                    </td>
                    <td className="p-3">
                      <div className="flex items-center gap-5">
                        <div className="w-[240px]">
                          <select className="bg-inputBackground px-3 w-full text-sm font-medium text-black300 h-8" value={editData?.type} onChange={(e) => handleTypeChange(e, idx)}>
                            <option value="Renewal Updates">Renewal Updates</option>
                            <option value="Expiry Updates">Expiry Updates</option>
                          </select>
                        </div>
                        {startEditAlert.data?.length > 2 && <i onClick={() => handleDeleteAlert(idx)} className="fa-solid fa-trash text-red-700 cursor-pointer"></i>}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="px-5 py-3 flex items-center justify-between gap-5">
          {/* New Alert Button */}
          <button disabled={startEditAlert?.data?.length === 20} onClick={addNewAlert} className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md bg-inputBackground">
            New Alert
          </button>
          <button className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg" onClick={() => setConfirmModel(true)}>
            Save
          </button>
        </div>
      </div>

      {confirmModel && (
        <div className="global-modal" style={{ zIndex: "999" }}>
          <div className="bg-white rounded-xl w-[510px] p-5  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
            {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
            <div className="flex justify-between items-center">
              <p className="text-lg text-black300 font-medium text-center mb-5 max-w-[90%] mx-auto">Are you sure you want to change this alert setting?</p>
            </div>

            <div className="flex justify-end gap-3 pt-3">
              <button
                className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                onClick={() => {
                  setConfirmModel(false);
                }}
              >
                Cancel
              </button>
              <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleSubmitAlert()}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
