import React, { useEffect } from 'react'
import HeroBanner from './herobanner'
import JoinMember from './joinMember'
import SimplifiedSubscription from './simplifiedSubscription'
import StayonTop from './StayonTop'
import UnexpectedCharges from './unexpectedCharges'
import SubscriptionLibrary from './subscriptionLibrary'
import OttReminders from './ottReminders'
import AlladdinHelp from './alladdinHelp'
import UsersSay from './usersSay'

const HomePage = () => {
  useEffect(() =>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <>
      <HeroBanner />
      <JoinMember />
      <OttReminders />
      <StayonTop />
      <SimplifiedSubscription />
      <SubscriptionLibrary />
      <UnexpectedCharges />
      <AlladdinHelp />
      <UsersSay />
    </>
  )
}

export default HomePage
