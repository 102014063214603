import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const AboutInformation = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <div className="bg-bglight py-60 mobile:px-30">
      <div className="max-w-1160 mx-auto px-5">
        <div className="grid grid-cols-3 gap-120 mobile:grid-cols-1 mobile:gap-10">
          <div ref={ref}>
            <p className="text-[40px] tab:text-[26px] tab:leading-normal font-bold text-black300 text-center mb-3">$ {inView ? <CountUp start={0} end={3.7} duration={3} decimals={1}/> : "0"} million+</p>
            <span className="block text-base font-normal leading-normal text-black500 text-center">Saved for our users</span>
          </div>
          <div>
            <p className="text-[40px] tab:text-[26px] tab:leading-normal font-bold text-black300 text-center mb-3">
            {inView ? <CountUp start={0} end={5} duration={3} /> : "0"} million+
            </p>
            <span className="block text-base font-normal leading-normal text-black500 text-center">Trusted users</span>
          </div>
          <div>
            <p className="text-[40px] tab:text-[26px] tab:leading-normal font-bold text-black300 text-center mb-3">
            {inView ? <CountUp start={0} end={5} duration={2} /> : "0"} years+
            </p>
            <span className="block text-base font-normal leading-normal text-black500 text-center">experience</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInformation;
