import React from "react";

const HalfStarIcon = () => {
  return (
    <svg classname="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" version="1.1" transform="matrix(-1,0,0,1,0,0)">
    <path d="M12 2L14.81 8.63L22 9.24L16.54 13.97L18.18 21L12 17.27L12 2Z" fill="#ffffff"></path>
    <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" stroke="#ffffff" stroke-width="1" fill="none"></path>
  </svg>
  );
};

export default HalfStarIcon;
