import React, { useEffect } from 'react'
import AboutusBanner from './aboutusBanner'
import AboutInformation from './aboutInformation'
import WhoAreWe from './WhoAreWe'
import OurValues from './ourValues'
import OurMission from './ourMission'
import CultureSection from './CultureSection'

const AboutusPage = () => {
  useEffect(() =>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <div>
      <AboutusBanner/>
      <AboutInformation/>
      <WhoAreWe/>
      <OurValues/>
      <OurMission/>
      <CultureSection/>
    </div>
  )
}

export default AboutusPage
