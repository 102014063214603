import StayImage from '../../../assets/images/stay.webp';
const StayonTop = () => {
  return (
    <div className='bg-bglight pt-110 pb-12 mobile:py-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <div className='grid grid-cols-[530px_1fr] gap-100 tab:grid-cols-1 tab:gap-60'>
            <div>
                <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal tracking-normal text-black300 font-semibold mb-6'>
                Stay on top of your "Free trials"
                </h2>
                <p className='text-lg font-normal mobile:text-base mobile:leading-8  leading-normal mb-6 text-gray650'>
                    Explore your spending with our detailed analytics. Track monthly expenses, view yearly trends, and uncover spending patterns. Pinpoint areas of excess and find opportunities to save. With our platform, you gain a clear financial picture, enabling smarter decisions. Strategize, optimize, and ensure every subscription 
                    truly benefits you.
                </p>
                <button onClick={() => window.open("https://alladdin-web.netlify.app/login", "_blank")} className='bg-transparent hover:bg-black300 hover:text-white transition ease-in-out border border-solid border-black300 text-sm font-normal tracking-normal py-3 px-4 text-black300 rounded-lg'>
                Learn More
                </button>
            </div>
            <div>
                <img className='block w-full' src={StayImage} alt='StayImage'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default StayonTop
