import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import contactStyles from "../../contactus/contact.module.css";
import substyles from "../../subscription/sub.module.css";
import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import Appconstants from "../../../constants/AppConstants";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfileData, UpdateUsersProfileData } from "../../../redux/features/subscriptionSlice";
import FullPageLoader from "../../common/loaders/FullPageLoader";

function Profile() {
  const { userData, isUserSubscribed, getUser, setUsers } = useAuth();
  const { usersProfileData } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [buttontext, setbuttontext] = useState(isUserSubscribed);
  const navigate = useNavigate();
  const [profileState, SetProfileState] = useState({
    fullName: userData?.name ? userData?.name : "",
    company: "",
    email: userData?.email ? userData?.email : "",
    phone: "",
    password: "",
  });
  const [startEdit, setStartEdit] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [uploadedImage, setUploaderImage] = useState();
  const [nameData, setNameData] = useState(usersProfileData.name);
  const [startChanging, setStartChanging] = useState(false);
  const handleSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (userData && isUserSubscribed) {
      // navigate("/dashboard/manage-subscription");
      try {
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(`${Appconstants.baseURL}/subscription/cancelsubscription`, {
          method: "POST",
        })
          .then((res) => {
            console.log(res, "res in cancel");
            if (res.ok) {
              message.success(res?.data?.message ? res?.data?.message : "Subscription Canceled");
              window.location.reload();
            } else {
              message.error(res?.data?.message ? res?.data?.message : "Something Went Wrong");
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log(e.error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error, "error");
        setLoading(false);
      }
    } else {
      try {
        let price = 4.99;
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        await fetch(`${Appconstants.baseURL}/subscription/create-checkout-session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({ price: price }),
        })
          .then((res) => {
            if (res.ok) return res.json();
            console.log(res);
            return res.json().then((json) => Promise.reject(json));
          })
          .then(({ session }) => {
            setLoading(false);
            window.location = session.url;
          })
          .catch((e) => {
            console.log(e.error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const handleChangeName = (e) => {
    setStartChanging(true);
    setNameData(e.target.value);
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validImageTypes = ["image/jpeg", "image/png", "image/webp"];
    if (validImageTypes.includes(fileType)) {
      const size = (file.size / 1024 / 1024).toFixed(2);

      if (size > 50) {
        message.error("File size should be less than 50MB");
        setUploaderImage('')
        return;
      }

      setUploaderImage(file);
    } else {
      setUploaderImage(null);
      message.error("Please upload a JPG or PNG image.");
    }
  };

  const handleSaveEditProfile = () => {
    if (nameData.trim() === "") {
      message.error("Please enter full name");
      return;
    }
    setStartEdit(false);
    setLoading(true);
    setLoading(true);
    const formData = new FormData();
    formData.append("name", nameData);
    dispatch(UpdateUsersProfileData({ id: usersProfileData?.uid, body: formData }))
      .then((res) => {
        setLoading(false);
        setUsers(res?.payload);
        dispatch(GetUserProfileData());
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to update profile");
      });
  };

  const handleDeleteImage = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("profile", null);
    dispatch(UpdateUsersProfileData({ id: usersProfileData?.uid, body: formData }))
      .then((res) => {
        setLoading(false);
        setUploaderImage(null);
        setShowUploadImage(false);
        dispatch(GetUserProfileData());
        setUsers(res?.payload);
        setShowDeleteModel(false);
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to update profile");
      });
  };

  const handleSubmitUploadImage = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", uploadedImage);
    dispatch(UpdateUsersProfileData({ id: usersProfileData?.uid, body: formData }))
      .then((res) => {
        setLoading(false);
        setUploaderImage(null);
        setShowUploadImage(false);
        dispatch(GetUserProfileData());
        setUsers(res?.payload);
      })
      .catch((e) => {
        setLoading(false);
        message.error("Failed to update profile");
      });
  };

  useEffect(() => {
    setNameData(usersProfileData.name);
  }, [usersProfileData]);

  useEffect(() => {
    dispatch(GetUserProfileData());
  }, []);

  return (
    <div>
      <div className=" pb-5 border-b border-solid border-borderColor1 ">
        <h2 className="text-xl text-black300 mobile:text-base font-medium m-0">Basic Details</h2>
      </div>
      <div className="bg-white p-8 rounded-xl mt-5 mobile:p-4">
        {showUploadImage ? (
          <>
            {uploadedImage ? (
              <div className="w-24 relative">
                <div className=" w-24 h-24 rounded-full overflow-hidden">
                  <img className="rounded-full w-24 h-24" src={URL.createObjectURL(uploadedImage)} alt="ProfileImage" width={96} height={96} />
                  <div onClick={() => setUploaderImage(null)} className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer left-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
                    <i class="fa-solid fa-trash text-[10px]"></i>
                  </div>
                  <div onClick={() => handleSubmitUploadImage()} className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer right-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
                    <i class="fa-solid fa-check text-[12px]"></i>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-24 relative">
                <div className="w-24 text-4xl text-black300 font-bold h-24 rounded-full primary-gradient-bg mb-4 flex items-center justify-center">
                  <div className="w-24 text-4xl text-black300 font-bold h-24 rounded-full primary-gradient-bg flex items-center justify-center overflow-hidden relative">
                    <input className="w-full text-4xl text-black300 font-bold h-24 rounded-full opacity-0 cursor-pointer absolute z-10" type="file" onChange={(e) => handleUploadImage(e)} />
                    <i class="fa-solid fa-upload absolute z-0"></i>
                  </div>
                  <div onClick={() => setShowUploadImage(false)} className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer right-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
                    <i class="fa-solid fa-xmark text-[12px]"></i>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="w-24 relative">
            <div className="w-24 text-4xl text-black300 font-bold h-24 rounded-full primary-gradient-bg mb-4 flex items-center justify-center">
              {usersProfileData?.profile ? (
                <>
                  <div className=" w-24 h-24 rounded-full overflow-hidden">
                    <img className="rounded-full w-24 h-24" src={usersProfileData?.profile} width={96} height={96} alt="ProfileImage" />
                  </div>
                  <div onClick={() => setShowDeleteModel(true)} className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer left-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
                    <i class="fa-solid fa-trash text-[10px]"></i>
                  </div>
                  <div
                    // onClick={() => handleUpdateProfileImage()}
                    onClick={() => setShowUploadImage(true)}
                    className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer right-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10"
                  >
                    <i class="fa-solid fa-pen-to-square text-[10px]"></i>
                  </div>
                </>
              ) : (
                <>
                  {usersProfileData?.name
                    ?.split(" ")
                    ?.map((word) => word.charAt(0).toUpperCase())
                    .join("")}
                  <div onClick={() => setShowUploadImage(true)} className="w-6 h-6 bg-white rounded-full shadow-lg absolute cursor-pointer right-0 bottom-2 flex items-center justify-center border border-solid border-borderColor1 z-10">
                    {/* <i class="fa-solid fa-trash text-[10px]"></i> */}
                    <i class="fa-solid fa-pen-to-square text-[10px]"></i>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <form className="max-w-[700px] pt-6 mobile:max-w-full">
          <div className="grid grid-cols-2 gap-5 mobile:grid-cols-1 mobile:gap-2">
            <div className="pb-4">
              <label htmlFor="Full_name" className="text-sm text-gray700 font-medium block pb-2">
                Full Name
              </label>
              <div className="relative">
                <input
                  name="fullName"
                  type="text"
                  value={nameData}
                  id="Full_name"
                  className="w-full border border-solid border-borderColor4 rounded pr-12 h-9 placeholder:text-sm text-sm text-black300 bg-white"
                  placeholder="Name"
                  contentEditable={false}
                  disabled={!startEdit}
                  onChange={(e) => handleChangeName(e)}
                />
                {!startEdit && <img onClick={() => setStartEdit(true)} className="mb-5 cursor-pointer absolute top-[15px] right-[15px]" src={"/assets/pencilicon.svg"} width={12} height={12} />}
                {startEdit && (
                  <div onClick={() => handleSaveEditProfile()}>
                    {" "}
                    {/* <i class="fa-solid fa-check text-[15px] mb-5 cursor-pointer absolute top-[12px] right-[15px]"></i> */}
                    <button className="text-[#c2d73b] text-sm cursor-pointer absolute top-[50%] translate-y-[-50%] bottom-0 right-[15px]">Save</button>
                  </div>
                )}
              </div>
            </div>
            <div className="pb-4">
              <label htmlFor="email" className="text-sm text-gray700 font-medium block pb-2">
                Email
              </label>
              <input
                name="email"
                type="email"
                value={profileState.email}
                id="email"
                className="w-full border border-solid border-borderColor4 rounded h-9 placeholder:text-sm text-sm text-black300 bg-white"
                placeholder="Email"
                contentEditable={false}
                readOnly={true}
              />
            </div>
          </div>
          {/* <div className="flex justify-end items-center">
            <button
              className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg"
              type="submit"
              disabled={loading}
              onClick={handleSubscription}
            >
              {buttontext ? "Cancel Subscription" : "Get Premium"}
            </button>
          </div> */}
        </form>
      </div>
      <div className="bg-white p-5 rounded-xl mt-5 flex items-center justify-between mobile:grid mobile:grid-cols-1 mobile:gap-3">
        <p className="text-xl font-bold text-black300">Manage your subscription billing and plans</p>
        <a href="https://billing.stripe.com/p/login/eVa6q3fXI6AIbYscMM" target="_blank">
          <button className="border-none cursor-pointer text-sm font-medium text-white py-3 px-6 rounded-md bg-[#AE3628]">Manage Account</button>
        </a>
      </div>

      {showDeleteModel && (
        <div className="global-modal" style={{ zIndex: "999999" }}>
          <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:overflow-auto">
            {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
            <div className="flex justify-between items-center">
              <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure you want to delete profile photo?</p>
            </div>

            <div className="flex justify-end gap-3 pt-3">
              <button
                className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
                onClick={() => {
                  setShowDeleteModel(false);
                }}
              >
                Cancel
              </button>
              <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-[#fb3939] text-white" onClick={() => handleDeleteImage()}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <FullPageLoader />}
    </div>
  );
}

export default Profile;
