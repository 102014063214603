import SubscriptionImage from '../../../assets/images/Subscription.webp';

const SubscriptionLibrary = () => {
  return (
    <div className='pt-[95px] pb-11 bg-bglight mobile:py-60'>
         <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
            <div className='grid grid-cols-[1fr_518px] gap-11 items-center tab:grid-cols-1 tab:gap-60'>
                <div>
                <h2 className='text-[40px] tracking-normal mobile:mt-0 mobile:text-[26px] mobile:leading-normal text-black300 font-semibold mb-5 mt-5'>
                Subscription Library: Your Comprehensive Guide
                </h2>
                <p className='text-lg font-normal  leading-normal mb-6 text-gray650 mobile:text-base mobile:leading-8'>
                Explore our Subscription Library, a comprehensive resource that brings together a wide range of online services, from education to entertainment and more. With a clear and organized structure, it's easier than ever to get an overview of what each service offers. Whether you're looking for something new or researching a known service, our library provides a streamlined and professional experience to assist your 
                decision-making
                </p>
                <button onClick={() => window.open("https://alladdin-web.netlify.app/login", "_blank")} className='bg-transparent hover:bg-black300 hover:text-white transition ease-in-out border border-solid border-black300 text-sm font-normal tracking-normal py-3 px-4 text-black300 rounded-lg'>
                Learn More
                </button>
                </div>
                <div>
                    <img src={SubscriptionImage} alt='SubscriptionImage' className='w-full block' />
                </div>
            </div>
         </div>
    </div>
  )
}

export default SubscriptionLibrary
