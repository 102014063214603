import TimerIcon from '../../../assets/icons/timer.svg';
const AlladdinHelp = () => {
  return (
    <div className='py-70 bg-bglight mobile:py-60'>
      <div className='max-w-880 mx-auto px-5 mobile:px-30'>
        <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal leading-normal text-black300 font-semibold text-center mb-60'>
        Consolidate the chaos.
        </h2>
        <div className='grid grid-cols-2 gap-x-[120px] gap-y-60 tab:gap-60 mobile:gap-8 mobile:grid-cols-1'>
            <div>
                <div className='flex items-center justify-center pb-6'>
                    <img src={'/assets/saveMoney.svg'}alt='TimerIcon'/>
                </div>
                <h3 className='text-center text-[26px] mobile:text-xl font-semibold text-black300 leading-normal mb-3'>
                A Subscription that Saves You Money!
                </h3>
                <p className='text-lg mobile:tet-base mobile:leading-8 text-black400 font-normal leading-normal text-center max-w-[266px] mx-auto'>
                Access your complete credit report and history. Get alerted to important changes that impact your score.
                </p>
            </div>
            <div>
                <div className='flex items-center justify-center pb-6'>
                    <img src={'/assets/controlCenter.svg'}alt='TimerIcon'/>
                </div>
                <h3 className='text-center text-[26px] mobile:text-xl font-semibold text-black300 leading-normal mb-3'>
                Your personliased control center
                </h3>
                <p className='text-lg mobile:tet-base mobile:leading-8 text-black400 font-normal leading-normal text-center max-w-[290px] mx-auto'>
                Our concierge will identify bills that can be lowered and negotiate on your behalf for the best rates available.
                </p>
            </div>
            <div>
                <div className='flex items-center justify-center pb-6'>
                    <img src={'/assets/keepGoal.svg'}alt='TimerIcon'/>
                </div>
                <h3 className='text-center text-[26px] mobile:text-xl font-semibold text-black300 leading-normal mb-3'>
                Get alerts to keep you on goal
                </h3>
                {/* <p className='text-lg mobile:tet-base mobile:leading-8 text-black400 font-normal leading-normal text-center max-w-[290px] mx-auto'>
                Track and manage upcoming payment dates for all your subscriptions. Set reminders and avoid unexpected charges.
                </p> */}
            </div>
            <div>
                <div className='flex items-center justify-center pb-6'>
                    <img src={'/assets/imdbSubscription.svg'}alt='TimerIcon'/>
                </div>
                <h3 className='text-center text-[26px] mobile:text-xl font-semibold text-black300 leading-normal mb-3'>
                The IMDB for subscriptions
                </h3>
                <p className='text-lg mobile:tet-base mobile:leading-8 text-black400 font-normal leading-normal text-center max-w-[290px] mx-auto'>
                Our concierge will identify bills that can be lowered and negotiate on your behalf for the best rates available.
                </p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AlladdinHelp
