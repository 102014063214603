import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

function SubscriptionStatusModel({ activeModel, setActiveModel, handleChangeSubscriptionStatus }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState()

  const handleSubmitData = () =>{
    if(validateData()){
      handleChangeSubscriptionStatus()
    }
  }

  const validateData = () =>{
      let isValid = true;
      let err = {};
  
      if (!activeModel?.start_date) {
        err.start_date = "Please select start date";
        isValid = false;
      }
  
      if (!activeModel?.end_date) {
        err.end_date = "Please select start date";
        isValid = false;
      }
  
      setError(err);
      return isValid;
  }

  const handleDateChange = (newValue, name) => {
    if (name === "start_date") {
      setActiveModel({
        ...activeModel,
        start_date: moment(newValue).format("YYYY-MM-DD"),
      });
      setStartDate(newValue);
      setError({ ...error, start_date: "" });
    } else {
      setActiveModel({
        ...activeModel,
        end_date: moment(newValue).format("YYYY-MM-DD"),
      });
      setEndDate(newValue);
      setError({ ...error, end_date: "" });
    }
  };

  return (
    <div className="global-modal" style={{ zIndex: "999" }}>
      {activeModel?.isActive === true ? (
        <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
          {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
          <div className="flex justify-between items-center">
            <p className="text-[17px] text-black300 font-medium text-center mb-5">Please fill in the details to reactivate to start tracking again.</p>
          </div>

          <div className="grid grid-cols-2 gap-5 pb-5 mobile:pb-3 mobile:grid-cols-1 mobile:gap-3">
            <div>
              <label className="block text-sm text-black333 pb-1 font-medium">
                Start Date <span className="text-red-600"> *</span>
              </label>
              <div className="DatePicker-main">
                <DatePicker
                  // disabled={!isStartEdit && setIsEditSubscription}
                  dateFormat="DD MM YYYY"
                  className={error?.start_date ? "custom-datepicker" : ""}
                  placeholderText="Select a date"
                  value={activeModel?.start_date ? moment(activeModel?.start_date).format("MM-DD-YYYY") : ""}
                  maxDate={new Date()}
                  fixedHeight
                  selected={startDate}
                  onChange={(date) => handleDateChange(date, "start_date")}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm text-black333 pb-1 font-medium">
                Next Payment Date <span className="text-red-600"> *</span>
              </label>
              <div className="DatePicker-main">
                <DatePicker
                  // disabled={setIsEditSubscription}
                  // disabled={setIsEditSubscription && activeModel?.end_date}
                  dateFormat="DD MM YYYY"
                  className={error?.end_date ? "custom-datepicker" : ""}
                  placeholderText="Select a date"
                  value={activeModel?.end_date ? moment(activeModel.end_date).format("MM-DD-YYYY") : ""}
                  minDate={new Date()}
                  fixedHeight
                  selected={endDate}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 pt-3">
            <button
              className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
              onClick={() => {
                setActiveModel({ isShow: false, id: null, isActive: false });
              }}
            >
              Cancel
            </button>
            <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleSubmitData()}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
          {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
          <div className="flex justify-between items-center">
            <p className="text-lg text-black300 font-medium text-center mb-5">Are you sure you want to deactivate this subscription?</p>
          </div>

          <div className="flex justify-end gap-3 pt-3">
            <button
              className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
              onClick={() => {
                setActiveModel({ isShow: false, id: null, isActive: false });
              }}
            >
              Cancel
            </button>
            <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleChangeSubscriptionStatus()}>
              Yes
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionStatusModel;
