import React, { useState } from "react";
import HuluIcon from "../../assets/icons/hulu.svg";
import TimeIcon from "../../assets/icons/time-icon.svg";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import DeleteSubscriptionModel from "../deleteSubscriptionModel";
import { deleteAladinDBSubscription, GetALLSubscriptions, GetBrowseAllData } from "../../redux/features/subscriptionSlice";
import FullPageLoader from "../common/loaders/FullPageLoader";
import { message } from "antd";

const AddNewSubsCard = ({ item, index, handleOpenAddModel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(null);
  const { borwseAlladdinLoader, deleteDBSubscriptionLoader } = useSelector((state) => state.subscription);
  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null
  });

  const handleDeleteSubscription = () => {
    let data = { id: deleteModel?.id, body: null };
    dispatch(deleteAladinDBSubscription(data))
      .then((res) => {
        message.success(res?.payload || "Subscription Deleted Successfully");
        dispatch(GetALLSubscriptions());
          dispatch(GetBrowseAllData());
        setDeleteModel({
          id: null,
          isShow: false,
        });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    // <div className=" bg-white rounded-2xl" >
    //   <div className="py-6 flex items-center justify-center" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
    //     <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="HuluIcon" />
    //   </div>
    //   <div className="px-4 pb-6" onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
    //     <p className="text-lg text-black font-bold">
    //       ${item?.cost?.toFixed(2)} <span className="text-sm font-normal">/Mo</span>
    //     </p>

    //     <span className="text-sm text-[#777] font-normal add-new-card-description min-h-[60px]">{item?.sub_description}</span>

    //     {/* <p className='text-[12px] font-bold text-black300 tracking-normal mb-1'>
    //         Pay date: <span className='font-normal'>6th July 2023</span>
    //     </p>
    //     <div className='p-1 rounded bg-lightgray19 flex items-center justify-between'>
    //         <span className='block text-xs font-normal text-black'>
    //             5 Days remaining
    //         </span>
    //         <img src={TimeIcon} alt='TimeIcon' className='block' />
    //     </div> */}
    //   </div>
    //   <div
    //     className="primary-gradient-bg p-2.5 flex items-center justify-center rounded-b-[16px]"
    //     // onClick={() => navigate(`/dashboard/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}
    //     onClick={() => handleOpenAddModel(item)}
    //   >
    //     <p className="text-sm font-normal text-black300 text-center cursor-pointer">Add {item?.sub_name}</p>
    //   </div>
    // </div>

    borwseAlladdinLoader ? (
      <>
        <div className="bg-white min-w-[240px] max-w-[240px] w-[240px] mobile:w-full mobile:min-w-full mobile:max-w-full rounded-2xl flex flex-col justify-between">
          <div>
            <div className="pt-3 py-3 flex items-center justify-center">
              <Skeleton
                circle
                width={60}
                height={60}
                className="mobile:w-[50px] mobile:h-[50px]" // Adjusting for mobile
              />
            </div>
            <div className="px-4 pb-3">
              <div className="w-full flex justify-center">
                <Skeleton
                  height={20}
                  width={120}
                  className="mobile:w-[100px] mobile:h-[18px]" // Mobile adjustments
                />
              </div>
              <div className="flex justify-center pb-2">
                <Skeleton width={50} height={20} className="mr-1 mobile:w-[40px] mobile:h-[18px]" />
                <Skeleton width={50} height={20} className="mr-1 mobile:w-[40px] mobile:h-[18px]" />
                <Skeleton width={50} height={20} className="mobile:w-[40px] mobile:h-[18px]" />
              </div>
              <Skeleton
                height={80}
                width={200}
                className="mobile:w-[180px] mobile:max-w-[150px] mobile:h-[60px]" // Responsive width/height
              />
            </div>
          </div>
          <div className="bg-lightgray19 p-2 mobile:p-2 flex items-center justify-center rounded-b-[16px] cursor-pointer">
            <Skeleton
              height={20}
              width={100}
              className="mobile:w-[80px] mobile:h-[18px]" // Smaller size for mobile
            />
          </div>
        </div>
      </>
    ) : (
      <div className=" bg-white min-w-[240px] max-w-[240px] w-[240px] relative group mobile:w-full mobile:min-w-full mobile:max-w-[100%] rounded-2xl flex flex-col justify-between">
          {item?.createdBy === "user" && <div className="absolute right-4 top-3 opacity-0 group-hover:opacity-[1] transition ease-in-out">
              <i class="fa-solid fa-trash text-red-500 cursor-pointer" onClick={() => setDeleteModel({ isShow: true, id: item?.sub_id })}></i>
            </div>}
        <div onMouseEnter={() => setCurrentIndex(index)} onMouseLeave={() => setCurrentIndex(null)}>
          <div className="pt-3 py-3 flex items-center justify-center" onClick={() => navigate(`/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
            <img className="w-[60px] h-[60px] block rounded-2xl" src={item?.sub_logo} alt="sub_logo" />
          </div>
          <div className="px-4 pb-3" onClick={() => navigate(`/details/${item?.sub_name?.split(" ").join("")}/${item?.sub_id}`)}>
            <p className="text-center text-lg text-black200 font-medium mobile:text-base truncate ">{item?.sub_name}</p>
            {/* <span className="text-xs font-normal text-gray600 block text-center pb-2">Education</span> */}
            <div className="flex justify-center pb-2" >
              {item?.categories?.length === 1 && (
                <div className=" px-1">
                  <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[0]}</p>
                </div>
              )}

              {item?.categories?.length === 2 && (
                <>
                  {/* <div className="border-solid pr-1 border-r border-borderColor1">
                    <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div> */}

                  <div className="border-solid pr-1 border-r border-borderColor1">
                    <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div>
                  {index === currentIndex && <ReactTooltip id="tooltip-1" place="top" style={{ zIndex: "9" }} content={item?.categories?.[0]} />}
                  <div className=" px-1 ">
                    <p data-tooltip-id="tooltip-1" className="text-[11px] text-center leading-3 font-medium text-black">
                      1 more
                    </p>
                  </div>
                </>
              )}

              {item?.categories?.length === 3 && (
                <>
                  {" "}
                  {/* <div className="border-solid pr-1 border-r border-borderColor1">
                    <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div> */}
                  <div className="border-solid px-1 border-r border-borderColor1">
                    <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[1]}</p>
                  </div>
                  {index === currentIndex && (
                    <ReactTooltip
                      id="tooltip-2"
                      place="top"
                      style={{ zIndex: "9" }}
                      content={
                        <>
                          {item?.categories?.[0]} <br /> {item?.categories?.[2]}
                        </>
                      }
                    />
                  )}
                  <div className=" px-1 ">
                    <p data-tooltip-id="tooltip-2" className="text-[11px] text-center leading-3 font-medium text-black">
                      2 more
                    </p>
                  </div>
                  {/* <div className="pl-1">
                    <p className="text-[11px] text-center leading-3 font-medium text-black">{item?.categories?.[2]}</p>
                  </div> */}
                </>
              )}
            </div>
            <p className="text-sm font-normal text-center text-black text-ellipsis mobile:text-xs">{item?.sub_description}</p>
          </div>
        </div>
        <div className="primary-gradient-bg p-2 mobile:p-1.5 mobile:text-xs flex items-center justify-center rounded-b-[16px] cursor-pointer" onClick={() => handleOpenAddModel(item)}>
          <p className="text-sm font-normal text-black300 mobile:text-xs text-center cursor-pointer truncate">Add {item?.sub_name}</p>
        </div>
        {deleteModel?.isShow && <DeleteSubscriptionModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDeleteSubscription={handleDeleteSubscription} />}
        {deleteDBSubscriptionLoader && <FullPageLoader />}
      </div>
    )
  );
};

export default AddNewSubsCard;
