import InstagramIcon from "../../svg/InstagramIcon";
import LinkedinIcon from "../../svg/LinkedinIcon";
import FacebookIcon from "../../svg/FacebookIcon";
import TwitterIcon from "../../svg/TwitterIcon";
import YoutubeIcon from "../../svg/YoutubeIcon";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo/logo.svg";

const FooterSection = () => {
  const navigate = useNavigate()
  return (
    <div className="py-60 bg-primary tab:py-60">
      <div className="max-w-1340 mx-auto px-5 mobile:px-30">
        <div className="flex justify-between tab:grid tab:grid-cols-1 tab:gap-60 ">
          <div>
            {/* <h2 className="text-[40px] mobile:text-[26px]  mobile:leading-normal text-black300 font-semibold leading-[48px] mb-4 mobile:mb-0">Get Started</h2> */}
            <img className="mb-[7px]" src={Logo} alt="Alladdin Logo" onClick={() => navigate("/")} />
            <p className="text-xl mobile:text-base mobile:leading-8 text-gray650 font-normal">Simplify Your <b>Subscriptions</b>, Amplify Your Savings</p>
          </div>
          <div className="">
            <div>
              <p className="text-xl font-bold text-black400 mb-[13px]">Quick Links</p>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/"}>Home</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/about-us"}>About Us</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/features"}>Features</NavLink>
              </a>
              <a className="block cursor-pointer text-base font-normal text-black400 pb-2">
                <NavLink to={"/contact-us"}>Contact Us</NavLink>
              </a>
            </div>
          </div>
          {/* <div>
            <p className="text-xl font-bold text-black400 mb-[13px]">Features</p>
            <a className="block cursor-default text-base font-normal text-black400 pb-2">Apps Management</a>
            <a className="block cursor-default text-base font-normal text-black400 pb-2">Timely reminders</a>
            <a className="block cursor-default text-base font-normal text-black400 pb-2">Optimized Calender</a>
            <a className="block cursor-default text-base font-normal text-black400 pb-2">Dashboard</a>
            <a className="block cursor-default text-base font-normal text-black400 pb-2">Customer support</a>
          </div> */}
          <div>
            {/* <p className="text-[22px] text-black400 font-normal leading-normal max-w-[310px] mb-[9px]">325 Hillside Ave, Camden, New Jersey(NJ), 08105</p> */}
            <button className="bg-black p-2 text-white rounded-md" onClick={() => navigate("/contact-us")}>Contact us</button>
            <span className="flex gap-1 pt-3">
            <i class="fa-solid fa-envelope flex justify-center items-center"></i>
              <a href="mailto:info@alladdinco.com" className="text-base text-black400 block leading-normal max-w-[310px]">
                info@alladdinco.com{" "}
              </a>
            </span>
            {/* <p className="text-base text-black400 leading-normal max-w-[310px]">(856) 635-1130 </p> */}
            <div className="flex items-center gap-5 pt-3">
              <a href="https://www.instagram.com/alladdinpay?igsh=MXJtNm1nbjJzN2tvMA%3D%3D&utm_source=qr" target="_blank">
                <InstagramIcon />
              </a>
              {/* <a>
              <LinkedinIcon />
              </a>
              <a>
              <FacebookIcon />
              </a> */}
              <a href="https://twitter.com/alladdinpay?s=21&t=AevryMqjWlvNMQurAZbWjQ" target="_blank">
                <TwitterIcon />
              </a>
              {/* <a>
              <YoutubeIcon />
              </a> */}
            </div>
              {/* <button className="bg-black p-2 text-white rounded-md mt-3" onClick={() => navigate("/contact-us")}>Contact us</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
