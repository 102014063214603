import React from 'react'
import WhoWeAreImage from '../../../assets/images/who-we-are.webp';
const WhoAreWe = () => {
  return (
    <div className='py-100 mobile:py-60'>
        <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
            <div className='grid grid-cols-2 gap-100 tab:gap-10 mobile:gap-10 mobile:grid-cols-1'>
                <div>
                    <img src={WhoWeAreImage} alt='WhoWeAreImage' className='block w-full rounded-xl h-[520px]' />
                </div>
                <div>
                    <h2 className='mb-8 text-[40px] mobile:text-[26px] mobile:leading-normal font-bold text-black300 leading-normal'>
                    Who are we?
                    </h2>
                    <p className='text-lg mobile:text-base mobile:leading-normal text-gray600 font-normal mb-6'>
                    We are a team dedicated to making your subscription management a breeze. At Alladdin, we strive to simplify the process of 
                    handling all your subscriptions, ensuring they are organized and hassle-free. With our platform, you can say goodbye to the 
                    stress of managing multiple subscriptions and enjoy peace of mind knowing that everything is taken care of efficiently. 
                    </p>
                    <p className='text-lg mobile:text-base mobile:leading-normal text-gray600 font-normal mb-6'>
                    Our mission is to provide you with a seamless and intuitive experience, allowing you to focus on what matters most while we 
                    handle the rest. Trust Alladdin to revolutionize the way you manage your subscriptions and discover a new level of convenience 
                    and organization.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhoAreWe
