import React, { useEffect, useState } from "react";
import styles from "./addnew.module.css";
import CardsComponent from "./CardsComponent";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription, GetALLSubscriptions } from "../../redux/features/subscriptionSlice";
import { useLocation } from "react-router";
import AddNewSubsCard from "../AddNewSubsCard";
import ModalComponent from "./ModalComponent";
import { useAuth } from "../../context/authContext";
import { message } from "antd";

const AddNew = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  let { state } = useLocation();
  const { userData } = useAuth();
  const { allSubscriptions } = useSelector((state) => state.subscription);
  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: Number(allSubscriptions?.[0]?.sub_id),
    sub_description: "",
    cost: state && state?.price ? state?.price : state?.cost ? state?.cost : "",
    plan_name: "monthly",
    start_date: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: ""
  });
  const [error, setError] = useState({})

  useEffect(() => {
    dispatch(GetALLSubscriptions());
  }, []);
  
  const handleOpen = () => {
    setOpen(true);
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: Number(allSubscriptions?.[0]?.sub_id),
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: ""
    });
    setError({})
  }
  const handleClose = () => {
    setOpen(false);
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: Number(allSubscriptions?.[0]?.sub_id),
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: ""
    });
    setError({})
  }
  
  const handleSubsDetails = (e) => {
    setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
    setError({...error, [e.target.name] : ""})
  };

  const validateData = () =>{
    let isValid = true;
    let err = {};

    if(!newSubsData?.start_date){
      err.start_date = "Please select start date";
      isValid = false;
    }
    if(newSubsData?.sub_type === "paid"){

    if(!newSubsData?.card_no){
      err.card_no = "Card Number is required";
      isValid = false;
    }

    if(!newSubsData?.cost){
      err.cost = "Cost is required";
      isValid = false;
    }
    else if(newSubsData?.cost && newSubsData?.cost <= 0){
      err.cost = "Cost is greater than 0";
      isValid = false;
    }
  }
  else {
    if(!newSubsData?.free_trial_days){
      err.free_trial_days = "Please enter days";
      isValid = false;
    }
    else if(newSubsData?.free_trial_days && newSubsData?.free_trial_days <= 0){
      err.free_trial_days = "Day is greater than 0";
      isValid = false;
    }
  }

    setError(err);
    return isValid;
  }

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();
    if(validateData()){

    if (newSubsData.sub_type == "paid") {
      setNewSubsData({ ...newSubsData, free_trial_days: "" });
    }

    dispatch(AddSubscription(newSubsData))
      .then((res) => {
        // message.success(res.payload);
        // dispatch(GetALLSubscriptions());r
      })
      .catch((err) => message.error(err));
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: "",
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: "",
    });
    handleClose();
  }
  };

  const handleOpenAddModel = (data) =>{
    setOpen(true);
    setNewSubsData({
      name: userData?.name,
      email: userData?.email,
      sub_title: Number(data?.sub_id),
      sub_description: "",
      cost: "",
      plan_name: "monthly",
      start_date: "",
      pay_method: "card",
      card_no: "",
      plan_variety: "",
      sub_type: "paid",
      free_trial_days: ""
    });
    setError({})
}
 
  return (
    // <div className={"w-full flex flex-col gap-2"}>
    //   <h2 className="postloginHeading">Add New</h2>
    //   <p className="text_sm" style={{ textAlign: "left" }}>
    //     Automatically merge your subscription through our APIs listed below
    //   </p>
    //   <CardsComponent allSubscriptions={allSubscriptions} state={state} />
    // </div>
    <>
      <div className="flex items-center justify-between pb-6">
        <div>
          <h2 className="text-xl font-medium text-black mb-1">
          Add New
          </h2>
          <p className="text-base font-normal text-black300">
            Automatically merge your subscription through our APIs 
            listed below
          </p>
        </div>
        <button className="border-none cursor-pointer text-sm font-medium text-black300 py-3 px-6 rounded-md primary-gradient-bg"  onClick={handleOpen}>
        Add New
        </button>
      </div>
      <div className='grid grid-cols-7 leptop:grid-cols-5 minileptop:!grid-cols-3 tab:grid-cols-3 gap-5'>
            {
                allSubscriptions.map((item, index)=> {
                    return(
                        <AddNewSubsCard item={item} index={index} handleOpenAddModel={handleOpenAddModel}/>
                    )
                })
            }
        </div>

        <ModalComponent
        open={open}
        userData={userData}
        setOpen={setOpen}
        handleClose={handleClose}
        handleSubsDetails={handleSubsDetails}
        setNewSubsData={setNewSubsData}
        newSubsData={newSubsData}
        handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
        error={error}
        setError={setError}
        allSubscriptions={allSubscriptions}
      />
    </>
  );
};

export default AddNew;
