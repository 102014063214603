import ReminderImage from '../../../assets/images/ott-reminder.webp';
import ShareIcon from '../../../assets/icons/share.svg';

export default function OttReminders() {
  return (
    <div className='py-14 mobile:py-60'>
      <div className='max-w-1340 mx-auto px-5 mobile:px-30'>
        <div className='grid grid-cols-[592px_1fr] gap-16 items-center tab:grid-cols-1 tab:gap-60'>
            <div>
                <img src={ReminderImage} alt='ReminderImage' className='w-full block' />
            </div>
            <div>
                <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal leading-normal text-black300 font-semibold mb-5 max-w-[640px]'>
                Built for Digital Entrepreneurs, IT Consultants, Small Businesses & Users alike.
                </h2>
                <p className='text-lg mobile:text-base mobile:leading-8 text-gray600 font-normal leading-normal max-w-[640px] mb-11'>
                Whether you are running your own small business or managing multiple client relationships, Alladdin will keep you on top of all the recurring 
                payments associated with running a smooth operation.  Plus, save money by canceling unused services.
                </p>
                <div className='grid grid-cols-[110px_1fr] mobile:grid-cols-[70px_1fr] mobile:gap-3 gap-[22px] items-center'>
                    <img src={ShareIcon} alt='ShareIcon'/>
                    <div>
                        <p className='text-[22px] mobile:text-lg text-black200 font-bold leading-6 mb-1.5'>
                        Smart Reminders
                        </p>
                        <span className='block mobile:text-sm mobile:leading-6 text-lg text-gray800 max-w-[350px]'>
                        • Email Alerts BEFORE Your Payment
                        </span>
                        <span className='block mobile:text-sm mobile:leading-6 text-lg text-gray800 max-w-[350px]'>
                        •   Customize Timing and Notifications 
                        </span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
