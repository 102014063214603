import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DatePicker from "react-datepicker";
import DeleteSubscriptionModel from "../deleteSubscriptionModel";
import SubscriptionStatusModel from "../subscriptionStatusModel";
import { deleteSubscription, getCurrentAllSubscriptions, GetUserCurrentSubscriptions, handleSubscriptionStatus } from "../../redux/features/subscriptionSlice";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FullPageLoader from "../common/loaders/FullPageLoader";

const SubscriptiondetailsModal = ({ handleClose, setNewSubsData, newSubsData, handleSubmitSubscriptionDetails, error, setError, allSubscriptions, isEditSubscription, setIsEditSubscription, activeTab, addedCustomSubscription }) => {
  const modelRef = useRef();
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const { subscriptionStatusLoader, updateSubscLoader, addSubscLoader, deleteSubscriptionLoader, borwseAlladdinData } = useSelector((state) => state.subscription);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [isStartEdit, setIsStartEdit] = useState(false);
  const [isDeleteSubscription, setIsDeleteSubscription] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(newSubsData?.start_date ? new Date(newSubsData?.start_date) : new Date());
  const [endDate, setEndDate] = useState(newSubsData?.end_date ? new Date(newSubsData?.end_date) : new Date());
  const filteredSubscriptions = borwseAlladdinData.filter((sub) => sub.sub_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null,
  });
  const [activeModel, setActiveModel] = useState({
    isShow: false,
    id: null,
    isActive: false,
    start_date: "",
    end_date: "",
  });

  const toggleDropdown = () => {
    if (!setIsEditSubscription) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const validateData = () => {
    let isValid = true;
    let regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let err = {};

    if (!newSubsData?.name) {
      err.name = "Please enter name";
      isValid = false;
    }

    if (!newSubsData?.email) {
      err.email = "Please enter email";
      isValid = false;
    }

    if (newSubsData?.email && regexEmail.test(newSubsData?.email) === false) {
      err.email = "Please enter valid email address";
      isValid = false;
    }

    if (!newSubsData?.start_date) {
      err.start_date = "Please select start date";
      isValid = false;
    }

    if (!newSubsData?.end_date && newSubsData?.sub_type === "paid") {
      err.end_date = "Please select start date";
      isValid = false;
    }

    if (addNote && !newSubsData?.notes) {
      err.notes = "Please enter notes";
      isValid = false;
    }

    if (newSubsData?.sub_type === "paid") {
      if (newSubsData?.pay_method === "card") {
        if (!newSubsData?.card_no) {
          err.card_no = "Card Number is required";
          isValid = false;
        } else if (newSubsData?.card_no && newSubsData?.card_no?.length < 4) {
          err.card_no = "Card Number is required";
          isValid = false;
        } else if (newSubsData?.card_no && newSubsData?.card_no < 1) {
          err.card_no = "Card Number is required";
          isValid = false;
        }
      } else {
        if (!newSubsData?.paypal_email) {
          err.paypal_email = "Please enter email";
          isValid = false;
        }

        if (newSubsData?.paypal_email && regexEmail.test(newSubsData?.paypal_email) === false) {
          err.paypal_email = "Please enter valid email address";
          isValid = false;
        }
      }

      if (!newSubsData?.cost) {
        err.cost = "Cost is required";
        isValid = false;
      } else if (newSubsData?.cost && newSubsData?.cost <= 0) {
        err.cost = "Cost is greater than 0";
        isValid = false;
      }

      if (!newSubsData?.plan_variety) {
        err.plan_variety = "plan name is required";
        isValid = false;
      }
    } else if (newSubsData?.sub_type === "free") {
      if (!newSubsData?.free_trial_days) {
        err.free_trial_days = "Please enter days";
        isValid = false;
      } else if (newSubsData?.free_trial_days && newSubsData?.free_trial_days <= 0) {
        err.free_trial_days = "Day is greater than 0";
        isValid = false;
      }
    } else if (newSubsData?.sub_type === "lifetime") {
      if (!newSubsData?.cost) {
        err.cost = "Cost is required";
        isValid = false;
      } else if (newSubsData?.cost && newSubsData?.cost <= 0) {
        err.cost = "Cost is greater than 0";
        isValid = false;
      }

      if (!newSubsData?.plan_variety) {
        err.plan_variety = "plan name is required";
        isValid = false;
      }
    }

    setError(err);
    return isValid;
  };

  const handleSubmitModel = (e) => {
    if (validateData()) {
      handleSubmitSubscriptionDetails(e);
    }
  };

  const handleSubsDetails = (e) => {
    const { name, value } = e.target;

    let trimmedValue = value.trimStart();
    if (e.target.name === "email" || e.target.name === "paypal_email") {
      if (e.target.value.trim() !== "") {
        setNewSubsData({ ...newSubsData, [e.target.name]: e?.target?.value?.trim()?.toLowerCase() });
        setError({ ...error, [e.target.name]: "" });
      } else {
        setNewSubsData({ ...newSubsData, [e.target.name]: "" });
        setError({ ...error, [e.target.name]: "Please enter email" });
      }
    } else {
      let formattedValue = trimmedValue.replace(/\s+/g, " ");
      setNewSubsData({ ...newSubsData, [name]: formattedValue });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const handleSearchSubsDetails = (e) => {
    const { value } = e.target;

    let trimmedValue = value.trimStart();
    let formattedValue = trimmedValue.replace(/\s+/g, " ");
    setSearchTerm(formattedValue);
  };

  const handlePlanName = (e) => {
    setNewSubsData({ ...newSubsData, plan_name: e.target.value });
  };

  const handlePaymentMethod = (e) => {
    setNewSubsData({ ...newSubsData, pay_method: e.target.value });
  };
  const handlePlanSubTypeChange = (e) => {
    if (e.target.value === "lifetime") {
      setNewSubsData({ ...newSubsData, sub_type: e.target.value, sub_description: "", cost: "", plan_name: null, start_date: "", end_date: null, pay_method: "card", card_no: "", plan_variety: "", free_trial_days: "", sub_id: null });
    } else {
      setNewSubsData({ ...newSubsData, sub_type: e.target.value, sub_description: "", cost: "", plan_name: "monthly", start_date: "", end_date: null, pay_method: "card", card_no: "", plan_variety: "", free_trial_days: "", sub_id: null });
    }
    setEndDate(new Date());
    setStartDate(new Date());
    setError({});
  };

  const handleDeleteSubscription = () => {
    let data = { id: deleteModel?.id, body: null };
    dispatch(deleteSubscription(data))
      .then((res) => {
        message.success(res?.message || "Subscription Deleted Successfully");
        dispatch(getCurrentAllSubscriptions({ status: activeTab ?? true }));
        dispatch(GetUserCurrentSubscriptions({ status: activeTab ?? true }));
        setDeleteModel({
          id: null,
          isShow: false,
        });
        handleClose();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleChangeSubscriptionStatus = () => {
    let forInactiveData = { id: activeModel?.id, body: { active: activeModel?.isActive } };
    let forActiveData = { id: activeModel?.id, body: { active: activeModel?.isActive, start_date: activeModel?.start_date, end_date: activeModel?.end_date } };
    dispatch(handleSubscriptionStatus(activeModel?.isActive ? forActiveData : forInactiveData))
      .then((res) => {
        message.success(res?.payload);
        dispatch(getCurrentAllSubscriptions());
        dispatch(GetUserCurrentSubscriptions());
        setActiveModel({ isShow: false, id: null, isActive: false });
        handleClose();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handlePlanVarity = (e) => {
    const { value } = e.target;
    let trimmedValue = value.trimStart();
    let formattedValue = trimmedValue.replace(/\s+/g, " ");
    setNewSubsData({ ...newSubsData, plan_variety: formattedValue });
    setError({ ...error, plan_variety: "" });
  };
  const handleDateChange = (newValue, name) => {
    if (name === "start_date") {
      setNewSubsData({
        ...newSubsData,
        start_date: moment(newValue).format("YYYY-MM-DD"),
      });
      setStartDate(newValue);
      setError({ ...error, start_date: "" });
    } else {
      setNewSubsData({
        ...newSubsData,
        end_date: moment(newValue).format("YYYY-MM-DD"),
      });
      setEndDate(newValue);
      setError({ ...error, end_date: "" });
    }
  };

  const bindInput = (value) => {
    const regex = new RegExp("^[^0-9]*$");
    const key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const bindFloatInput = (event) => {
    const value = event.target.value;
    const key = event.key;

    if (window.getSelection().toString() === value) {
      event.target.value = "";
    }
    if (!/[\d.]/.test(key)) {
      event.preventDefault();
      return;
    }

    const regex = new RegExp("^[0-9]*\\.?[0-9]{0,2}$");
    const currentValue = value + key;

    if (!regex.test(currentValue)) {
      event.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const regex = new RegExp("^[0-9]*$");
    if (!regex.test(paste)) {
      e.preventDefault();
    }
  };

  const handleDrop = (e) => {
    const data = e.dataTransfer.getData("text");
    const regex = new RegExp("^[0-9]*\\.?[0-9]{0,2}$");
    if (!regex.test(data)) {
      e.preventDefault();
    }
  };

  const handleSaveNotes = () => {
    setAddNote(false);
  };

  const handlePaymentMethodChange = (e) => {
    setNewSubsData({ ...newSubsData, isPaymentMethod: e.target.value === "No" ? 0 : 1 });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (modelRef && modelRef.current && !modelRef.current.contains(e.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [modelRef]);

  return (
    <div className="global-modal" style={{ zIndex: "999" }}>
      <div ref={!deleteModel?.isShow ? modelRef : null} className="bg-white rounded-xl w-[580px] p-6  mobile:w-[calc(100%-20px)] mobile:p-4 mobile:max-h-[calc(100vh-150px)] mobile:overflow-auto">
        {/* <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? "Edit" : "Add"} Subscription Details</p> */}
        <div className="flex justify-between items-center">
          <p className="text-lg text-black300 font-medium text-center mb-5">{setIsEditSubscription ? (isStartEdit ? "Edit" : "") : "Add"} Subscription Details</p>
          {newSubsData?.active === 1 && <div onClick={() => setIsStartEdit(true)}>{setIsEditSubscription && <img className="mb-5 cursor-pointer" src={"/assets/pencilicon.svg"} width={15} height={15} />}</div>}
          {newSubsData?.active === 0 && (
            <div onClick={() => setDeleteModel({ isShow: true, id: newSubsData?.id })}>
              {" "}
              <i className="fa-solid fa-trash text-red-700 mb-5  cursor-pointer"></i>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2  mobile:grid-cols-1 mobile:gap-3 gap-5 pb-5">
          <div>
            <label className="block text-sm text-black333 pb-1 font-medium">
              User Name <span className="text-red-600"> *</span>
            </label>
            <input
              placeholder="Enter username"
              className={`w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] cursor-pointer ${error?.name ? "error-class" : ""}`}
              type="text"
              name="name"
              onChange={handleSubsDetails}
              value={newSubsData.name}
              disabled={!isStartEdit && setIsEditSubscription}
            />
          </div>
          <div>
            <label className="block text-sm text-black333 pb-1 font-medium">
              Email <span className="text-red-600"> *</span>
            </label>
            <input
              placeholder="Enter Email Address"
              className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.email ? "error-class" : ""}`}
              name="email"
              type="text"
              value={newSubsData.email}
              onChange={handleSubsDetails}
              disabled={!isStartEdit && setIsEditSubscription}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5 pb-5 mobile:pb-3 mobile:grid-cols-1 mobile:gap-3">
          <div>
            <label className="block text-sm text-black333 pb-1 font-medium">Subscription Title</label>
            <div className="relative" ref={dropdownRef}>
              <div
                className="cursor-pointer modal-select-class truncate"
                onClick={() => {
                  if (!addedCustomSubscription) {
                    toggleDropdown();
                  }
                }}
              >
                {borwseAlladdinData.find((sub) => sub.sub_id === (isEditSubscription === true ? newSubsData?.sub_id : addedCustomSubscription ? addedCustomSubscription : newSubsData?.sub_title))?.sub_name ?? allSubscriptions.find((sub) => sub.sub_id === (isEditSubscription === true ? newSubsData?.sub_id : addedCustomSubscription ? addedCustomSubscription : newSubsData?.sub_title))?.sub_name}
              </div>

              {isDropdownOpen && (
                <div className="absolute z-10 w-full bg-white rounded mt-1 border border-solid border-borderColor1">
                  <div className="p-3">
                    <input
                      type="text"
                      className="cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
                      placeholder="Search Subscription"
                      value={searchTerm}
                      onChange={(e) => handleSearchSubsDetails(e)}
                    />

                    <div className="mt-2 h-[250px] overflow-auto">
                      {filteredSubscriptions.length > 0 ? (
                        filteredSubscriptions.map((item) => (
                          <span
                            value={item.sub_id}
                            onClick={() => {
                              setNewSubsData({ ...newSubsData, sub_title: item.sub_id });
                              setIsDropdownOpen(false);
                            }}
                            className={`${item.sub_id === newSubsData?.sub_title ? "bg-inputBackground" : ""} block p-2 text-sm text-black font-medium cursor-pointer hover:bg-inputBackground transition ease-in-out`}
                          >
                            {item.sub_name}
                          </span>
                        ))
                      ) : (
                        <span className="block text-center text-sm text-black300 pt-3 font-medium">No subscriptions found</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm text-black333 pb-1 font-medium">Subscription Type</label>
            <select
              name="paymentmethod"
              onChange={handlePlanSubTypeChange}
              disabled={!isStartEdit && setIsEditSubscription}
              value={newSubsData?.sub_type}
              className="cursor-pointer w-full h-12 bg-inputBackground p-2 border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
            >
              <option className="cursor-pointer" value="free">
                Free
              </option>
              <option className="cursor-pointer" value="paid">
                Paid
              </option>
              <option className="cursor-pointer" value="lifetime">
                Liftetime
              </option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-5 pb-5 mobile:pb-3 mobile:grid-cols-1 mobile:gap-3">
          <div>
            <label className="block text-sm text-black333 pb-1 font-medium">
              Start Date<span className="text-red-600"> *</span>
            </label>
            <div className="DatePicker-main">
              <DatePicker
                disabled={!isStartEdit && setIsEditSubscription}
                dateFormat="DD MM YYYY"
                className={error?.start_date ? "custom-datepicker" : ""}
                placeholderText="Select a date"
                value={newSubsData?.start_date ? moment(newSubsData.start_date).format("DD-MMM-YYYY") : ""}
                maxDate={new Date()}
                fixedHeight
                selected={startDate}
                onChange={(date) => handleDateChange(date, "start_date")}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </div>

          {newSubsData.sub_type === "paid" && (
            <div>
              <label className="block text-sm text-black333 pb-1 font-medium">
                Next Payment Date <span className="text-red-600"> *</span>
              </label>
              <div className="DatePicker-main">
                <DatePicker
                  // disabled={newSubsData?.end_date && setIsEditSubscription}
                  disabled={!isStartEdit && setIsEditSubscription}
                  // disabled={setIsEditSubscription && newSubsData?.end_date}
                  dateFormat="DD MM YYYY"
                  className={error?.end_date ? "custom-datepicker" : ""}
                  placeholderText="Select a date"
                  value={newSubsData?.end_date ? moment(newSubsData.end_date).format("DD-MMM-YYYY") : ""}
                  minDate={new Date()}
                  fixedHeight
                  selected={endDate}
                  onChange={(date) => handleDateChange(date, "end_date")}
                  // onClick={(date) => handleDateChange(date, "end_date")}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          )}

          {newSubsData.sub_type === "lifetime" && (
            <div>
              <label className="block text-sm text-black333 pb-1 font-medium">
                Plan Name <span className="text-red-600"> *</span>
              </label>
              <input
                name="plan_variety"
                value={newSubsData.plan_variety}
                type="text"
                maxLength={100}
                onChange={handlePlanVarity}
                className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.plan_variety ? "error-class" : ""}`}
                placeholder="Enter plan name"
                disabled={!isStartEdit && setIsEditSubscription}
              />
            </div>
          )}
        </div>

        {(newSubsData.sub_type === "paid" || newSubsData.sub_type === "lifetime") && (
          <>
            <div className="grid grid-cols-2 gap-5 pb-5 mobile:pb-3 mobile:grid-cols-1 mobile:gap-3">
              <div>
                <label className="block text-sm text-black333 pb-1 font-medium">
                  Cost <span className="text-red-600"> *</span>
                </label>
                <input
                  name="cost"
                  value={newSubsData.cost}
                  type="text"
                  onChange={handleSubsDetails}
                  id="plancost"
                  onKeyPress={bindFloatInput}
                  onPaste={handlePaste}
                  onDrop={handleDrop}
                  disabled={!isStartEdit && setIsEditSubscription}
                  maxLength="5"
                  placeholder="$0.00"
                  className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.cost ? "error-class" : ""}`}
                />
              </div>

              {newSubsData.sub_type === "paid" && (
                <div>
                  <label className="block text-sm text-black333 pb-1 font-medium">Payment Cycle</label>
                  <select
                    name="plans"
                    onChange={handlePlanName}
                    value={newSubsData?.plan_name}
                    disabled={!isStartEdit && setIsEditSubscription}
                    defaultValue={"monthly"}
                    className="cursor-pointer w-full h-12 bg-inputBackground p-2 border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              )}
            </div>

            {newSubsData.sub_type === "paid" && (
              <div className="grid grid-cols-3 gap-5 pb-5 mobile:grid-cols-1 mobile:gap-3 mobile:pb-3">
                <div>
                  <label className="block text-sm text-black333 pb-1 font-medium">Payment method</label>
                  <select
                    className="cursor-pointer w-full p-2 h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]"
                    value={newSubsData?.pay_method}
                    onChange={handlePaymentMethod}
                    required
                    disabled={!isStartEdit && setIsEditSubscription}
                    name="paymentmethod"
                  >
                    <option value="card">Card</option>
                    <option value="paypal">Paypal</option>
                  </select>
                </div>
                {newSubsData?.pay_method === "card" ? (
                  <div>
                    <label className="block text-sm text-black333 pb-1 font-medium">
                      Enter card no. <span className="text-red-600"> *</span>
                    </label>
                    <input
                      name="card_no"
                      value={newSubsData.card_no}
                      disabled={!isStartEdit && setIsEditSubscription}
                      type="text"
                      onChange={handleSubsDetails}
                      id="card"
                      maxLength="4"
                      className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.card_no ? "error-class" : ""}`}
                      placeholder="Last 4 Digits"
                      onKeyPress={bindInput}
                      onPaste={handlePaste}
                      onDrop={handleDrop}
                    />
                  </div>
                ) : (
                  <div>
                    <label className="block text-sm text-black333 pb-1 font-medium">
                      Enter paypal email <span className="text-red-600"> *</span>
                    </label>
                    <input
                      name="paypal_email"
                      value={newSubsData.paypal_email ?? ""}
                      type="text"
                      id="paypal_email"
                      onChange={handleSubsDetails}
                      className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.paypal_email ? "error-class" : ""}`}
                      placeholder="Enter Email Address"
                      disabled={!isStartEdit && setIsEditSubscription}
                    />
                  </div>
                )}
                <div>
                  <label className="block text-sm text-black333 pb-1 font-medium">
                    Plan Name <span className="text-red-600"> *</span>
                  </label>
                  <input
                    name="plan_variety"
                    value={newSubsData.plan_variety}
                    type="text"
                    maxLength={100}
                    onChange={handlePlanVarity}
                    className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.plan_variety ? "error-class" : ""}`}
                    placeholder="Enter plan name"
                    disabled={!isStartEdit && setIsEditSubscription}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {(newSubsData.sub_type == "" || newSubsData.sub_type == "free") && (
          <div style={{ width: "100%" }}>
            <label className="text-[15px] font-medium pr-2 text-black300" htmlFor="Freetrial">
              Free trial valid for:
              <span className="text-red-600"> *</span>
            </label>
            <input
              name="free_trial_days"
              onKeyPress={bindInput}
              onPaste={handlePaste}
              onDrop={handleDrop}
              type="text"
              disabled={!isStartEdit && setIsEditSubscription}
              value={newSubsData.free_trial_days}
              onChange={handleSubsDetails}
              maxLength={4}
              id="Freetrial"
              className={`w-[200px] h-10 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.free_trial_days ? "error-class" : ""}`}
              placeholder="Free trial valid for"
            />
          </div>
        )}
        {(newSubsData.sub_type == "" || newSubsData.sub_type == "free") && (
          <div style={{ width: "100%" }}>
            {/* <label className="text-[15px] font-medium text-black300" htmlFor="Freetrial">
                  Free trial valid for:
                </label> */}
            <div className="flex gap-3 pt-3">
              <p className="text-[15px] font-medium text-black300">Have you added a payment method?</p>
              <div className="flex gap-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="Yes"
                    name="payment_method"
                    value="Yes"
                    disabled={!isStartEdit && setIsEditSubscription}
                    checked={newSubsData?.isPaymentMethod != 0 ? true : false}
                    className="text-blue-600 focus:ring-blue-500 h-4 w-4 border-gray-300"
                    onChange={handlePaymentMethodChange}
                  />
                  <label htmlFor="Yes" className="ml-2 text-sm font-medium text-gray-900">
                    Yes
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="No"
                    name="payment_method"
                    value="No"
                    disabled={!isStartEdit && setIsEditSubscription}
                    checked={newSubsData?.isPaymentMethod === 0 ? true : false}
                    className="text-blue-600 focus:ring-blue-500 h-4 w-4 border-gray-300"
                    onChange={handlePaymentMethodChange}
                  />
                  <label htmlFor="No" className="ml-2 text-sm font-medium text-gray-900">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          // <div className={styles.formcontrol} style={{ width: "100%" }}>
          //   {/* <label className="text-[15px] font-medium text-black300" htmlFor="Freetrial">
          //     Free trial valid for:
          //   </label> */}
          //   <p className="text-[15px] font-medium text-black300">Please select your favorite Web language:</p>
          //   <div className="flex">
          //     <h1>Display Radio Buttons</h1>
          //     <div>
          //         <input type="radio" id="html" name="fav_language" value="HTML" />  <label for="html">HTML</label>
          //     </div>
          //     <div>
          //       <input type="radio" id="css" name="fav_language" value="CSS" />  <label for="css">CSS</label>
          //     </div>
          //   </div>
          //   {/* <input
          //     name="free_trial_days"
          //     onKeyPress={bindInput}
          //     type="text"
          //     value={newSubsData.free_trial_days}
          //     onChange={handleSubsDetails}
          //     id="Freetrial"
          //     className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.free_trial_days ? "error-class" : ""}`}
          //     placeholder="Free trial valid for"
          //   /> */}
          // </div>
        )}

        <div>
          <div className="flex gap-3">
            <label className="block text-sm text-black333 pb-1 font-medium">
              Notification Notes{" "}
              {!addNote && (
                <span
                  className="cursor-pointer text-[#d0ee11]"
                  onClick={() => {
                    !(!isStartEdit && setIsEditSubscription) && setAddNote(true);
                  }}
                >
                  {!newSubsData?.notes ? "(add a note)" : "(edit)"}
                </span>
              )}
            </label>
          </div>
          {addNote && (
            <>
              <textarea
                name="notes"
                value={newSubsData.notes}
                type="text"
                maxLength={100}
                onChange={handleSubsDetails}
                className={`cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3] ${error?.notes ? "error-class" : ""}`}
                placeholder="Enter notes..."
                disabled={!isStartEdit && setIsEditSubscription}
              />
              <button className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary" onClick={() => handleSaveNotes()}>
                Save
              </button>
            </>
          )}
          {newSubsData.notes && !addNote && <input className="cursor-pointer w-full h-12 bg-inputBackground border-none text-base placeholder:text-base text-black300 placeholder:text-[#B3B3B3]" type="text" disabled value={newSubsData.notes} />}
        </div>

        <div className="flex justify-between gap-3 pt-3">
          <div>
            {newSubsData?.sub_type === "free" && !isStartEdit && setIsEditSubscription && (
              <button
                className="py-3 min-w-[184px]  px-5 bg-[#ff1e2a] border border-solid  border-[#ff1e2a] text-white text-sm font-medium rounded"
                onClick={() => {
                  setDeleteModel({ isShow: true, id: newSubsData?.id });
                }}
              >
                <>Delete Subscription</>
              </button>
              // ) : (
              //   <button
              //     className="py-3 min-w-[184px]  px-5 bg-[#ff1e2a] border border-solid  border-[#ff1e2a] text-white text-sm font-medium rounded"
              //     onClick={() => {
              //       setActiveModel({ isShow: true, id: newSubsData?.id, isActive: newSubsData?.active === 0 ? true : false });
              //     }}
              //   >
              //     <>{newSubsData?.active === 0 ? "Active Subscription" : "Inactive Subscription"}</>
              //   </button>
            )}
            {/* <button
              className="px-6 py-2 block border-none text-base font-medium text-white rounded bg-[#fd2b2be8]"
              onClick={() => {
                setIsStartEdit(false);
                handleClose();
              }}
            >
              Delete
            </button> */}
          </div>
          <div className="flex justify-end gap-3">
            <button
              className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-inputBackground"
              onClick={() => {
                setIsStartEdit(false);
                handleClose();
              }}
            >
              Cancel
            </button>
            {!(!isStartEdit && setIsEditSubscription) && (
              <button disabled={!isStartEdit && setIsEditSubscription} onClick={handleSubmitModel} className="px-6 py-2 block border-none text-base font-medium text-black300 rounded bg-primary">
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      {deleteModel?.isShow && <DeleteSubscriptionModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDeleteSubscription={handleDeleteSubscription} />}
      {activeModel?.isShow && <SubscriptionStatusModel activeModel={activeModel} setActiveModel={setActiveModel} handleChangeSubscriptionStatus={handleChangeSubscriptionStatus} />}
      {(subscriptionStatusLoader || updateSubscLoader || addSubscLoader || deleteSubscriptionLoader) && <FullPageLoader />}
    </div>
  );
};

export default SubscriptiondetailsModal;
