import React, { useEffect, useState } from "react";
import CurrentSubscription from "./currentSubscription";
import ActivityStream from "./activityStream";
import RecommendedApps from "./recommendedApps";
import Overview from "./overview";
import CalenderView from "../../components/common/commonComponents/Calender";
import NetlflixIcon from "../../assets/icons/sm-netflix.png";
import CalenderIcon1 from "../../assets/icons/c1.svg";
import CalenderIcon2 from "../../assets/icons/c2.svg";
import CalenderIcon3 from "../../assets/icons/c3.svg";
import CalenderIcon4 from "../../assets/icons/c4.svg";
import CalenderIcon5 from "../../assets/icons/c5.svg";
import CalenderIcon6 from "../../assets/icons/c6.svg";
import NoDataFound from "../../assets/images/notification-no-data.webp";
import { useNavigate } from "react-router";
import { getUser, useAuth } from "../../context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionSummarry, GetUserProfileData } from "../../redux/features/subscriptionSlice";
import { averageCoats, GetUserCurrentSubscriptions, showNotifications } from "../../redux/features/subscriptionSlice";
import FeatureCard from "../../components/features/FeatureCard";
import DashbordFeatures from "../../components/commonComponents/UnsubscribedFeatures";
import CalenderViewDemo from "../../components/common/commonComponents/CalenderDemo";
import FullPageLoader from "../../components/common/loaders/FullPageLoader";

const AdminDashboard = () => {
  const [spendDays, setSpendDays] = useState(30);
  const [spendType, setSpendType] = useState("Projected");
  const userData = JSON.parse(localStorage.getItem("usersData"));
  const { averageCoastsData, averageCoastsLoader } = useSelector((state) => state.subscription);
  const { subscriptionSummaryData, notifications, notificationsLoader, usersProfileLoader } = useSelector((state) => state.subscription);
  const { isAuthenticate, isUserSubscribed } = useAuth();
  const [isUserSubscrib, setIsUserSubscrib] = useState(false);
  const { usersData } = useSelector((state) => state);
  const [selectedName, setSelectedName] = useState("3 Months");
  const [selectedDates, setSelectedDates] = useState([new Date("2023-10-10"), new Date("2023-10-15"), new Date("2023-11-05")]);
  const [currentDates, setCurrentDates] = useState()
  
  const [isCustomDate, setIsCustomDate] = useState({
    isShowCalendar: false,
    startDate: null,
    endDate: null,
    finalStartDate: null,
    finalEndDate: null,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  async function fetchData() {
    const authData = await getUser();
    if (authData?.isUserSubscribed) {
      setIsUserSubscrib(authData?.isUserSubscribed);
    } else {
      setIsUserSubscrib(null);
    }
  }
  useEffect(() => {
    dispatch(GetUserProfileData());
    dispatch(averageCoats());
    dispatch(GetUserCurrentSubscriptions());
    dispatch(getSubscriptionSummarry());
    dispatch(
      showNotifications({
        query: "",
        body: { days: 30, page: 1, limit: 10 },
      })
    );
    fetchData();
  }, []);

  return !userData?.isUserSubscribed ? (
    <div className="flex items-center justify-center h-full">
      <div className="unsubscribedContainerWrapper">
        <div className="unsubscribedContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "18px",
            }}
          >
            <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
            <h6 className="unsubscribedHeading">
              This Feature is <br /> locked in Free Plan
            </h6>
            <p className="unsubscribedpara">
              Please, Upgrade your plan to <br /> enjoy full benefits
            </p>
          </div>
          <FeatureCard feature={DashbordFeatures} />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex gap-5 tab:flex-wrap mobile:gap-0">
        <div className="w-[calc(100%-280px)] tab:w-full">
          <CurrentSubscription spendDays={spendDays} setSpendDays={setSpendDays} spendType={spendType} setSpendType={setSpendType} 
          selectedName={selectedName} isCustomDate={isCustomDate}
          selectedDates={selectedDates} setSelectedDates={setSelectedDates}
          currentDates={currentDates} setCurrentDates={setCurrentDates}
           />
          <div className="grid grid-cols-2 gap-5 pt-5 tab:grid-cols-1">
            <div className="">
              <h2 className="text-xl font-medium text-black300 mb-2.5 mobile:text-base">Average Cost</h2>
              <div className="bg-white rounded-xl">
                <div className="p-3 mobile:p-2 border-b border-solid border-borderColor1 grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon1} alt="CalenderIcon1" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">
                    Yearly: {userData?.currency ?? "$"}
                    {averageCoastsData?.yearlyCost ? averageCoastsData?.yearlyCost?.toFixed(2) : 0.0}
                  </p>
                </div>
                <div className="p-3 mobile:p-2 border-b border-solid border-borderColor1 grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon2} alt="CalenderIcon2" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">
                    Monthly: {userData?.currency ?? "$"}
                    {averageCoastsData?.monthlyCost ? averageCoastsData?.monthlyCost?.toFixed(2) : 0.0}
                  </p>
                </div>
                <div className="p-3 mobile:p-2  grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon3} alt="CalenderIcon3" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">
                    Weekly: {userData?.currency ?? "$"}
                    {averageCoastsData?.weeklyCost ? averageCoastsData?.weeklyCost?.toFixed(2) : 0.0}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-xl font-medium text-black300 mb-2.5 mobile:text-base">Subscription Summary</h2>
              <div className="bg-white rounded-xl">
                <div className="p-3 mobile:p-2 border-b border-solid border-borderColor1 grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon4} alt="CalenderIcon4" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">Total: {subscriptionSummaryData.length}</p>
                </div>
                <div className="p-3 mobile:p-2 border-b border-solid border-borderColor1 grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon5} alt="CalenderIcon5" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">Trial: {subscriptionSummaryData.filter((item) => item.type === "free").length}</p>
                </div>
                <div className="p-3 mobile:p-2  grid grid-cols-[38px_1fr] gap-2.5 items-center">
                  <img src={CalenderIcon6} alt="CalenderIcon6" className="block" />
                  <p className="text-lg  text-black300 mobile:text-sm mobile:font-medium">Active: {subscriptionSummaryData.filter((item) => item.type === "paid" || item.type === "lifetime").length}</p>
                </div>
              </div>
            </div>
          </div>
          <ActivityStream selectedName={selectedName} setSelectedName={setSelectedName} isCustomDate={isCustomDate} setIsCustomDate={setIsCustomDate} />
          {/* <RecommendedApps/> */}
        </div>
        <div className="w-[280px] tab:w-full ">
          <div className="bg-white rounded-2xl">
            <Overview spendDays={spendDays} setSpendDays={setSpendDays} spendType={spendType} setSpendType={setSpendType} />
            <div className="bg-white px-4 ">
              <p className="text-sm font-semibold text-black300 mb-3">Calender</p>
              <CalenderViewDemo inlineCalendar={"inlineCalendar"} selectedDates={selectedDates} setSelectedDates={setSelectedDates} currentDates={currentDates} setCurrentDates={setCurrentDates}/>
            </div>
            <div className="mx-4 pt-4 pb-5">
              <div className="bg-white shadow-5xl p-4 rounded-lg">
                <div className="flex items-center justify-between pb-2">
                  <p className="text-sm font-semibold text-black300">Notifications</p>
                  <span className="block underline text-xs text-gray700 cursor-pointer font-medium" onClick={() => navigate("/notifications")}>
                    View all
                  </span>
                </div>
                <div className="p-1 bg-lightBg rounded flex items-center mb-2 justify-between">
                  <p className="text-xs font-medium text-black300">{notifications?.length} Notifications</p>
                </div>
                {notifications?.length ? (
                  <div className="max-h-[240px] min-h-[240px] overflow-auto">
                    {notifications?.map((item, index) => {
                      return (
                        <div key={index} className="grid grid-cols-[26px_1fr] gap-2 py-2 border-solid  border-b items-center border-borderColor1 last:border-none last:pb-0">
                          <img src={item?.img_url} alt="NetlflixIcon" className="block w-full h-[26px] rounded" />
                          <p className="text-xs text-black300 font-medium">{item?.description ?? "-"}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className=" bg-[#fff] flex flex-col gap-2 items-center justify-center mobile:gap-2 mobile:grid mobile:grid-cols-2 p-3 rounded-[20px] w-full">
                    <img src={NoDataFound} alt="No Data Found" width={150} height={150} />
                    <p className="text-sm font-medium text-black">No notification found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(notificationsLoader || usersProfileLoader) && <FullPageLoader />}
    </>
  );
};
export default AdminDashboard;
