import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import styles from "./sub.module.css";
import { useParams } from "react-router-dom";

import TimeIcon from "../../../assets/icons/time-icon.svg";

import DiscriptionSec from "./DiscriptionSec";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription, deleteSubscription, GetALLSubscriptions, GetSubsDetails, Getsubsgraphs, GetUserCurrentSubscriptions, handleSubscriptionStatus, UpdateSubscription } from "../../../redux/features/subscriptionSlice";
import AreaGraphComponent from "./AreaGraphComponent";
import { Select, message } from "antd";
import { useAuth } from "../../../context/authContext";
import SalesImage from "../../../assets/images/sale-img.png";
import SubscriptiondetailsModal from "../../subscriptiondetailsModal";
import moment from "moment";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import DeleteSubscriptionModel from "../../deleteSubscriptionModel";
import FullPageLoader from "../../common/loaders/FullPageLoader";
import SubscriptionStatusModel from "../../subscriptionStatusModel";

const Subscription = ({}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const usersData = JSON.parse(localStorage.getItem("usersData"));
  const [days, setDays] = useState(30);
  const [graphSeries, setGraphSeries] = useState([]);
  const [graphOption, setgraphOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({});
  const [isEditSubscription, setIsEditSubscription] = useState(false);
  const [deleteModel, setDeleteModel] = useState({
    isShow: false,
    id: null,
  });
  const [activeModel, setActiveModel] = useState({
    isShow: false,
    id: null,
    isActive: false,
    start_date: "",
    end_date: "",
  });

  const handleOpen = () => {
    setOpen(true);
    setIsEditSubscription(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsEditSubscription(false);
    setError({});
  };
  const perticularSubsData = useSelector((state) => state.subscription.perticularSubsData);
  const { allSubscriptions, deleteSubscriptionLoader } = useSelector((state) => state.subscription);
  const { usersCurrentSub } = useSelector((state) => state.subscription);
  // let daysLeft = usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.end_date
  //   ? Math.floor((new Date(usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.end_date) - new Date()) / (1000 * 60 * 60 * 24))
  //   : "0";

  let daysLeft = usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.end_date
    ? Math.floor((new Date(usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.end_date) - new Date()) / (1000 * 60 * 60 * 24))
    : usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.start_date && usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.free_trial_days
    ? Math.floor(
        (new Date(new Date(usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id).start_date).getTime() + usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id).free_trial_days * 24 * 60 * 60 * 1000) -
          new Date()) /
          (1000 * 60 * 60 * 24)
      )
    : "0";

  let daysLeftForFree =
    usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.start_date && usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.free_trial_days
      ? Math.floor(
          (new Date(new Date(usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id).start_date).getTime() + usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id).free_trial_days * 24 * 60 * 60 * 1000) -
            new Date()) /
            (1000 * 60 * 60 * 24)
        )
      : "0";

  let currentSubscription = usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id);

  const [newSubsData, setNewSubsData] = useState({
    name: userData?.name,
    email: userData?.email,
    sub_title: "",
    sub_description: "",
    cost: "",
    plan_name: "monthly",
    start_date: "",
    end_date: "",
    notes: "",
    pay_method: "card",
    card_no: "",
    plan_variety: "",
    sub_type: "paid",
    free_trial_days: "",
    sub_id: "",
  });

  const handleSubsDetails = (e) => {
    if (e.target.name === "email") {
      if (e.target.value.trim() !== "") {
        setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value });
      } else {
        setNewSubsData({ ...newSubsData, [e.target.name]: "" });
        setError({ ...error, [e.target.name]: "Please enter email" });
      }
    } else {
      setNewSubsData({ ...newSubsData, [e.target.name]: e.target.value?.trimStart() });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const handleDeleteSubscription = () => {
    let data = { id: deleteModel?.id, body: null };
    dispatch(deleteSubscription(data))
      .then((res) => {
        message.success(res?.message || "Subscription Deleted Successfully");
        dispatch(GetUserCurrentSubscriptions());
        setDeleteModel(false);
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleChangeSubscriptionStatus = () => {
    let forInactiveData = { id: activeModel?.id, body: { active: activeModel?.isActive } };
    let forActiveData = { id: activeModel?.id, body: { active: activeModel?.isActive, start_date: activeModel?.start_date, end_date: activeModel?.end_date } };
    dispatch(handleSubscriptionStatus(activeModel?.isActive ? forActiveData : forInactiveData))
      .then((res) => {
        message.success(res?.payload);
        dispatch(GetUserCurrentSubscriptions());
        setActiveModel({ isShow: false, id: null, isActive: false });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSubmitSubscriptionDetails = (e) => {
    e.preventDefault();

    // if (validateData()) {
    if (isEditSubscription) {
      let data = { id: newSubsData?.id, body: { ...newSubsData, plan_variety: newSubsData?.plan_variety?.trimEnd(), name: newSubsData?.name?.trimEnd() } };
      if (newSubsData.sub_type == "paid") {
        setNewSubsData({ ...newSubsData, free_trial_days: "" });
      }
      dispatch(UpdateSubscription(data))
        .then((res) => {
          message.success(res?.message || "Subscription Updated Successfully");
          dispatch(GetUserCurrentSubscriptions());
          handleClose();
        })
        .catch((err) => {
          message.error(err);
        });
      setNewSubsData({
        name: userData?.name,
        email: userData?.email,
        sub_title: "",
        sub_description: "",
        cost: "",
        plan_name: "monthly",
        start_date: "",
        end_date: "",
        notes: "",
        pay_method: "card",
        card_no: "",
        plan_variety: "",
        sub_type: "paid",
        free_trial_days: "",
        sub_id: null,
      });
    } else {
      if (newSubsData.sub_type == "paid") {
        setNewSubsData({ ...newSubsData, free_trial_days: "" });
      }
      dispatch(AddSubscription({ ...newSubsData, plan_variety: newSubsData?.plan_variety?.trimEnd(), name: newSubsData?.name?.trimEnd() }))
        .then((res) => {
          // console.log("ressssssssssssssss", res)
          // message.success(res.payload);
          dispatch(GetUserCurrentSubscriptions());
          handleClose();
          // dispatch(GetALLSubscriptions());r
        })
        .catch((err) => message.error(err));
      setNewSubsData({
        name: userData?.name,
        email: userData?.email,
        sub_title: "",
        sub_description: "",
        cost: "",
        plan_name: "monthly",
        start_date: "",
        end_date: "",
        notes: "",
        pay_method: "card",
        card_no: "",
        plan_variety: "",
        sub_type: "paid",
        free_trial_days: "",
        sub_id: null,
      });
      // }
    }
  };

  const handleChange = (value) => {
    setDays(value.value);
  };

  useEffect(() => {
    dispatch(Getsubsgraphs({ sub_id: params?.sub_id, days: days }))
      .then((res) => {
        setGraphSeries(res.payload.map((graph) => graph.graph_data.price));
        setgraphOption(res.payload.map((graph) => graph.graph_data.time));
      })
      .catch((err) => console.log(err));
  }, [days]);

  const perticularGraphData = useSelector((state) => state.subscription.perticularGraphData);

  useEffect(() => {
    dispatch(GetALLSubscriptions());
    dispatch(GetUserCurrentSubscriptions());
    dispatch(GetSubsDetails({ sub_id: params?.sub_id, sub_own_id: params?.id }));
    dispatch(Getsubsgraphs({ sub_name: params?.sub_name, days: days }))
      .then((res) => {
        setGraphSeries(res.payload.map((graph) => graph.graph_data.price));
        setgraphOption(res.payload.map((graph) => graph.graph_data.time));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  const breadcrumbs = [
    <Link
      underline="hover"
      color="inherit"
      to="/subscription-list"
      //   onClick={handleClick}
    >
      <p className={styles.breadcrumText}> App </p>
    </Link>,

    <p className={styles.breadcrumText}>{params?.sub_name}</p>,
  ];
  return (
    <>
      {perticularSubsData && (
        <>
          <div className="pb-2">
            <span className="block text-xl text-black mobile:text-base w-5 cursor-pointer" onClick={() => window.history.back()}>
              <i class="fa-solid fa-arrow-left"></i>
            </span>
            <span className="block text-xl text-gray700 font-normal mobile:text-base truncate">
              <span className="cursor-pointer" onClick={() => navigate("/subscription-list")}>
                Apps{" "}
              </span>{" "}
              {`>`} {params?.sub_name}
            </span>
          </div>
          <div className="grid grid-cols-[216px_1fr] gap-5 items-center mobile:grid-cols-1 mobile:gap-2">
            <div className="w-full bg-white rounded-2xl flex items-center justify-center h-[160px] mobile:w-[160px]">
              <img src={perticularSubsData[0]?.sub_logo} className="max-w-[80px] block" />
            </div>
            <div>
              <h2 className="text-[22px] leading-normal text-black300 font-medium mb-2 mobile:text-base truncate">{params.sub_name}</h2>
              {usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id) &&
                (usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.type === "lifetime" ? (
                  <p className="text-sm text-black300 font-normal pb-1">
                    Pay date:{" "}
                    <span className="text-sm text-black300 font-normal">
                      {/* 6th July 2023 */}
                      {/* {perticularSubsData[0]?.pay_date} */}
                      {moment(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.start_date).format("DD-MMM-YYYY")}
                    </span>
                  </p>
                ) : usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.type === "free" ? (
                  <p className="text-sm text-black300 font-normal pb-1">
                    End date:{" "}
                    <span className="text-sm text-black300 font-normal">
                      {/* {moment(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.end_date).format("Do MMM YYYY")} */}
                      {usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.end_date
                        ? moment(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.end_date).format("DD-MMM-YYYY")
                        : usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.start_date
                        ? moment(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.start_date)
                            .add(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.free_trial_days - 1, "days")
                            .format("DD-MMM-YYYY")
                        : "-"}
                    </span>
                  </p>
                ) : (
                  <p className="text-sm text-black300 font-normal pb-1">
                    Pay date:{" "}
                    <span className="text-sm text-black300 font-normal">
                      {/* 6th July 2023 */}
                      {/* {perticularSubsData[0]?.pay_date} */}
                      {moment(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.end_date).format("DD-MMM-YYYY")}
                    </span>
                  </p>
                ))}
              {usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id) && (
                <div className="p-1 rounded bg-lightgray19 flex items-center justify-between w-[30%] mb-1 mobile:w-fit mobile:p-2 mobile:gap-3">
                  <span className="block text-xs font-normal text-black">
                    {usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.type === "lifetime"
                      ? "No Expiration"
                      : usersCurrentSub?.find((sub) => sub?.sub_id === perticularSubsData[0]?.sub_id)?.type === "free"
                      ? daysLeftForFree > 0
                        ? daysLeftForFree + " Days remaining"
                        : daysLeftForFree == 0
                        ? "Expire Today"
                        : "Expired"
                      : daysLeft > 0
                      ? daysLeft + " Days remaining"
                      : daysLeft == 0
                      ? "Expire Today"
                      : "Already Paid"}
                  </span>
                  <img width={12} height={12} src={TimeIcon} alt="TimeIcon" className="block cursor-pointer" />
                </div>
              )}
              <p className="text-sm text-black300 font-normal">
                {usersData?.currency ?? "$"}
                {usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.cost ? usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.cost : perticularSubsData[0]?.cost ?? 0} to be paid
              </p>
              <div className="pt-1">
                {usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id) ? (
                  <div className="flex items-center gap-3 mobile:grid mobile:grid-cols-1">
                    {currentSubscription?.type === "free" && (
                      <a href={perticularSubsData[0]?.plan_link} target="_blank">
                        <button className="py-3 px-10 min-w-[244px] mobile:min-w-full mobile:w-full mobile:justify-center bg-primary text-black300 text-sm font-medium border-none rounded">
                          <>Upgrade</>
                        </button>
                      </a>
                    )}

                    {currentSubscription?.active === 1 && (
                      <button
                        className="py-3 min-w-[244px] mobile:min-w-full mobile:w-full mobile:justify-center flex items-center gap-3 px-10 bg-transparent border border-solid  border-primary text-black300 text-sm font-medium rounded"
                        onClick={() => {
                          setNewSubsData({
                            ...newSubsData,
                            name: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.name || userData?.name,
                            email: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.email || userData?.email,
                            active: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.active,
                            sub_id: perticularSubsData[0].sub_id,
                            sub_title: perticularSubsData[0]?.sub_id,
                            cost: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.cost,
                            id: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.id,
                            sub_type: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.type,
                            free_trial_days: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.free_trial_days,
                            // start_date: dayjs(usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.start_date),
                            start_date: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.start_date,
                            end_date: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.end_date,
                            notes: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.notes,
                            isPaymentMethod: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.isPaymentMethod,
                            card_no: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.card_no,
                            plan_variety: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.plan_variety,
                            plan_name: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.plan_name,
                            pay_method: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.pay_method,
                            paypal_email: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.paypal_email,
                          });
                          handleOpen();
                        }}
                      >
                        <>
                          Manage Subscription <img src={"/assets/pencilicon.svg"} width={15} height={15} />
                        </>
                      </button>
                    )}

                    {currentSubscription?.type === "free" ? (
                      <button
                        className="py-3 min-w-[244px] mobile:min-w-full mobile:w-full mobile:justify-center flex items-center gap-3 px-10 bg-[#ff1e2a] border border-solid  border-[#ff1e2a] text-white text-sm font-medium rounded"
                        onClick={() => {
                          setDeleteModel({ isShow: true, id: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.id });
                        }}
                      >
                        <>
                          Delete Subscription <img src={DeleteIcon} width={17} height={15} />
                        </>
                      </button>
                    ) : (
                      <button
                        className="py-3 min-w-[244px] mobile:min-w-full mobile:w-full mobile:justify-center  px-10 bg-[#f73535c9] border border-solid  border-[#f73535c9] text-white text-sm font-medium rounded"
                        onClick={() => {
                          setActiveModel({ isShow: true, id: usersCurrentSub?.find((sub) => sub.sub_id === perticularSubsData[0].sub_id)?.id, isActive: currentSubscription?.active === 0 ? true : false });
                        }}
                      >
                        <>{currentSubscription?.active === 0 ? "Reactivate Subscription" : "Deactivate Subscription"}</>
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    className="py-3 px-10 bg-primary text-black300 text-sm font-medium border-none rounded"
                    onClick={() => {
                      setNewSubsData({
                        name: userData?.name,
                        email: userData?.email,
                        sub_title: perticularSubsData[0]?.sub_id,
                        isPaymentMethod: 0,
                        sub_description: "",
                        cost: "",
                        plan_name: "monthly",
                        start_date: "",
                        end_date: "",
                        notes: "",
                        pay_method: "card",
                        card_no: "",
                        plan_variety: "",
                        sub_type: "paid",
                        free_trial_days: "",
                      });
                      setOpen(true);
                    }}
                  >
                    <>Add {perticularSubsData[0].sub_name}</>
                  </button>
                )}
              </div>
            </div>
          </div>
          <DiscriptionSec sub_description={perticularSubsData[0]?.sub_description} />
          {/* <div>
            <h2 className="text-[22px] leading-normal text-black300 font-medium mb-2">Subscription activity</h2>
            <div className="bg-white h-[190px] rounded-2xl"></div>
          </div>
          <div className="pt-5">
            <h2 className="text-[22px] leading-normal text-black300 font-medium mb-2">Description</h2>
            <div className="grid grid-cols-3 gap-5">
              <div className="bg-white rounded-2xl p-4 grid grid-cols-[140px_1fr] gap-3 items-center">
                <div className="">
                  <img className="w-full h-[130px] rounded" src={SalesImage} alt="SalesImage" />
                </div>
                <div>
                  <p className="text-lg font-semibold text-black300 max-w-[200px] mb-2">Don’t miss out on 40% discount on all aps</p>
                  <p className="text-sm text-black300 font-normal max-w-[200px]">Don’t miss out on 40% discount on all aps</p>
                </div>
              </div>
              <div className="bg-white rounded-2xl p-4 grid grid-cols-[140px_1fr] gap-3 items-center">
                <div className="">
                  <img className="w-full h-[130px] rounded" src={SalesImage} alt="SalesImage" />
                </div>
                <div>
                  <p className="text-lg font-semibold text-black300 max-w-[200px] mb-2">Don’t miss out on 40% discount on all aps</p>
                  <p className="text-sm text-black300 font-normal max-w-[200px]">Don’t miss out on 40% discount on all aps</p>
                </div>
              </div>
              <div className="bg-white rounded-2xl p-4 grid grid-cols-[140px_1fr] gap-3 items-center">
                <div className="">
                  <img className="w-full h-[130px] rounded" src={SalesImage} alt="SalesImage" />
                </div>
                <div>
                  <p className="text-lg font-semibold text-black300 max-w-[200px] mb-2">Don’t miss out on 40% discount on all aps</p>
                  <p className="text-sm text-black300 font-normal max-w-[200px]">Don’t miss out on 40% discount on all aps</p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="mainSubscriptionContainer">
            {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs> */}

            {/* <div className="buttonsContainer flex justify-end my-3">
              <Select
                className="select-class"
                style={{ border: "none !important" }}
                labelInValue
                defaultValue={{
                  value: 30,
                  label: (
                    <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                      30 days
                    </p>
                  ),
                }}
                onChange={handleChange}
                options={[
                  {
                    value: 60,
                    label: (
                      <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                        60 days
                      </p>
                    ),
                  },
                  {
                    value: 30,
                    label: (
                      <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                        30 days
                      </p>
                    ),
                  },
                  {
                    value: 180,
                    label: (
                      <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                        6 Months
                      </p>
                    ),
                  },
                ]}
              />
            </div> */}
            {/* {perticularGraphData.length > 0 ? <AreaGraphComponent graphData={perticularGraphData[0]} graphSeries={graphSeries} graphOption={graphOption} /> : <p className="header_1">No Data for Graph</p>} */}

            {open && (
              <SubscriptiondetailsModal
                open={open}
                userData={userData}
                setOpen={setOpen}
                handleClose={handleClose}
                handleSubsDetails={handleSubsDetails}
                setNewSubsData={setNewSubsData}
                newSubsData={newSubsData}
                handleSubmitSubscriptionDetails={handleSubmitSubscriptionDetails}
                error={error}
                setError={setError}
                allSubscriptions={allSubscriptions}
                setIsEditSubscription={isEditSubscription}
              />
            )}

            {deleteModel?.isShow && <DeleteSubscriptionModel deleteModel={deleteModel} setDeleteModel={setDeleteModel} handleDeleteSubscription={handleDeleteSubscription} />}
            {activeModel?.isShow && <SubscriptionStatusModel activeModel={activeModel} setActiveModel={setActiveModel} handleChangeSubscriptionStatus={handleChangeSubscriptionStatus} />}
            {deleteSubscriptionLoader && <FullPageLoader />}
          </div>
        </>
      )}
    </>
  );
};

export default Subscription;
