import React, { useEffect, useState } from "react";
import Logo from "../../assets/logo/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
const Header = () => {
  const location = useLocation();
  const [header, setHeader] = useState(false);
  useEffect(() => {
    if (header && location?.pathname !== "/login" && location?.pathname !== "/signup") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [header]);

  return (
    <>
      <header className="border-solid border-b border-borderColor py-5 sticky top-0 left-0 bg-white z-20">
        <div className="max-w-1340 mx-auto px-5 mobile:px-30">
          <div className="flex items-center justify-between">
            <div className="cursor-pointer">
              <NavLink to={"/"}>
                <img src={Logo} alt="Logo" className="tab:max-w-[120px] mobile:max-w-[170px]" />
              </NavLink>
            </div>
            <div className="flex items-center gap-80 tab:gap-8 mobile:hidden">
              <div className="cursor-pointer group">
                <NavLink to={"/"}>
                  <span className={`${location?.pathname === "/" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-base tracking-normal`}>Home</span>
                </NavLink>
              </div>
              <div className="cursor-pointer group">
                <NavLink to={"/features"} 
                >
                  <span className={`${location?.pathname === "/features" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-base tracking-normal`}>Features</span>
                </NavLink>
              </div>
              <div className="cursor-pointer group">
                <NavLink to={"/about-us"} >
                  <span className={`${location?.pathname === "/about-us" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-base tracking-normal`}>About us</span>
                </NavLink>
              </div>
              <div className="cursor-pointer group">
                <NavLink to={"/contact-us"} >
                  <span className={`${location?.pathname === "/contact-us" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-base tracking-normal`}>Contact us</span>
                </NavLink>
              </div>
            </div>
            <div className="flex items-center gap-5 mobile:hidden">
              <NavLink to={"/login"}>
                <span className="text-base tracking-normal hover:font-extrabold transition ease-in-out text-black300 font-normal cursor-pointer">Sign In</span>
              </NavLink>
              <NavLink to={"/signup"}>
                <button className="border-none text-base tracking-normal text-white py-[10px] px-[14px] rounded-full bg-black300">Start Trial</button>
              </NavLink>
            </div>
            <div className="hidden mobile:block" onClick={() => setHeader(!header)}>
              <i class="fa-solid fa-bars text-black200" ></i>
            </div>
          </div>
        </div>
      </header>
      <div className={header ? "mobile-header show" : "mobile-header hide"}>
        <div className="relative">
        <div className="border-solid border-b border-borderColor py-5">
          <div className="max-w-1340 mx-auto px-5 mobile:px-30">
            <div className="flex items-center justify-between">
              <div className="cursor-pointer">
                <NavLink to={"/"}>
                  <img src={Logo} alt="Logo" className="tab:max-w-[120px]" />
                </NavLink>
              </div>
              <div onClick={() => setHeader(false)}>
                <i class="fa-solid fa-xmark cursor-pointer"></i>
              </div></div>
          </div>
        </div>
        <div className="mobile-sidbar-height">
        <div className="max-w-1340 mx-auto px-5 mobile:px-30">
          <div className="py-10">
            <div className="cursor-pointer group mb-8">
              <NavLink to={"/"} onClick={() =>
                setHeader(false)
              }>
                <span className={`${location?.pathname === "/" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-2xl tracking-normal`}>Home</span>
              </NavLink>
            </div>
            <div className="cursor-pointer group mb-8">
              <NavLink to={"/features"} onClick={() =>
               setHeader(false)
              }>
                <span className={`${location?.pathname === "/features" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-2xl tracking-normal`}>Features</span>
              </NavLink>
            </div>
            <div className="cursor-pointer group mb-8">
              <NavLink to={"/about-us"} onClick={() =>
                setHeader(false)
              }>
                <span className={`${location?.pathname === "/about-us" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-2xl tracking-normal`}>About us</span>
              </NavLink>
            </div>
            <div className="cursor-pointer group mb-8">
              <NavLink to={"/contact-us"} onClick={() =>
                setHeader(false)
              }>
                <span className={`${location?.pathname === "/contact-us" ? "font-extrabold" : "font-normal"} block hover:font-extrabold transition ease-in-out text-black200 text-2xl tracking-normal`}>Contact us</span>
              </NavLink>
            </div>
          
          </div>
        </div>
        </div>
        <div className="absolute bottom-[6.5rem] left-0 w-full">
          <div className='mobile:px-30 mobile-sidebar-login-button'>
          <NavLink to={"/login"} onClick={() =>
                setHeader(false)
              } className={"w-full"}>
          <button className="py-3 px-5 w-full bg-primary text-black mb-4 font-medium text-base rounded-full">Login</button>
          </NavLink>
          <NavLink to={"/signup"} onClick={() =>
                setHeader(false)
              }className={"w-full"}>
          <button className="py-3 px-5 w-full bg-black text-white mb-4 font-medium text-base rounded-full">Start Trial </button>
          </NavLink>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Header;
