import React from "react";
import "./help.css";

const HelpComponent = () => {
  return (
    <div className="help-container">
      <h1 className="main-heading">Help Center: Alladdin</h1>
      <p className="desc_texts font-extrabold text-left mb-6 mt-6">
        Welcome to Alladdin Help Center!
      </p>

      <div className="mt-6">
        <h2 className="toc-heading">Table of Contents</h2>
        <ul className="toc-list">
          <li className="text_very_sm">
            <a href="#getting-started">1. Getting Started</a>
          </li>
          <li className="text_very_sm">
            <a href="#managing-subscriptions">2. Managing Subscriptions</a>
          </li>
          <li className="text_very_sm">
            <a href="#faqs">3. FAQs</a>
          </li>
          <li className="text_very_sm">
            <a href="#contact-support">4. Contact Support</a>
          </li>
        </ul>
      </div>

      <section id="getting-started" className="my-5">
        <h2 className="section-heading">1. Getting Started</h2>

        <h3 className="subsection-heading">1.1 Account Creation</h3>
        <p className="text_very_sm">
          If you're new to OTT Subscription Tracker, follow these steps to get
          started:
        </p>
        <ol className="step-list">
          <li className="text_very_sm">
            Visit our website and click on the "Sign Up" button.
          </li>
          <li className="text_very_sm">
            Fill in the required information to create your account.
          </li>
          <li className="text_very_sm">
            Once registered, log in with your credentials.
          </li>
        </ol>

        <h3 className="subsection-heading">1.2 Adding OTT Subscriptions</h3>
        <p className="text_very_sm">
          To start tracking your OTT subscriptions:
        </p>
        <ol className="step-list">
          <li className="text_very_sm">
            Navigate to the "Add New" page. or in Dashboard press "+Add
            subscription" button
          </li>
          <li className="text_very_sm">
            Enter the details of your OTT subscriptions, including the service
            name, plan details, and billing cycle.
          </li>
          <li className="text_very_sm">Click "Submit" to save.</li>
        </ol>
      </section>

      <section id="managing-subscriptions" className="my-5">
        <h2 className="section-heading">2. Managing Subscriptions</h2>

        <h3 className="subsection-heading">2.1 Viewing Your Subscriptions</h3>
        <p className="text_very_sm">To view your existing subscriptions:</p>
        <ol className="step-list">
          <li className="text_very_sm">Go to the "Subscription List" page.</li>
          <li className="text_very_sm">
            Here, you'll see a list of all your added OTT subscriptions.
          </li>
        </ol>

        <h3 className="subsection-heading">
          2.2 Updating Subscription Details
        </h3>
        <p className="text_very_sm">
          Need to make changes to your subscription information?
        </p>
        <ol className="step-list">
          <li className="text_very_sm">
            Navigate to the "Subscription List" page.
          </li>
          <li className="text_very_sm">
            Click on the subscription you want to update.
          </li>
          <li className="text_very_sm">
            Edit the necessary details and save the changes.
          </li>
        </ol>

        <h3 className="subsection-heading">2.3 Cancelling a Subscription</h3>
        <p className="text_very_sm">Ready to end a subscription?</p>
        <ol className="step-list">
          <li className="text_very_sm">Go to the "My profile" page.</li>
          <li className="text_very_sm">
            Locate the "Cancel Subscription" button.
          </li>
          <li className="text_very_sm">
            Click on "Cancel Subscription" and follow the prompts.
          </li>
        </ol>
      </section>

      <section id="contact-support" className="my-5">
        <h2 className="section-heading">4. Contact Support</h2>

        <h3 className="subsection-heading">4.1 Need Assistance?</h3>
        <p className="text_very_sm">
          If you encounter any issues or have questions not covered in our help
          center, contact our support team:
        </p>
        <ul className="toc-list">
          <li className="text_very_sm">
            Email: support@ottsubscriptiontracker.com
          </li>
          <li className="text_very_sm">
            Phone: 1-800-123-4567 (Mon-Fri, 9 AM - 5 PM)
          </li>
        </ul>
      </section>
    </div>
  );
};

export default HelpComponent;
