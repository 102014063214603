import React from 'react'
import CultureImage from '../../../assets/images/Culture.webp';
import { NavLink } from 'react-router-dom';
const CultureSection = () => {
  return (
    <div className='pb-100 mobile:pb-60'>
      <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
        <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal font-bold text-black300 leading-normal mb-6 text-center'>
          Culture that suits
        </h2>
        <p className='text-xl mobile:text-base mobile:leading-8 leading-normal text-gray600 max-w-[820px] mx-auto text-center mb-6'>
          Our culture thrives on collaboration, creativity, and mutual respect. We foster an environment where diverse ideas flourish and every
          team member’s voice is valued.
        </p>
        <div className='flex items-center justify-center pb-60 mobile:pb-10'>
          <NavLink to="/contact-us">
            <button className='bg-transparent hover:bg-black300 hover:text-white transition ease-in-out border border-solid border-black300 text-sm font-medium tracking-normal py-3 px-6 text-black300 rounded-lg'>
              Fill form
            </button>
          </NavLink>
        </div>
        <div>
          <img src={CultureImage} alt='CultureImage' className='w-full block rounded-lg' />
        </div>
      </div>
    </div>
  )
}

export default CultureSection
