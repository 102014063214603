import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import styles from "../contactus/contact.module.css";
import substyles from "../subscription/sub.module.css";
import addStyles from "./addnew.module.css";
import { Select } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useAuth } from "../../context/authContext";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalComponent = ({ open, userData, setOpen, handleClose, handleSubsDetails, setNewSubsData, newSubsData, handleSubmitSubscriptionDetails, error, setError, allSubscriptions, isEditSubscription, setIsEditSubscription }) => {
  const dropdownRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedName, setSelectedName] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredSubscriptions = allSubscriptions.filter((sub) => sub.sub_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (event) => {
    handleSelectSubscription(event);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleChange = (e) => {
    setNewSubsData({ ...newSubsData, plan_name: e.target.value });
    // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };
  const handlePaymentMethod = (e) => {
    setNewSubsData({ ...newSubsData, pay_method: e.target.value });
  };
  const handlePlanSubTypeChange = (e) => {
    setNewSubsData({ ...newSubsData, sub_type: e.target.value });
  };
  const handlePlanName = (e) => {
    setNewSubsData({ ...newSubsData, plan_name: e.target.value });
  };
  const handlePlanVarity = (e) => {
    setNewSubsData({ ...newSubsData, plan_variety: e.target.value });
  };
  const handleDateChange = (newValue) => {
    setNewSubsData({
      ...newSubsData,
      start_date: moment(newValue.$d).format("YYYY-MM-DD"),
    });
    setError({ ...error, start_date: "" });
  };

  const handleSelectSubscription = (e) => {
    setNewSubsData({ ...newSubsData, sub_title: Number(e.target.value) });
  };

  const handlePaste = (e) => {
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    const regex = new RegExp("^[0-9]*$");
    if (!regex.test(paste)) {
      e.preventDefault();
    }
  };

  const bindInput = (value) => {
    const regex = new RegExp("^[^0-9]*$");
    const key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    if (regex.test(key)) {
      value.preventDefault();
      return false;
    }
  };

  const bindFloatInput = (event) => {
    // const regex = new RegExp("^[^0-9]*$");
    // const key = String.fromCharCode(!value.charCode ? value.which : value.charCode);
    // if (regex.test(key)) {
    //   value.preventDefault();
    //   return false;
    // }
    const value = event.target.value;
    const key = event.key;

    if (window.getSelection().toString() === value) {
      event.target.value = "";
    }
    if (!/[\d.]/.test(key)) {
      event.preventDefault();
      return;
    }

    const regex = new RegExp("^[0-9]*\\.?[0-9]{0,2}$");
    const currentValue = value + key;

    if (!regex.test(currentValue)) {
      event.preventDefault();
    }
  };


  const handlePaymentMethodChange = (e) => {
    setNewSubsData({ ...newSubsData, isPaymentMethod: e.target.value === "No" ? 0 : 1 });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  const [datevalue1, setDateValue1] = useState();
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="addnewmodal" keepMounted>
      <Box sx={style}>
        <h1 className="text-[22px] font-medium text-black300" style={{ textAlign: "center", color: "#030303" }}>
          {isEditSubscription ? "Edit" : "Add"} Subscription Details
        </h1>
        <form onSubmit={handleSubmitSubscriptionDetails}>
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div className="" style={{ display: "flex", gap: "20px", marginTop: "35px" }}>
              <div className={styles.inputfieldContainer}>
                <div className={styles.formcontrol}>
                  <label className="text-[15px] font-medium text-black300" htmlFor="fullname">
                    Full Name
                  </label>
                  <input name="name" type="text" className="text-[15px] font-medium text-black300" onChange={handleSubsDetails} value={newSubsData.name} id="fullname" placeholder="Enter Full Name" />
                </div>
              </div>
              <div className={styles.inputfieldContainer}>
                <div className={styles.formcontrol}>
                  <label className="text-[15px] font-medium text-black300" htmlFor="email">
                    Email
                  </label>
                  <input name="email" type="email" value={newSubsData.email} onChange={handleSubsDetails} id="email" className="text-[15px] font-medium text-black300" placeholder="Enter Email Address" />
                </div>
              </div>
            </div>
            {/* <div className={styles.formcontrol}>
              <label className="text-[15px] font-medium text-black300" htmlFor="Subscription">
                Subscription Title
              </label>

              <input type="text" className="modal-select-class" placeholder="Search Subscription" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

              <select name="sub_title" className="modal-select-class" onChange={handleSelectSubscription} value={isEditSubscription ? allSubscriptions.find((sub) => sub.sub_name === newSubsData.sub_title)?.sub_id || "" : newSubsData.sub_title}>
                {filteredSubscriptions.map((item) => (
                  <option key={item.sub_id} value={item.sub_id}>
                    {item.sub_name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className={styles.formcontrol}>
              <label className="text-[15px] font-medium text-black300" htmlFor="Subscription">
                Subscription Title
              </label>

              <div className="relative" ref={dropdownRef}>
                <div className="modal-select-class" onClick={toggleDropdown}>
                  {/* {isEditSubscription ? allSubscriptions.find((sub) => sub.sub_name === newSubsData.sub_title)?.sub_name || "Select Subscription" : newSubsData.sub_title || "Select Subscription"} */}
                  {allSubscriptions.find((sub) => sub.sub_id === isEditSubscription ? newSubsData?.sub_id : newSubsData?.sub_title)?.sub_name}
                </div>

                {isDropdownOpen && (
                  <div className="absolute z-10 w-full bg-white border border-gray-300 rounded mt-1">
                    <input type="text" className="w-full p-2 border-b border-gray-300" placeholder="Search Subscription" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <select
                      name="sub_title"
                      className="w-full p-2 max-h-[200px]"
                      size={filteredSubscriptions.length > 0 ? filteredSubscriptions.length : 1}
                      onChange={handleSelect}
                      value={isEditSubscription ? allSubscriptions.find((sub) => sub.sub_name === newSubsData.sub_title)?.sub_id || "" : newSubsData.sub_title}
                    >
                      {filteredSubscriptions.length > 0 ? (
                        filteredSubscriptions.map((item) => (
                          <option key={item.sub_id} value={item.sub_id}>
                            {item.sub_name}
                          </option>
                        ))
                      ) : (
                        <option>No subscriptions found</option>
                      )}
                    </select>
                  </div>
                )}
              </div>
            </div>
            {/* <div className={styles.formcontrol}>
              <label htmlFor="Description">Description</label>
              <input
                name="sub_description"
                type="text"
                onChange={handleSubsDetails}
                value={newSubsData.sub_description}
                id="Description"
                className="inputcontrol"
                placeholder="Add Description"
              />
            </div> */}
            {/* <div className={styles.formcontrol}>
              <label htmlFor="Url">Add Url</label>
              <input
                name="url"
                type="text"
                value={newSubsData.url}
                onChange={handleSubsDetails}
                id="Url"
                className="inputcontrol"
                placeholder="Paste the Url Here"
              />
            </div> */}

            <div className={addStyles.lowerContainer}>
              {/* <div className={addStyles.thirdWidthContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="Cost">Cost</label>
                  <input
                    name="cost"
                    value={newSubsData.cost}
                    type="number"
                    onChange={handleSubsDetails}
                    id="Cost"
                    className="inputcontrol"
                    placeholder="$0.00"
                  />
                </div>
              </div>
              <div className={addStyles.thirdWidthContainer}>
                <div className={styles.formcontrol}>
                  <label htmlFor="Payment">Payment Cycle</label>
                 
                  <select
                    name="paymentmethod"
                    className="modal-select-class"
                    onChange={handleChange}
                    defaultValue={"30"}
                  >
                    <option value="30">
                      <p
                        className="text_very_sm"
                        style={{ marginBottom: "0px" }}
                      >
                        30 days
                      </p>
                    </option>
                    <option value="60">
                      <p
                        className="text_very_sm"
                        style={{ marginBottom: "0px" }}
                      >
                        60 days
                      </p>
                    </option>
                  </select>
                </div>
              </div> */}
              <div className="" style={{ display: "flex", gap: "20px", marginTop: "35px" }}>
                <div className={styles.inputfieldContainer}>
                  <div className={styles.formcontrol}>
                    <label className="text-[15px] font-medium text-black300" htmlFor="Paymentmethod">
                      Subscription Type
                    </label>

                    <select
                      name="paymentmethod"
                      className="modal-select-class"
                      onChange={handlePlanSubTypeChange}
                      // defaultValue={"paid"}
                      value={newSubsData?.sub_type}
                    >
                      <option value="free">
                        <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                          Free
                        </p>
                      </option>
                      <option value="paid">
                        <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                          Paid
                        </p>
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles.inputfieldContainer}>
                  <div className={styles.formcontrol}>
                    <label className="text-[15px] font-medium text-black300 w-[320px]" htmlFor="email">
                      Start Date
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs} style={{ flex: "1" }}>
                      <div className={`datepicker`}>
                        <DatePicker
                          style={{ flex: "1" }}
                          maxDate={dayjs()}
                          value={newSubsData?.start_date ? newSubsData.start_date : ""}
                          onChange={(newValue) => handleDateChange(newValue)}
                          // className="modaldatepicker"
                          className="text-[15px] font-medium text-black300"
                          renderInput={(params) => <input {...params.inputProps} className={error?.start_date ? "error-class" : ""} />}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              {/* <div className={addStyles.thirdWidthContainer}></div> */}
              {newSubsData.sub_type === "paid" && (
                <>
                  {" "}
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="Paymentmethod" className="text-[15px] font-medium text-black300">
                        Payment method
                      </label>

                      <select required name="paymentmethod" className="modal-select-class" value={newSubsData?.pay_method} onChange={handlePaymentMethod}>
                        <option value="card">
                          <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                            Card
                          </p>
                        </option>
                        <option value="paypal">
                          <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                            Paypal
                          </p>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label className="text-[15px] font-medium text-black300" htmlFor="card">
                        Enter card no.
                      </label>
                      <input
                        // required={
                        //   newSubsData.pay_method == "Card" ? true : false
                        // }
                        name="card_no"
                        value={newSubsData.card_no}
                        type="text"
                        onChange={handleSubsDetails}
                        id="card"
                        maxLength="4"
                        className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.card_no ? "error-class" : ""}`}
                        placeholder="Last 4 Digits"
                        onKeyPress={bindInput}
                        onPaste={handlePaste}
                      />
                    </div>
                  </div>
                  <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label className="text-[15px] font-medium text-black300" htmlFor="planname">
                        Plan Name
                      </label>
                      <input
                        // required={
                        //   newSubsData.pay_method == "Card" ? true : false
                        // }
                        name="plan_variety"
                        value={newSubsData.plan_variety}
                        type="text"
                        onChange={handlePlanVarity}
                        className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.plan_variety ? "error-class" : ""}`}
                        placeholder="Enter plan name"
                      />
                      {/* <select name="plan_variety" className="modal-select-class" onChange={handlePlanVarity} value={newSubsData?.plan_variety}>
                        <option value="standard">
                          <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                            Standard
                          </p>
                        </option>
                        <option value="premium">
                          <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                            Premium
                          </p>
                        </option>
                        <option value="pro">
                          <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                            Pro
                          </p>
                        </option>
                      </select> */}
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                    <div className={styles.inputfieldContainer}>
                      <div className={styles.formcontrol}>
                        <label className="text-[15px] font-medium text-black300" htmlFor="planname">
                          Plans
                        </label>
                        <select name="plans" className="modal-select-class" onChange={handlePlanName} value={newSubsData?.plan_name} defaultValue={"monthly"}>
                          <option value="monthly">
                            <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                              Monthly
                            </p>
                          </option>
                          <option value="yearly">
                            <p className="text_very_sm" style={{ marginBottom: "0px" }}>
                              Yearly
                            </p>
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.inputfieldContainer}>
                      <div className={styles.formcontrol}>
                        <label className="text-[15px] font-medium text-black300" htmlFor="plancost">
                          Plan Cost
                        </label>
                        <input
                          name="cost"
                          value={newSubsData.cost}
                          type="text"
                          onChange={handleSubsDetails}
                          id="plancost"
                          onKeyPress={bindFloatInput}
                          onPaste={handlePaste}
                          maxLength="5"
                          className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.cost ? "error-class" : ""}`}
                          placeholder="Enter Price"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {(newSubsData.sub_type == "" || newSubsData.sub_type == "free") && (
                <div className={styles.formcontrol} style={{ width: "100%" }}>
                  <label className="text-[15px] font-medium text-black300" htmlFor="Freetrial">
                    Free trial valid for:
                  </label>
                  <input
                    name="free_trial_days"
                    onKeyPress={bindInput}
                    onPaste={handlePaste}
                    type="text"
                    value={newSubsData.free_trial_days}
                    onChange={handleSubsDetails}
                    id="Freetrial"
                    className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.free_trial_days ? "error-class" : ""}`}
                    placeholder="Free trial valid for"
                  />
                </div>
              )}
              {(newSubsData.sub_type == "" || newSubsData.sub_type == "free") && (
                <div className={styles.formcontrol} style={{ width: "100%" }}>
                  {/* <label className="text-[15px] font-medium text-black300" htmlFor="Freetrial">
                  Free trial valid for:
                </label> */}
                  <div className="flex gap-3">
                    <p className="text-[15px] font-medium text-black300">Have you added a payment method?</p>
                    <div className="flex gap-4">
                      <div className="flex items-center">
                        <input type="radio" id="Yes" name="payment_method" value="Yes" checked={newSubsData?.isPaymentMethod != 0 ? true : false } className="text-blue-600 focus:ring-blue-500 h-4 w-4 border-gray-300" onChange={handlePaymentMethodChange} />
                        <label htmlFor="Yes" className="ml-2 text-sm font-medium text-gray-900">
                          Yes
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input type="radio" id="No" name="payment_method" value="No" checked={newSubsData?.isPaymentMethod === 0 ? true : false }  className="text-blue-600 focus:ring-blue-500 h-4 w-4 border-gray-300" onChange={handlePaymentMethodChange} />
                        <label htmlFor="No" className="ml-2 text-sm font-medium text-gray-900">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                // <div className={styles.formcontrol} style={{ width: "100%" }}>
                //   {/* <label className="text-[15px] font-medium text-black300" htmlFor="Freetrial">
                //     Free trial valid for:
                //   </label> */}
                //   <p className="text-[15px] font-medium text-black300">Please select your favorite Web language:</p>
                //   <div className="flex">
                //     <h1>Display Radio Buttons</h1>
                //     <div>
                //         <input type="radio" id="html" name="fav_language" value="HTML" />  <label for="html">HTML</label>
                //     </div>
                //     <div>
                //       <input type="radio" id="css" name="fav_language" value="CSS" />  <label for="css">CSS</label>
                //     </div>
                //   </div>
                //   {/* <input
                //     name="free_trial_days"
                //     onKeyPress={bindInput}
                //     type="text"
                //     value={newSubsData.free_trial_days}
                //     onChange={handleSubsDetails}
                //     id="Freetrial"
                //     className={`add-new-model-input text-[15px] font-medium text-black300 ${error?.free_trial_days ? "error-class" : ""}`}
                //     placeholder="Free trial valid for"
                //   /> */}
                // </div>
              )}
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button
              type="button"
              className={substyles.buttonType_1}
              onClick={() => {
                handleClose();
              }}
              style={{ background: "#f5f5f5", flex: "none" }}
            >
              Cancel
            </button>
            <button className={substyles.buttonType_1} style={{ flex: "none" }} type="submit">
              Submit
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalComponent;

{
  /* <div className={addStyles.thirdWidthContainer}>
                    <div className={styles.formcontrol}>
                      <label htmlFor="cardname">Enter Name</label>
                      <input
                        name="card_name"
                        type="text"
                        value={newSubsData.card_name}
                        onChange={handleSubsDetails}
                        id="cardname"
                        className="inputcontrol"
                        placeholder="Name on card"
                      />
                    </div>
                  </div> */
}
