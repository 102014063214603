import React from "react";
import styles from "./about.module.css";

import commonStyles from "../commonComponents/common.module.css";

const StatsSection = () => {
  return (
    <div
      className="wholeWidthContainer "
      style={{ position: "relative", background: "#F2F2F2" }}
    >
      <div className="pageWrapper">
        <div className={commonStyles.iconcontainer} style={{ right: "0%" }}>
          <img
            src="/assets/alladinicongray.svg"
            classsname="img"
            alt="alldin icon"
          />
        </div>
        <div className={styles.statsContainer}>
          <div className={styles.stats}>
            <h2 className="header_2" style={{ fontWeight: 700 }}>
              4.5 Star Rating
            </h2>
            {/* <p className="desc_texts">Saved for our users</p> */}
          </div>
          <div className={styles.stats}>
            <h2 className="header_2" style={{ fontWeight: 700 }}>
              2 million + trusted users
            </h2>
            {/* <p className="desc_texts">Saved for our users</p> */}
          </div>
          <div className={styles.stats}>
            <h2 className="header_2" style={{ fontWeight: 700 }}>
              More than 5+ years of experience
            </h2>
            {/* <p className="desc_texts">Saved for our users</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
