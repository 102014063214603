import React, { useEffect } from 'react'
import FeaturesBanner from './featuresBanner'
import FeaturesDetails from './featuresDetails'
import Unlockmore from './Unlockmore'

const FeaturesPage = () => {
  useEffect(() =>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <div>
      <FeaturesBanner/>
      <FeaturesDetails/>
      {/* <Unlockmore/> */}
    </div>
  )
}

export default FeaturesPage
