import React from 'react'
import TeamWorkIcon from '../../../assets/icons/team-work.svg';
import CommiterIcon from '../../../assets/icons/Commiter.svg';
import PositivityIcon from '../../../assets/icons/Positivity.svg';
import InnovationIcon from '../../../assets/icons/Innovation.svg';
import GrowthIcon from '../../../assets/icons/Growth.svg';
import OwnershipIcon from '../../../assets/icons/Ownership.svg';
const OurValues = () => {
    return (
        <div className='py-100 bg-bglight mobile:py-60'>
            <div className='max-w-1160 mx-auto px-5 mobile:px-30'>
                <h2 className='mb-60  mobile:text-[26px] mobile:leading-normal mobile:mb-10 text-[40px] font-bold text-black300 leading-normal text-center'>
                    Our Values
                </h2>
                <div className='grid grid-cols-3 gap-x-5 gap-y-8 tab:grid-cols-2 mobile:grid-cols-1'>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={TeamWorkIcon} alt='TeamWorkIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Team Work
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'> 
                             By fostering an environment of mutual respect and open communication, we empower our team members 
                             to work seamlessly together, turning individual strengths into collective achievements. 
                        </p>
                    </div>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={CommiterIcon} alt='CommiterIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Commiter
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'>
                            We are dedicated to delivering excellence in every aspect of our service. Our unwavering 
                            commitment to quality and customer satisfaction drives us to exceed expectations and continuously improve.
                        </p>
                    </div>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={PositivityIcon} alt='PositivityIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Positivity 
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'>
                        We embrace a positive mindset to inspire creativity and overcome challenges. Our optimistic 
                        approach fuels innovation and fosters a vibrant, supportive work environment.
                        </p>
                    </div>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={InnovationIcon} alt='InnovationIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Innovation 
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'>
                            We champion innovation by constantly seeking new ideas and creative solutions. Our 
                            commitment to pushing boundaries ensures we stay ahead in delivering cutting-edge OTT experiences.
                        </p>
                    </div>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={GrowthIcon} alt='GrowthIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Growth 
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'>
                        We are dedicated to continuous growth, both as a company and as individuals. By embracing new 
                        challenges and opportunities, we drive progress and achieve new heights together.
                        </p>
                    </div>
                    <div className='bg-white rounded-xl p-8 shadow-4xl'>
                        <div className='pb-6'>
                            <img src={OwnershipIcon} alt='OwnershipIcon'/>
                        </div>
                        <p className='mb-4 text-2xl font-bold text-black300'>
                        Ownership 
                        </p>
                        <p className='text-lg mobile:text-base mobile:leading-8 font-normal text-black400'>
                            We take full ownership of our responsibilities, ensuring accountability and excellence in every task. 
                            Our proactive approach drives us to deliver results and take pride in our contributions.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OurValues
