import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1 className="header_2" style={{ marginBottom: "32px" }}>
        Privacy Policy{" "}
      </h1>
      <p className="desc_texts">
        Privacy Policy This Privacy Policy governs the manner in which [Your
        Company Name] collects, uses, maintains, and discloses information
        collected from users (each, a "User") of the [Your Website URL] website
        ("Site"). This privacy policy applies to the Site and all products and
        services offered by [Your Company Name]. Personal Identification
        Information We may collect personal identification information from
        Users in various ways, including but not limited to when Users visit our
        site, register on the site, place an order, subscribe to the newsletter,
        respond to a survey, fill out a form, and in connection with other
        activities, services, features, or resources we make available on our
        Site. Users may be asked for, as appropriate, name, email address,
        mailing address, phone number, and credit card information. We will
        collect personal identification information from Users only if they
        voluntarily submit such information to us. Users can always refuse to
        supply personal identification information, except that it may prevent
        them from engaging in certain Site-related activities. Non-Personal
        Identification Information We may collect non-personal identification
        information about Users whenever they interact with our Site.
        Non-personal identification information may include the browser name,
        the type of computer, and technical information about Users' means of
        connection to our Site, such as the operating system and the Internet
        service providers utilized and other similar information. Web Browser
        Cookies Our Site may use "cookies" to enhance User experience. Users'
        web browsers place cookies on their hard drive for record-keeping
        purposes and sometimes to track information about them. Users may choose
        to set their web browser to refuse cookies or to alert you when cookies
        are being sent. If they do so, note that some parts of the Site may not
        function properly. How We Use Collected Information [Your Company Name]
        may collect and use Users' personal information for the following
        purposes: To improve customer service: Information you provide helps us
        respond to your customer service requests and support needs more
        efficiently. To personalize the user experience: We may use information
        in the aggregate to understand how our Users as a group use the services
        and resources provided on our Site. To process payments: We may use the
        information Users provide about themselves when placing an order only to
        provide service to that order. We do not share this information with
        outside parties except to the extent necessary to provide the service.
        To run a promotion, contest, survey, or other Site feature: To send
        Users information they agreed to receive about topics we think will be
        of interest to them. To send periodic emails: We may use the email
        address to send User information and updates pertaining to their order.
        It may also be used to respond to their inquiries, questions, and/or
        other requests. If User decides to opt-in to our mailing list, they will
        receive emails that may include company news, updates, related product
        or service information, etc. If at any time the User would like to
        unsubscribe from receiving future emails, we include detailed
        unsubscribe instructions at the bottom of each email. How We Protect
        Your Information We adopt appropriate data collection, storage, and
        processing practices and security measures to protect against
        unauthorized access, alteration, disclosure, or destruction of your
        personal information, username, password, transaction information, and
        data stored on our Site. Sharing Your Personal Information We do not
        sell, trade, or rent Users' personal identification information to
        others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates, and advertisers
        for the purposes outlined above. Compliance With Children's Online
        Privacy Protection Act Protecting the privacy of the very young is
        especially important. For that reason, we never collect or maintain
        information at our Site from those we actually know are under 13, and no
        part of our website is structured to attract anyone under 13. Changes To
        This Privacy Policy [Your Company Name] has the discretion to update
        this privacy policy at any time. When we do, we will post a notification
        on the main page of our Site. We encourage Users to frequently check
        this page for any changes to stay informed about how we are helping to
        protect the personal information we collect. You acknowledge and agree
        that it is your responsibility to review this privacy policy
        periodically and become aware of modifications. Your Acceptance Of These
        Terms By using this Site, you signify your acceptance of this policy and
        terms of service. If you do not agree to this policy, please
      </p>
    </div>
  );
};

export default PrivacyPolicy;
