import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getCalenderData } from "../../../../redux/features/subscriptionSlice";
import { Tooltip } from "react-tooltip";
import { Spin, Avatar, List } from "antd";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import moment from "moment";

const subscriptions = [
  {
    sub_id: 9,
    sub_name: "Masterclasses",
    sub_logo: "https://alladdinx.com/assets/mastercalss.png",
    cost: "1",
    end_date: "2024-09-12",
  },
  {
    sub_id: 6,
    sub_name: "HBO Max",
    sub_logo: "https://alladdinx.com/assets/hbomax.png",
    cost: "20",
    end_date: "2024-09-20",
  },
  {
    sub_id: 1,
    sub_name: "Netflix",
    sub_logo: "https://alladdinx.com/assets/netflix.png",
    cost: "1212",
    end_date: "2024-09-26",
  },
];

function CalenderView({ fullSizeCalendar, inlineCalendar }) {
  const params = window.location.pathname;
  const { calenderData } = useSelector((state) => ({
    ...state.subscription,
  }));
  const [loadCalender, SetLoadCalender] = useState(false);
  const [displayedMonth, setDisplayedMonth] = useState(new Date());
  const [highlightDates, setHighlightDates] = useState([]);
  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState([new Date("2023-10-10"), new Date("2023-10-15"), new Date("2023-11-05")]);
  const [tooltipContent, setTooltipContent] = useState(null);

  const events = {
    "2023-10-10": "Event 1",
    "2023-10-15": "Event 2",
    "2023-11-05": "Event 3",
  };

  const isDateHighlighted = (date) => selectedDates.some((selectedDate) => selectedDate.getDate() === date.getDate() && selectedDate.getMonth() === date.getMonth() && selectedDate.getFullYear() === date.getFullYear());

  useEffect(() => {
    SetLoadCalender(true);
    let data = {
      calender_month: new Date().getMonth() + 1,
      calender_year: new Date().getFullYear(),
    };
    dispatch(getCalenderData(data)).then((res) => {
      // console.log(res, "res");
      let dates = [];
      if (Array.isArray(res?.payload)) {
        res?.payload?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
      }
      const highlightData = res?.payload?.map((sub) => ({
        date: moment(sub.end_date),
        sub_name: sub.sub_name,
        cost: sub.cost,
      }));
      setHighlightDates(highlightData);
      SetLoadCalender(false);
    });
  }, []);
  const handleDateMouseOver = (date) => {
    // Show Tooltip and set its content when hovering over a selected date
    // setSelectedDates(date);
    setTooltipContent(events[date.toISOString().split("T")[0]]);
  };

  const handleDateMouseOut = () => {
    setTooltipContent(null);
  };

  const renderDayContents = (day, date) => {
    const subscription = highlightDates.find((highlight) => moment(highlight.date).isSame(date, "day"));

    const displayedMonthNumber = displayedMonth.getMonth();
    const dayMonthNumber = date.getMonth();

    if (dayMonthNumber !== displayedMonthNumber) {
      return <div className="empty-day"></div>;
    }

    return (
      <div className="day">
        <span>{day}</span>
        {subscription && (
          <div className="subscription-details">
            <div className="subscription-name">{subscription.sub_name}</div>
          </div>
        )}
      </div>
    );
  };

  
  // const renderDayContents = (day, date) => {
  //   const subscription = highlightDates.find((highlight) => moment(highlight.date).isSame(date, "day"));

  //   return (
  //     <div className="day">
  //       <span>{day}</span>
  //       {subscription && (
  //         <div className="subscription-details">
  //           <div className="subscription-name">{subscription.sub_name}</div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const handleChangeMonth = (date) => {
    setDisplayedMonth(date);
    SetLoadCalender(true);
    let data = {
      calender_month: new Date(date).getMonth() + 1,
      calender_year: new Date(date).getFullYear(),
    };
    dispatch(getCalenderData(data)).then((res) => {
      // console.log(res, "res");
      let dates = [];
      if (Array.isArray(res?.payload)) {
        res?.payload?.map((item) => {
          dates.push(new Date(item.end_date));
        });
        setSelectedDates(dates);
        const highlightData = (res?.payload ?? [])?.map((sub) => ({
          date: moment(sub.end_date),
          sub_name: sub.sub_name,
          cost: sub.cost,
        }));
        setHighlightDates(highlightData);
      }
      SetLoadCalender(false);
    });
  };

  return (
    <>
      {loadCalender ? (
        <Spin tip="Loading" size="large" className={"mt-8"}>
          <div className="content mt-8" />
        </Spin>
      ) : (
        <>
          {fullSizeCalendar ? (
            <DatePicker
              highlightDates={selectedDates}
              renderDayContents={renderDayContents}
              calendarClassName={`${fullSizeCalendar} ${inlineCalendar}`}
              inline
              isClearable
              minDate={new Date()}
              onMonthChange={(date) => handleChangeMonth(date)}
              dayClassName={(date) => (isDateHighlighted(date) ? "highlighted-date" : undefined)}
              disabledKeyboardNavigation
              shouldCloseOnSelect={false}
            ></DatePicker>
          ) : (
            <DatePicker
              highlightDates={selectedDates}
              calendarClassName={`${fullSizeCalendar} ${inlineCalendar}`}
              inline
              isClearable
              minDate={new Date()}
              onMonthChange={(date) => setDisplayedMonth(date)}
              dayClassName={(date) => (isDateHighlighted(date) ? "highlighted-date" : undefined)}
              disabledKeyboardNavigation
              shouldCloseOnSelect={false}
            ></DatePicker>
          )}
          {/* {params.includes("recurring") && (
            <List
              itemLayout="horizontal"
              className="mt-5"
              dataSource={calenderData.filter((item) => item.sub_name)}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={<Avatar key={index} src={item.sub_logo} />} title={<p key={index}>{item.sub_name}</p>} description={<p key={index}>{item.end_date}</p>} />
                </List.Item>
              )}
            />
          )} */}
        </>
      )}

      {/* <Tooltip id="my-tooltip" /> */}
    </>
  );
}

export default CalenderView;

{
  /* {selectedDates.map((date, index) => (
        <a
          key={index}
          data-tooltip-id={`tooltip-${index}`}
          data-tooltip-content={events[date.toISOString().split("T")[0]]}
          data-tooltip-place="top"
          className="selected-date"
        >
          {date.getDate()}
        </a>
      ))}
      {selectedDates.map((date, index) => (
        <Tooltip
          key={index}
          id={`tooltip-${index}`}
          effect="solid"
          place="top"
        />
      ))} */
}
