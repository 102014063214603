import React from "react";
import HeroSection from "./HeroSection";
import StatsSection from "./StatsSection";
import AboutSection from "./AboutSection";
import OurValues from "./OurValues";
import MissionSec from "./MissionSec";
import CultureSection from "./CultureSection";

const About = () => {
  return (
    <>
      {/* <HeroSection /> */}
      <StatsSection />
      <AboutSection />
      <OurValues />
      <MissionSec />
      <CultureSection />
    </>
  );
};

export default About;
