import React from 'react'

const RoundCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
  <path d="M21.9543 12.2105C21.9543 6.42629 17.2614 1.7334 11.4771 1.7334C5.69289 1.7334 1 6.42629 1 12.2105C1 17.9948 5.69289 22.6877 11.4771 22.6877C17.2614 22.6877 21.9543 17.9948 21.9543 12.2105Z" stroke="#31C65B" stroke-width="1.85685" stroke-miterlimit="10"/>
  <path d="M16.7154 7.84473L9.38143 16.5757L6.23828 13.0833" stroke="#31C65B" stroke-width="1.85685" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default RoundCheckIcon
