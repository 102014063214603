import axios from "axios";
import AppConstants from "../../constants/AppConstants";

const API = axios.create({ baseURL: AppConstants.baseURL });

//====================================================signup flow =================================================/////

// export const notificationForUser = () =>
//   API.get("/auth/noticationforuser");

//=====================================userProfile process===============================//

export const getUserProfile = () => API.get("/auth/profile");
export const updateUserProfile = (formData) =>
  API.post("/auth/updateprofile", formData);
// export const logout = (formData) => API.post(/auth/logout", formData);

//====================================================forgot passowrd flow =================================================/////

export const changePassword = (formData) =>
  API.post("/auth/changepassword", formData);
